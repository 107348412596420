//import config from 'config';
import { handleResponse, requestOptions } from '@/helpers';

export const memberService = {
    getAll,
    getAllWithDeleted,
    getById,
    create,
    update,
    remove,
    restore,
    saveSearch,
    updateSearches,
    toggleFavorite,
    getByEfaId,
    isPasswordGood,
    changePassword
};

function getAll() {
    return fetch(`${process.env.VUE_APP_API_URI}/members`, requestOptions.get())
        .then(handleResponse);
}
function getAllWithDeleted() {
    return fetch(`${process.env.VUE_APP_API_URI}/members/full`, requestOptions.get())
        .then(handleResponse);
}

function getById(id) {
    return fetch(`${process.env.VUE_APP_API_URI}/members/${id}`, requestOptions.get())
        .then(handleResponse);
}

function create(user) {
    return fetch(`${process.env.VUE_APP_API_URI}/members/`, requestOptions.post({user:user}))
        .then(handleResponse);
}

function isPasswordGood(pwd) {
    return fetch(`${process.env.VUE_APP_API_URI}/members/ispasswordgood`, requestOptions.post({password:pwd}))
        .then(handleResponse);
}

function changePassword(pwd) {
    return fetch(`${process.env.VUE_APP_API_URI}/members/changepassword`, requestOptions.post({password:pwd}))
        .then(handleResponse);
}

function update(user) {
    return fetch(`${process.env.VUE_APP_API_URI}/members/`, requestOptions.put({user:user}))
        .then(handleResponse);
}

function restore(id) {
    return fetch(`${process.env.VUE_APP_API_URI}/members/restore`, requestOptions.put({id:id}))
        .then(handleResponse);
}

function remove(id) {
    return fetch(`${process.env.VUE_APP_API_URI}/members/${id}`, requestOptions.delete())
        .then(handleResponse);
}

function saveSearch(user_id, search) {
    return fetch(`${process.env.VUE_APP_API_URI}/members/saveSearch`, requestOptions.put({user_id:user_id, search:search}))
        .then(handleResponse);
}

function updateSearches(user_id, searches) {
    return fetch(`${process.env.VUE_APP_API_URI}/members/updateSearches`, requestOptions.put({user_id:user_id, searches:searches}))
        .then(handleResponse);
}

function toggleFavorite(favorite) {
    return fetch(`${process.env.VUE_APP_API_URI}/members/favorite/`, requestOptions.put({userFavorite:favorite}))
        .then(handleResponse);
}

/**
 *
 * @param id
 * @returns {Promise<Response>}
 */
function getByEfaId(id){
    return fetch(`${process.env.VUE_APP_API_URI}/members/efaid/${id}`, requestOptions.get())
        .then(handleResponse);
}