import {i18n} from "../lang/lang";

import HomePage from "../views/home/HomePage";
import LoginPage from "../views/login/LoginPage";
import p404 from "../views/errors/404";

export default [
    {
        path: '/',
        name: i18n.t('routes.home'),
        component: HomePage,
        meta: {
            title: "JobInclusif.fr",
            metaTags: [
                {
                    name: 'description',
                    content: 'Le site pour l\'insertion professionnelle.'
                },
                {
                    property: 'og:description',
                    content: 'Le site pour l\'insertion professionnelle.'
                }
            ]
        }
    },
    {
        path: '/login',
        name: i18n.t('routes.login'),
        component: LoginPage,
        meta: {
            title: "JobInclusif.fr - " + i18n.t('routes.login')
        }
    },
    {
        path: '/login/:token',
        name: i18n.t('routes.login') + ' - e-Formadapt',
        component: LoginPage,
        meta: {
            title: "JobInclusif.fr - " + i18n.t('routes.login')
        }
    },
    // error pages
    {
        path: '/404',
        name: '404',
        component: p404,
        meta: {
            title: "JobInclusif.fr - 404"
        }
    }

]