import {i18n} from "../lang/lang";
import Companies from "../views/companies/Companies";
import CompaniesForm from "../views/admin/companies/CompanyForm";

export default [
    {
        path: 'companies',
        name: i18n.t('routes.companies.index'),
        component: Companies,
        meta:{
            authorize: [],
            title: "JobInclusif.fr - " + i18n.t('routes.companies.index')
        }
    },
    {
        path: 'companies/:id',
        name : i18n.t('routes.companies.page'),
        component: Companies,
        meta:{
            authorize: [],
            title: "JobInclusif.fr - " + i18n.t('routes.companies.page')
        }
    },
    {
        path: 'companies/update/:id/',
        name : i18n.t('routes.companies.update'),
        component: CompaniesForm,
        meta:{
            authorize: [],
            title: "JobInclusif.fr - " + i18n.t('routes.companies.update')
        }
    }

]