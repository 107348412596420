export default {
    index:"Consulter un CV",

    content:{
        available:"Disponiblité",
        address:"Adresse",
        mobility:"Mobilité",
        search:"Postes recherchés",
        experience:"Expériences professionnelles",
        skills:"Compétences extra-professionnelles",
        training:"Formations",
        regulation:"Réglementations",
        permis:"Permis",
        language:{
            index:"Langues",
            read:"Lire",
            write:"Ecrire",
            listen:"Ecouter",
            speak:"S'exprimer",
            converse:"Converser"
        },
        approved:{
            true:"Niveaux validés par l'employeur",
            false:"Niveaux auto-évalué "
        }
    },

}