import {i18n} from "../../lang/lang";
import {Roles} from "../../config";

import Companies from "../../views/admin/companies/Companies";
import CompanyForm from "../../views/admin/companies/CompanyForm";

export default [
    {
        path: 'companies',
        name: i18n.t('routes.companies.index'),
        component: Companies,
        meta: {
            authorize: [Roles.Admin], //doit être connecté avec le role admin
            title: "JobInclusif.fr - " + i18n.t('routes.companies.index')
        }
    },
    {
        path: 'companies/create',
        name: i18n.t('routes.admin.companies.create'),
        component: CompanyForm,
        meta: {
            authorize: [Roles.Admin], //doit être connecté avec le role admin
            title: "JobInclusif.fr - " + i18n.t('routes.admin.companies.create')
        }
    },
    {
        path: 'companies/update/:id',
        name: i18n.t('routes.admin.companies.update'),
        component: CompanyForm,
        meta: {
            authorize: [Roles.Admin], //doit être connecté avec le role admin
            title: "JobInclusif.fr - " + i18n.t('routes.admin.companies.update')
        }
    },
];