import routesNames from "./routesNames";
import errorMessages from "./errorMessages";
import buttons from './buttons';
import roles from "./roles";
import datatable from './datatable';
import models from "./models/models";
import cv from './cv';
import company from "./company";
import dashboard from './dashboard';
import joboffers from './joboffers';
import favorites from "./favorites";
import searches from "./searches";
import applications from "./applications";

export default {
    routes: routesNames,
    errors: errorMessages,
    buttons: buttons,
    roles: roles,
    datatable: datatable,
    models: models,
    cv: cv,
    company: company,
    dashboard: dashboard,
    joboffers: joboffers,
    favorites: favorites,
    searches: searches,
    applications: applications
}