<template>
    <div></div>
</template>

<script>
    import { authenticationService } from '@/services';
    import { router } from '@/routes/router';
    export default {
        name: "logout",
        created() {
            authenticationService.logout();
            router.push('/');
        }
    }
</script>

<style scoped>

</style>