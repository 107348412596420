<template>
  <CForm v-if="$parent.resume && $parent.company && $parent.efa_societe">
    <h3>{{ $t('cv.content.experience') }}</h3>
    <CCardGroup class="d-block align-items-center col-12">
      <!--Partie des expériences pro (jobsdescription)-->
      <CCard v-for="(experience, index) in $parent.resume.jobdescriptions" :key="'exp_' + experience.ID">
        <CCardHeader @click="showCollapse('exp-'+index)" class="btn text-left">
          <CRow>
            <CCol col="7">
              <h5>
                <b>{{ experience.LIBELLE }}</b>
              </h5>
              <span v-if="experience.DT_DEBUT">Depuis le {{ format_date(experience.DT_DEBUT) }}</span>
            </CCol>
            <CCol class="text-right">
              <b v-if="$parent.company && $parent.company.name">{{ $parent.company.name }}</b>
              <b v-else>{{ $parent.efa_societe.libelle }}</b>
              <br>
              <small class="text-muted">
                <i>
                  {{ $t("cv.content.approved.true") }}
                  <font-awesome-icon icon="check" size="lg" class="text-success"></font-awesome-icon>
                </i>
              </small>
            </CCol>
            <CCol class="text-center border-left">
              <h6>Affiché sur le CV Public</h6>
              <CSwitch shape="pill" color="success" :checked="experience.show"
                       @update:checked="experience.show = !experience.show"></CSwitch>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCollapse :show="collapseShow === 'exp-'+index">
          <CCardBody>
            <CRow>
              <template v-for="skill in experience.skills">
                <CCol col="6"
                      v-if="$parent.resume.skills_with_level && $parent.resume.skills_with_level.length > 0 && $parent.resume.skills_with_level.find(e=>e.skill_id === skill.ID) && $parent.resume.skills_with_level.find(e=>e.skill_id === skill.ID).rated_level >= 2"
                      :key="'skill_' + skill.ID">
                  <BadgeExperience
                      :data="{name:skill.LIBELLE, description:skill.DESCRIPTIF, rating:$parent.resume.skills_with_level.find(e=>e.skill_id === skill.ID).rated_level, ratename:$parent.resume.skills_with_level.find(e=>e.skill_id === skill.ID).rated_level_name}"></BadgeExperience>
                </CCol>
              </template>
            </CRow>
          </CCardBody>
        </CCollapse>
      </CCard>
      <!--/ Partie des expériences pro (jobsdescription)-->

      <!--Partie des expériences pro (jobsdescription_history)-->
      <CCard v-for="(experience, index) in $parent.resume.jobdescriptions_history"
             :key="'exp_history_' + experience.ID">
        <CCardHeader @click="showCollapse('exphistory-'+index)" class="btn text-left">
          <CRow>
            <CCol col="7">
              <h5>
                <b>{{ experience.LIBELLE }}</b>
              </h5>
              <span
                  v-if="experience.DT_DEBUT && experience.DT_FIN">Du {{
                  format_date(experience.DT_DEBUT)
                }} au {{ format_date(experience.DT_FIN) }}</span>
              <span v-else-if="experience.DT_DEBUT">Depuis le {{ format_date(experience.DT_DEBUT) }}</span>
              <span v-else-if="experience.DT_FIN">Jusqu'au {{ format_date(experience.DT_DEBUT) }}</span>
            </CCol>
            <CCol class="text-right">
              <b v-if="$parent.company">{{ $parent.company.name }}</b>
              <b v-else>{{ $parent.efa_societe.libelle }}</b>
              <br>
              <small class="text-muted">
                <i>
                  {{ $t("cv.content.approved.true") }}
                  <font-awesome-icon icon="check" size="lg" class="text-success"></font-awesome-icon>
                </i>
              </small>
            </CCol>
            <CCol class="text-center border-left">
              <h6>Affiché sur le CV Public</h6>
              <CSwitch shape="pill" color="success" :checked="experience.show"
                       @update:checked="experience.show = !experience.show"></CSwitch>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCollapse :show="collapseShow === 'exphistory-'+index">
          <CCardBody>
            <CRow>
              <template v-for="skill in experience.skills">
                <CCol col="6"
                      v-if="$parent.resume.skills_with_level && $parent.resume.skills_with_level.length > 0 && $parent.resume.skills_with_level.find(e=>e.skill_id === skill.ID) && $parent.resume.skills_with_level.find(e=>e.skill_id === skill.ID).manager_rated_level >= 2"
                      :key="'skill_history_' + skill.ID" class="w-50 min-width-500">
                  <BadgeExperience
                      :data="{name:skill.LIBELLE, description:skill.DESCRIPTIF, rating:$parent.resume.skills_with_level.find(e=>e.skill_id === skill.ID).manager_rated_level, ratename:$parent.resume.skills_with_level.find(e=>e.skill_id === skill.ID).rated_level_name}">
                  </BadgeExperience>
                </CCol>
              </template>
            </CRow>
          </CCardBody>
        </CCollapse>
      </CCard>
      <!--/ Partie des expériences pro (jobsdescription_history)-->

      <!--Partie des expériences pro (jobsdescription_custom)-->
      <CCard v-for="(experience, index) in $parent.resume.jobdescriptions_custom" :key="'exp_custom_' + experience.ID">
        <CCardHeader @click="showCollapse('expcustom-'+index)" class="btn text-left">
          <CRow>
            <CCol col="7">
              <h5><b>{{ experience.FONCTION }}</b></h5>
              <span
                  v-if="experience.DTDEBUT && experience.DTFIN">Du {{
                  format_date(experience.DTDEBUT)
                }} au {{ format_date(experience.DTFIN) }}</span>
              <span v-else-if="experience.DTDEBUT">Depuis le {{ format_date(experience.DTDEBUT) }}</span>
              <span v-else-if="experience.DTFIN">Jusqu'au {{ format_date(experience.DTDEBUT) }}</span>
            </CCol>
            <CCol class="text-right">
              <b>{{ experience.EMPLOYEUR || '&nbsp;' }}</b>
              <br>
              <small class="text-muted">
                <i>
                  {{ $t("cv.content.approved.false") }}
                </i>
              </small>
            </CCol>
            <CCol class="text-center border-left">
              <h6>Affiché sur le CV Public</h6>
              <CSwitch shape="pill" color="success" :checked="experience.show"
                       @update:checked="experience.show = !experience.show"></CSwitch>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCollapse :show="collapseShow === 'expcustom-'+index">
          <CCardBody>
            <CRow>
              <template
                  v-for="skill in $parent.resume.skills_custom.filter(c => c.efa_experience_id === experience.ID)">
                <CCol col="6" v-if="skill.level >= 2" :key="'skill_custom_' + skill.efa_id" class="w-50 min-width-500">
                  <BadgeExperience
                      :data="{name:skill.name, description:skill.context, rating:skill.level }"></BadgeExperience>
                </CCol>
              </template>
            </CRow>
          </CCardBody>
        </CCollapse>
      </CCard>
      <!--/ Partie des expériences pro (jobsdescription_custom)-->
    </CCardGroup>

    <!-- Section des compétences extra pro -->
    <div v-if="$parent.resume.skills_custom.filter(c => c.efa_experience_id === null && c.level > 2).length > 0"
         class="mt-5">
      <CRow>
        <CCol>
          <h3>{{ $t('cv.content.skills') }}</h3>
        </CCol>
      </CRow>
      <CCardGroup class="d-block align-items-center col-12">
        <CCard v-for="(skill) in $parent.resume.skills_custom.filter(c => c.efa_experience_id === null)"
               :key="'skill_other_' + skill._id">
          <CCardHeader>
            <CCardTitle>
              <CRow>
                <CCol col="7">
                  <h5>{{ skill.name }}</h5>
                </CCol>
                <CCol>
                  <small>
                    <star-rating :level="skill.level"></star-rating>
                  </small>
                </CCol>
                <CCol class="text-center border-left">
                  <h6>Affiché sur le CV Public</h6>
                  <CSwitch shape="pill" color="success" :checked="skill.show"
                           @update:checked="skill.show = !skill.show"></CSwitch>
                </CCol>
              </CRow>
            </CCardTitle>
          </CCardHeader>
        </CCard>
      </CCardGroup>
    </div>
    <!-- /Section des compétences extra pro -->

    <!--Section des formations-->
    <div v-if="$parent.resume.trainings.length > 0" class="mt-5">
      <CRow>
        <CCol>
          <h3>{{ $t('cv.content.training') }}</h3>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CCardGroup class="d-block align-items-center col-12">
            <CCard v-for="(training, index) in $parent.resume.trainings" :key="'training_' + index + '_' + training.ID">
              <CCardHeader @click="showCollapse('tra-'+index)" class="btn text-left">
                <CRow>
                  <CCol col="7">
                    <h5>
                      <b>{{ training.LIBELLE }}</b>
                    </h5>
                    {{ format_date(training.DTFINFORMATION) }}
                  </CCol>
                  <CCol class="text-right"><b>{{ $parent.company.name || $parent.efa_societe.libelle }}</b></CCol>
                  <CCol class="text-center border-left">
                    <h6>Affiché sur le CV Public</h6>
                    <CSwitch shape="pill" color="success" :checked="training.show"
                             @update:checked="training.show = !training.show"></CSwitch>
                  </CCol>
                </CRow>
              </CCardHeader>
              <CCollapse :show="collapseShow === 'tra-'+index">
                <CCardBody>
                  <CRow>
                    <CCol v-if="training.DIPLOME" col="3" class="text-right">
                      <font-awesome-icon icon="graduation-cap" size="lg" v-c-tooltip="'Diplôme'" color="primary"/>
                      :
                      <span v-if="training.DIPLOME">{{ training.DIPLOME }}</span>
                      <span v-else>Aucune diplône n'est renseigné</span>
                    </CCol>
                    <CCol>
                      <font-awesome-icon icon="info-circle" size="lg"/>
                      :
                      <span v-if="training.DESCRIPTION">{{ training.DESCRIPTION }}</span>
                      <span v-else>Aucune compétences n'est disponible</span>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCollapse>
            </CCard>
            <CCard v-for="(training, index) in $parent.resume.trainings_custom" :key="'training_custom_' + training.ID">
              <CCardHeader @click="showCollapse('tra_custom-'+index)" class="btn text-left">
                <CRow>
                  <CCol col="7">
                    <h5>
                      <b>{{ training.LIBELLE }}</b>
                    </h5>
                    Du {{ format_date(training.DTDEBUT) }} au {{ format_date(training.DTFIN) }}
                  </CCol>
                  <CCol>&nbsp;</CCol>
                  <CCol class="text-center border-left">
                    <h6>Affiché sur le CV Public</h6>
                    <CSwitch shape="pill" color="success" :checked="training.show"
                             @update:checked="training.show = !training.show"></CSwitch>
                  </CCol>
                </CRow>
              </CCardHeader>
              <CCollapse :show="collapseShow === 'tra_custom-'+index">
                <CCardBody>
                  <CRow>
                    <CCol v-if="training.DIPLOME" col="3" class="text-right">
                      <font-awesome-icon icon="graduation-cap" size="lg" v-c-tooltip="'Diplôme'" color="primary"/>
                      :
                      <span v-if="training.DIPLOME">{{ training.DIPLOME }}</span>
                      <span v-else>Aucune diplône n'est renseigné</span>
                    </CCol>
                    <CCol>
                      <font-awesome-icon icon="info-circle" size="lg"/>
                      :
                      <span v-if="training.COMPETENCESACQUISES">{{ training.COMPETENCESACQUISES }}</span>
                      <span v-else>Aucune compétences n'est disponible</span>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCollapse>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </div>
    <!--/ Section des formations-->

    <!--Section des réglementations-->
    <div v-if="$parent.resume.regulations.length && $parent.resume.regulations.length > 0" class="mt-5">
      <CRow>
        <CCol>
          <h3>{{ $t('cv.content.regulation') }}</h3>
        </CCol>
      </CRow>
      <CCardGroup class="d-block align-items-center col-12">
        <CCard v-for="reglementation in $parent.resume.regulations"
               :key="'regulation_' + reglementation.efa_regulation_id">
          <CCardHeader>
            <CRow>
              <CCol col="7">
                <CCardTitle>
                  <h5>{{ reglementation.libelle }}</h5>
                </CCardTitle>
                <span
                    v-if="reglementation.startdate && reglementation.enddate">Du {{
                    format_date(reglementation.startdate)
                  }} au {{ format_date(reglementation.enddate) }}</span>
                <span v-else-if="reglementation.startdate">Depuis le {{ format_date(reglementation.startdate) }}</span>
                <span v-else-if="reglementation.enddate">Jusqu'au {{ format_date(reglementation.enddate) }}</span>
              </CCol>
              <CCol>&nbsp;</CCol>
              <CCol class="text-center border-left">
                <h6>Affiché sur le CV Public</h6>
                <CSwitch shape="pill" color="success" :checked="reglementation.show"
                         @update:checked="reglementation.show = !reglementation.show"></CSwitch>
              </CCol>
            </CRow>
          </CCardHeader>
        </CCard>
      </CCardGroup>
    </div>
    <!--/ Section des réglementations-->

    <!--Section des langues-->
    <div v-if="$parent.resume.languages.length > 0" class="mt-5">
      <CRow>
        <CCol>
          <h3>{{ $t('cv.content.language.index') }}</h3>
        </CCol>
      </CRow>
      <CCardGroup class="d-block align-items-center col-12">
        <CCard v-for="language in $parent.resume.languages" :key="'language_' + language._id">
          <CCardHeader>
            <CRow>
              <CCol col="2">
                <CCardTitle>
                  <h5>{{ language.name }}</h5>
                </CCardTitle>
              </CCol>
              <CCol col="5" class="text-center">
                <CRow>
                  <CCol class="text-center">
                    <b>{{ $t("cv.content.language.read") }}</b>
                    <br>
                    {{ language.lire }}
                  </CCol>
                  <CCol class="text-center">
                    <b>{{ $t("cv.content.language.write") }}</b>
                    <br>
                    {{ language.ecrire }}
                  </CCol>
                  <CCol class="text-center">
                    <b>{{ $t("cv.content.language.listen") }}</b>
                    <br>
                    {{ language.ecouter }}
                  </CCol>
                  <CCol class="text-center">
                    <b>{{ $t("cv.content.language.speak") }}</b>
                    <br>
                    {{ language.s_exprimer }}
                  </CCol>
                  <CCol class="text-center">
                    <b>{{ $t("cv.content.language.converse") }}</b>
                    <br>
                    {{ language.converser }}
                  </CCol>
                </CRow>
              </CCol>
              <CCol>&nbsp;</CCol>
              <CCol class="text-center border-left">
                <h6>Affiché sur le CV Public</h6>
                <CSwitch shape="pill" color="success" :checked="language.show"
                         @update:checked="language.show = !language.show"></CSwitch>
              </CCol>
            </CRow>
          </CCardHeader>
        </CCard>
      </CCardGroup>
    </div>
    <!--/ Section des langues-->

    <!--Section des permis -->
    <div v-if="$parent.resume.driving_licenses.length > 0" class="mt-5">
      <CRow>
        <CCol>
          <h3>{{ $t('cv.content.permis') }}</h3>
        </CCol>
      </CRow>
      <CCardGroup class="d-block align-items-center col-12">
        <CCard v-for="singlePermis in $parent.resume.driving_licenses" :key="'permis_' + singlePermis.id_permis">
          <CCardHeader>
            <CRow>
              <CCol col="7">
                <CCardTitle>
                  <h5>
                    {{ singlePermis.name }}
                  </h5>
                </CCardTitle>
              </CCol>
              <CCol>&nbsp;</CCol>
              <CCol class="text-center border-left">
                <h6>Affiché sur le CV Public</h6>
                <CSwitch shape="pill" color="success" :checked="singlePermis.show"
                         @update:checked="singlePermis.show = !singlePermis.show"></CSwitch>
              </CCol>
            </CRow>
          </CCardHeader>
        </CCard>
      </CCardGroup>
    </div>
    <!--/ Section des permis-->
    <CRow class="mt-4 mx-3 mb-4">
      <CCol>
        <CButton color="danger" @click="$parent.load()">
          <font-awesome-icon icon="times"></font-awesome-icon>
          {{ $t('buttons.cancel') }}
        </CButton>
      </CCol>
      <CCol class="text-right">
        <CButton color="success" @click="$parent.save()">
          <font-awesome-icon icon="save"></font-awesome-icon>
          Valider
        </CButton>
      </CCol>
    </CRow>
  </CForm>
</template>

<script>
import BadgeExperience from "../BadgeExperience";
import StarRating from "../StarRating";
import {formatDate} from '@/helpers';

export default {
  name: "ResumeFormStep1",
  components: {
    BadgeExperience,
    StarRating
  },
  data() {
    return {
      collapseShow: '',
    };
  },
  methods: {
    showCollapse: function (id) {
      if (id === this.collapseShow) {
        this.collapseShow = '';
      } else {
        this.collapseShow = id;
      }
    },
    format_date(value) {
      return formatDate(value)
    },
  },
  mounted() {
    console.log(this.$parent.resume);
  }
}
</script>

<style scoped>

</style>