import {i18n} from "../../lang/lang";
import {Roles} from "../../config";

import Search from "../../views/search/Search";

import profiles from './profiles'
import joboffers from "./joboffers";
import savedSearch from './savedSearch';

let routes = [];
routes = routes.concat(profiles);
routes = routes.concat(joboffers);
routes = routes.concat(savedSearch);

export default [
    {
        path: 'search',
        name: i18n.t('routes.search.index'),
        component: Search,
        meta: {
            authorize: [Roles.Admin, Roles.Company_Administrator, Roles.Recruiter], //doit être connecté et avoir le role Admin
            title: "JobInclusif.fr - " + i18n.t('routes.search.index')
        },
        children: routes
    }
]