import {handleResponse, requestOptions} from '@/helpers';

//------------------Export des routes----------------//
export const applicationsService = {
    create,
    update,
    remove,
    getAll,
    getById,
    getByResumeAndJobId,
    getByIdResume,
    getByIdCip,
    getByIdCipAndIdJoboffer,
    getByIdJoboffer,
}
//---------------------------------------------------//


//---------------------Routes------------------------//
/**
 *
 * @param application
 * @returns {Promise<Response>}
 */
function create(application) {
    return fetch(`${process.env.VUE_APP_API_URI}/applications`, requestOptions.post({application: application}))
        .then(handleResponse);
}

/**
 *
 * @param application
 * @returns {Promise<Response>}
 */
function update(application) {
    return fetch(`${process.env.VUE_APP_API_URI}/applications`, requestOptions.put({application: application}))
        .then(handleResponse);
}

/**
 *
 * @param id
 * @returns {Promise<Response>}
 */
function remove(id) {
    return fetch(`${process.env.VUE_APP_API_URI}/applications`, requestOptions.delete({id: id}))
        .then(handleResponse);
}

/**
 *
 * @returns {Promise<Response>}
 */
function getAll() {
    return fetch(`${process.env.VUE_APP_API_URI}/applications`, requestOptions.get())
        .then(handleResponse);
}

/**
 *
 * @param id
 * @returns {Promise<Response>}
 */
function getById(id) {
    return fetch(`${process.env.VUE_APP_API_URI}/applications/${id}`, requestOptions.get())
        .then(handleResponse);
}

/**
 *
 * @param id_resume
 * @param id_joboffer
 * @returns {Promise<Response>}
 */
function getByResumeAndJobId(id_resume, id_joboffer) {
    return fetch(`${process.env.VUE_APP_API_URI}/applications/` + id_resume + '/' + id_joboffer, requestOptions.get())
        .then(handleResponse);
}

/**
 *
 * @param id
 */
function getByIdResume(id) {
    return fetch(`${process.env.VUE_APP_API_URI}/applications/all/` + id, requestOptions.get())
        .then(handleResponse);
}

/**
 *
 * @param id
 * @param id_joboffer
 * @returns {Promise<Response>}
 */
function getByIdCipAndIdJoboffer(id, id_joboffer) {
    return fetch(`${process.env.VUE_APP_API_URI}/applications/cipandjobid/` + id + '/' + id_joboffer, requestOptions.get())
        .then(handleResponse);
}

/**
 *
 * @param id
 * @returns {Promise<Response>}
 */
function getByIdCip(id) {
    return fetch(`${process.env.VUE_APP_API_URI}/applications/cipid/${id}`, requestOptions.get())
        .then(handleResponse);
}

/**
 *
 * @param id
 * @returns {Promise<Response>}
 */
function getByIdJoboffer(id) {
    return fetch(`${process.env.VUE_APP_API_URI}/applications/jobid/${id}`, requestOptions.get())
        .then(handleResponse);
}

//---------------------------------------------------//