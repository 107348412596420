import {i18n} from "../lang/lang";
import Application from "../views/applications/Applications";


export default [
    {
        path: 'applications',
        name: i18n.t('routes.applications.index'),
        component: Application,
        meta:{
            authorize: [],
            title: "JobInclusif.fr - " + i18n.t('routes.applications.index')
        }
    }
]