<template>
    <CForm>
        <CRow>
            <CCol>
                <h4>Mes postes recherchés</h4>
            </CCol>
        </CRow>
        <CCardGroup class="d-block align-items-center col-12">
            <CCard v-for="(fct, index) in $parent.resume.jobdescriptions_target" :key="'fct_' + index">
                <CCardHeader>
                    <CRow>
                        <CCol col="7">
                            <CCardTitle>
                                <h5>{{fct.LIBELLE}}</h5>
                            </CCardTitle>
                        </CCol>
                        <CCol>&nbsp;</CCol>
                        <CCol class="text-center border-left">
                            <CButton color="danger" @click="removeFunction(index)">
                                <font-awesome-icon icon="trash"></font-awesome-icon>
                                {{$t('buttons.trash')}}
                            </CButton>
                        </CCol>
                    </CRow>
                </CCardHeader>
            </CCard>
            <CCard v-if="listNotEmpty">
                <CCardBody>
                    <CAlert color="danger">
                        Vous devez au moins choisir une fonction
                    </CAlert>
                </CCardBody>
            </CCard>
        </CCardGroup>
        <template v-if="$parent.resume.jobdescriptions && $parent.resume.jobdescriptions.length">
            <CRow class="mt-5">
                <CCol>
                    <h4>Ajouter mes fonctions actuelles ou passées</h4>
                </CCol>
            </CRow>
            <CCardGroup class="d-block align-items-center col-12">
                <CCard v-for="(fct, index) in $parent.resume.jobdescriptions" :key="'fct_' + index">
                    <CCardHeader>
                        <CRow>
                            <CCol col="7">
                                <CCardTitle>
                                    <h5>{{fct.LIBELLE}}</h5>
                                </CCardTitle>
                            </CCol>
                            <CCol>&nbsp;</CCol>
                            <CCol class="text-center border-left">
                                <CButton color="success" @click="addCurrentFunction(fct)" :disabled="existsInList(fct.LIBELLE)" v-if="!existsInList(fct.LIBELLE)">
                                    <font-awesome-icon icon="plus"></font-awesome-icon>
                                    {{$t('buttons.add')}}
                                </CButton>
                                <small class="text-danger" v-else><b>Cette fonction a déjà été ajoutée !</b></small>
                            </CCol>
                        </CRow>
                        <CRow >
                            <CCol>

                            </CCol>
                        </CRow>
                    </CCardHeader>
                </CCard>
                <CCard v-for="(fct, index) in $parent.resume.jobdescriptions_history" :key="'fct_hist_' + index">
                    <CCardHeader>
                        <CRow>
                            <CCol col="7">
                                <CCardTitle>
                                    <h5>{{fct.LIBELLE}}</h5>
                                </CCardTitle>
                            </CCol>
                            <CCol>&nbsp;</CCol>
                            <CCol class="text-center border-left">
                                <CButton color="success" @click="addCurrentFunction(fct)" :disabled="existsInList(fct.LIBELLE)" v-if="!existsInList(fct.LIBELLE)">
                                    <font-awesome-icon icon="plus"></font-awesome-icon>
                                    {{$t('buttons.add')}}
                                </CButton>
                                <small class="text-danger" v-else><b>Cette fonction a déjà été ajoutée !</b></small>
                            </CCol>
                        </CRow>
                        <CRow >
                            <CCol>

                            </CCol>
                        </CRow>
                    </CCardHeader>
                </CCard>
                <CCard v-for="(fct, index) in $parent.resume.jobdescriptions_custom" :key="'fct_custom_' + index">
                    <CCardHeader>
                        <CRow>
                            <CCol col="7">
                                <CCardTitle>
                                    <h5>{{fct.FONCTION}}</h5>
                                </CCardTitle>
                            </CCol>
                            <CCol>&nbsp;</CCol>
                            <CCol class="text-center border-left">
                                <CButton color="success" @click="addCurrentFunction(fct)" :disabled="existsInList(fct.FONCTION)" v-if="!existsInList(fct.FONCTION)">
                                    <font-awesome-icon icon="plus"></font-awesome-icon>
                                    {{$t('buttons.add')}}
                                </CButton>
                                <small class="text-danger" v-else><b>Cette fonction a déjà été ajoutée !</b></small>
                            </CCol>
                        </CRow>
                        <CRow >
                            <CCol>

                            </CCol>
                        </CRow>
                    </CCardHeader>
                </CCard>
            </CCardGroup>
        </template>
        <CRow class="mt-5">
            <CCol>
                <h4>Rechercher dans la liste</h4>
            </CCol>
        </CRow>
        <CRow>
            <CCol>
                <label for="select_secteur">{{$t('searches.select.sector')}}</label>
                <v-select id="select_secteur" label="name" :options="secteurs" v-model="selected_secteur" @input="changeSecteur()"></v-select>
            </CCol>
            <CCol>
                <label for="select_metier">{{$t('searches.select.job')}}</label>
                <v-select id="select_metier" label="name" :options="metiers" v-model="selected_metier" @input="changeMetier()"></v-select>
            </CCol>
            <CCol>
                <label for="select_fonction">{{$t('searches.select.function')}}</label>
                <v-select id="select_fonction" label="name" :options="fonctions"  v-model="selected_fonction" @input="changeFonction()"></v-select>
            </CCol>
            <CCol col="3">
                <br>
                <CButton color="success" block class="mt-2" @click="addFunction()" :disabled="!selected_fonction || existsInList(selected_fonction.name)">
                    <font-awesome-icon icon="plus"></font-awesome-icon>
                    {{$t('buttons.add')}}
                </CButton>
            </CCol>
        </CRow>
        <CRow v-if="selected_fonction && existsInList(selected_fonction.name)">
            <CCol class="text-right">
                <small class="text-danger">
                    <b>Cette fonction a déjà été ajoutée !</b>
                </small>
            </CCol>
        </CRow>
        <CRow class="mt-5">
            <CCol>
                <h4>Saisir une nouvelle fonction</h4>
            </CCol>
        </CRow>
        <CRow>
            <CCol>
                <CInput v-model="new_function" placeholder="Saisissez un nom de fonction"></CInput>
                <small class="text-danger" v-if="new_function.length < 3">
                    <b>Le nom de la fonction doit contenir au moins 3 caractères</b>
                </small>
            </CCol>
            <CCol col="3">
                <CButton color="success" block @click="addNewFunction()" :disabled="existsInList(new_function) || new_function.length < 3">
                    <font-awesome-icon icon="plus"></font-awesome-icon>
                    {{$t('buttons.add')}}
                </CButton>
                <small class="text-danger" v-if="existsInList(new_function)">
                    <br>
                    <b>Cette fonction a déjà été ajoutée !</b>
                </small>
            </CCol>
        </CRow>
        <CRow class="mt-4 mx-3 mb-4">
            <CCol>
                <CButton color="danger" @click="$parent.load()">
                    <font-awesome-icon icon="times"></font-awesome-icon>
                    {{$t('buttons.cancel')}}
                </CButton>
            </CCol>
            <CCol class="text-right">
                <CButton color="success" @click="save()" :disabled="listNotEmpty">
                    <font-awesome-icon icon="save"></font-awesome-icon>
                    {{$t('buttons.validate')}}
                </CButton>
            </CCol>
        </CRow>
    </CForm>
</template>

<script>
    import {structureService} from "@/services";
    import {removeAccent} from "@/helpers";

    export default {
        name: "ResumeFormStep2",
        data() {
            return {
                secteurs: [],
                metiers: [],
                fonctions: [],
                selected_secteur: null,
                selected_metier: null,
                selected_fonction: null,
                new_function: ""
            }
        },
        methods: {
            existsInList(name) {
                name = removeAccent(name);
                for(let i = 0; i < this.$parent.resume.jobdescriptions_target.length; i++) {
                    if(removeAccent(this.$parent.resume.jobdescriptions_target[i].LIBELLE) === name) return true;
                }
                return false;
            },
            removeDoubles: function(array_to_sort) {
                return array_to_sort.filter((v,i) => {
                    return array_to_sort.map(e => {
                        return e.name.toLowerCase();
                    }).indexOf(v.name.toLowerCase()) === i;
                });
            },
            sortByName: function (array_to_sort) {
                return array_to_sort.sort((a,b) => {
                    const nameA=a.name.toLowerCase(),
                        nameB=b.name.toLowerCase()
                    ;
                    if (nameA < nameB) //sort string ascending
                        return -1;
                    if (nameA > nameB)
                        return 1;
                    return 0;
                });
            },
            changeSecteur: function () {
                this.selected_metier = null;
                this.search_string = "";
                if(this.selected_secteur) {
                    let metiers = [];
                    let fonctions = [];
                    this.selected_secteur.metiers.forEach(metier => {
                        metiers.push(metier);
                        metier.fonctions.forEach(fonction => {
                            fonctions.push(fonction);
                        });
                    });
                    this.metiers = this.removeDoubles(this.sortByName(metiers));
                    this.fonctions = this.removeDoubles(this.sortByName(fonctions));
                }
            },
            changeMetier: function () {
                this.search_string = "";
                if(this.selected_metier) {
                    let fonctions = [];
                    this.selected_metier.fonctions.forEach(fonction => {
                        fonctions.push(fonction);
                    });
                    this.fonctions = this.removeDoubles(this.sortByName(fonctions));
                }
            },
            changeFonction() {
                if(this.selected_fonction) {
                    if (!this.selected_metier) {
                        this.metiers.forEach(m => {
                            if (m.fonctions.includes(this.selected_fonction)) {
                                if (!this.selected_secteur) {
                                    this.secteurs.forEach(s => {
                                        if (s.metiers.includes(m)) {
                                            this.selected_secteur = s;
                                        }
                                    });
                                }
                                if (this.selected_secteur) this.changeSecteur();
                                this.selected_metier = m;
                            }
                        });
                    }
                    if (this.selected_metier) {
                        this.changeMetier();
                    }
                }
            },
            addFunction() {
                this.$parent.resume.jobdescriptions_target.push({
                    ...this.selected_fonction,
                    LIBELLE: this.selected_fonction.name
                });
                this.selected_secteur = null;
                this.selected_metier = null;
                this.selected_fonction = null;
                this.loadStructure();
            },
            addNewFunction() {
                this.$parent.resume.jobdescriptions_target.push({
                    LIBELLE: this.new_function
                });
                this.new_function = "";
            },
            addCurrentFunction(fct) {
                this.$parent.resume.jobdescriptions_target.push({LIBELLE: fct.LIBELLE || fct.FONCTION});
            },
            removeFunction(index) {
                this.$parent.resume.jobdescriptions_target.splice(index,1);
            },
            loadStructure() {
                structureService.getAllCS().then(s => {
                    let secteurs = [], metiers = [], fonctions = [];
                    secteurs = s;
                    for(let i = 0; i < s.length; i++) {
                        const m = s[i].metiers;
                        for(let j = 0; j < m.length; j++) {
                            metiers.push(m[j]);
                            const f = m[j].fonctions;
                            for(let k = 0; k < f.length; k++) {
                                fonctions.push(f[k]);
                            }
                        }
                    }
                    this.secteurs = this.removeDoubles(this.sortByName(secteurs));
                    this.metiers = this.removeDoubles(this.sortByName(metiers));
                    this.fonctions = this.removeDoubles(this.sortByName(fonctions));
                });
            },
            save() {
                if(this.$parent.resume.jobdescriptions_target.length > 0) {
                    this.$parent.save();
                }
            }
        },
        computed: {
            listNotEmpty() {
                return this.$parent.resume.jobdescriptions_target && (this.$parent.resume.jobdescriptions_target.length < 1);
            }
        },
        mounted() {
            this.loadStructure();
        }
    }
</script>

<style scoped>

</style>