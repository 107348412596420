export default {
    'en-US': {
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
    },
    'fr-FR': {
        short: {
            year: 'numeric', month: 'long', day: 'numeric'
        },
    }
}