<template>
    <div v-if="userFromApi">
        <CCard>
            <CCardHeader>
                <CRow>
                    <CCol>
                        <CCardTitle>
                            {{$t('routes.admin.members.index')}}
                        </CCardTitle>
                    </CCol>
                    <CCol class="text-right">
                        <CButton color="info" @click="loadWithDeleted()" v-if="!deleted_displayed">
                            Afficher les utilisateurs archivés
                        </CButton>
                        <CButton color="info" @click="load()" v-else>
                            masquer les utilisateurs archivés
                        </CButton>
                        <CButton color="success" :to="'/app/' + baseRoute + 'members/create'" class="ml-2">
                            <font-awesome-icon icon="plus"></font-awesome-icon>
                            {{$t('routes.admin.members.create')}}
                        </CButton>
                    </CCol>
                </CRow>
            </CCardHeader>
            <CCardBody>
                <CDataTable :items="usersData"
                            :fields="usersFields"
                            :striped="true"
                            :border="true"
                            :small="true"
                            :sorter="{ resetable: true }"
                            :columnFilter="true"
                            :columnFilterValue="{ username: '' }"
                            :tableFilter="{ placeholder: $t('datatable.search_placeholder'), label: $t('datatable.search') }"
                            :loading="false"
                            :pagination="true"
                            :itemsPerPage="25"
                            :itemsPerPageSelect="{ label: $t('datatable.elements_count'), values:[1,10,25,50,100,250,500,1000] }"
                            :noItemsView="{ noResults: $t('datatable.noResults'), noItems: $t('datatable.noItems') }"
                >
                    <td slot="avatar" class="text-center p-0" slot-scope="{item}" style="width: 25px;">
                        <div class="c-avatar m-1 border border-1">
                            <img :src="item.avatar" class="c-avatar-img" alt="">
                        </div>
                    </td>
                    <td slot="email" slot-scope="{item}" style="width: 25px;">
                        {{item.email || ""}}
                    </td>
                    <td slot="roles" slot-scope="{item}">
                        <CBadge v-for="r in item.roles" v-bind:key="r" class="m-1 border border-1 border-dark">
                            {{ $t(`roles.${r}`) }}
                        </CBadge>
                    </td>
                    <td slot="actions" slot-scope="{item}">
                        <CButtonGroup v-if="trash_index !== item._id && !item.deletedAt">
                            <CButton :to="'/app/' + baseRoute + 'members/update/' + item._id" color="warning" size="sm">
                                <font-awesome-icon icon="edit"></font-awesome-icon>
                                {{$t('buttons.update')}}
                            </CButton>
                            <button class="btn btn-sm btn-danger" v-if="item.source === 'JobInclusif'" @click="trash_index = item._id">
                                <font-awesome-icon icon="trash"></font-awesome-icon>
                                {{$t('buttons.trash')}}
                            </button>
                            <CButton color="primary" :to="'/login/' + item.uuid" v-if="userFromApi.roles.includes(Roles.Admin) && item.uuid">
                                <font-awesome-icon icon="sign-in-alt"></font-awesome-icon>
                                Se connecter
                            </CButton>
                        </CButtonGroup>
                        <CButtonGroup v-if="trash_index && trash_index === item._id">
                            <button class="btn btn-sm btn-success" @click="trash(item._id)">
                                <font-awesome-icon icon="check"></font-awesome-icon>
                                {{$t('buttons.trash')}}
                            </button>
                            <button class="btn btn-sm btn-danger" @click="trash_index = ''">
                                <font-awesome-icon icon="times"></font-awesome-icon>
                                {{$t('buttons.cancel')}}
                            </button>
                        </CButtonGroup>
                        <CButton color="info" v-if="item.deletedAt" @click="restore(item._id)">
                            <font-awesome-icon icon="trash-restore"></font-awesome-icon>
                            {{$t('buttons.restore')}}
                        </CButton>
                    </td>
                </CDataTable>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
    import {authenticationService, memberService} from '@/services';
    import {randomIntFromInterval, initialsAvatar, authorised_in_view} from "@/helpers";
    import {AvatarColors} from "@/config";
    import {Roles} from "../../../config";

    export default {
        name: "Members",
        data() {
            return {
                user: authenticationService.currentUserValue,
                userFromApi: null,
                users: [],
                trash_index: "",
                uri: "",
                deleted_displayed: false,
                baseRoute: '',
                Roles: Roles
            };
        },
        methods: {
            avatar(user) {
                const fullname = user.firstName + "+" + user.lastName;
                let color = AvatarColors[randomIntFromInterval(0, AvatarColors.length - 1)];
                if (user.colorIndex) color = AvatarColors[user.colorIndex];
                return initialsAvatar(fullname.replace(/ /g, '+'), color);
            },
            trash(id) {
                memberService.remove(id).then(() => {
                    if (this.deleted_displayed) this.loadWithDeleted();
                    else this.load();
                    this.trash_index = '';
                });
            },
            load() {
                memberService.getAll().then(users => {
                    this.users = users;
                    this.deleted_displayed = false;
                });
            },
            loadWithDeleted() {
                memberService.getAllWithDeleted().then(users => {
                    this.users = users;
                    this.deleted_displayed = true;
                });
            },
            restore(id) {
                memberService.restore(id).then(() => {
                    this.loadWithDeleted();
                });
            },
        },
        computed: {
            usersData() {
                const users = [];
                this.users.forEach(u => {
                    users.push({
                        source: u.id_efa ? "e-FormAdapt" : "JobInclusif",
                        avatar: this.avatar(u),
                        ...u
                    });
                })
                return users;
            },
            usersFields() {
                let fields = [
                    {key: 'avatar', label: "", filter: false, sorter: false},
                    {key: 'email', label: "E-Mail", filter: true},
                    {key: 'fullName', label: "Nom Complet", filter: true},
                ];
                if (authorised_in_view(Roles.Admin, this.userFromApi)) fields.push({key: 'company_name', label: "Société", filter: true});
                fields.push({key: 'source', label: "Source", filter: true});
                fields.push({key: 'roles', label: "Roles", filter: false, sorter: false});
                fields.push({key: 'actions', label: '', filter: false, sorter: false});
                return fields;
            }
        },
        mounted() {
            memberService.getById(this.user.id).then(user => {
                this.userFromApi = user;
                if (user.roles.includes(Roles.Admin)) this.baseRoute = 'admin/';
            });
            this.load();
        }
    };
</script>

<style scoped>

</style>