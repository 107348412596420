<template>
    <CSidebar fixed :minimize="minimize" :show="show"
              @update:show="(value) => $store.commit('set', ['sidebarShow', value])">
        <CSidebarBrand class="d-md-down-none" to="/">
            <img src="../../assets/images/Logo_long.png" class="c-sidebar-brand-full" height="35" name="logo">
            <img src="../../assets/images/Logo_short.png" class="c-sidebar-brand-minimized" height="35" name="logo">
        </CSidebarBrand>
        <CSidebarNav>
            <CSidebarNavItem :name="$t('routes.dashboard')" icon="cilHome" to="/app/dashboard"></CSidebarNavItem>

            <template v-if="(authorised([Roles.User]) && userFromApi.roles.length === 1) || (authorised([Roles.Manager]) && userFromApi.roles.length === 2) ||(authorised([Roles.Referral_Support]) && userFromApi.roles.length <= 3)">
                <CSidebarNavDivider></CSidebarNavDivider>
                <CSidebarNavItem :name="$t('dashboard.shortcuts.resume')" fontIcon="fas fa-id-card" :to="'/app/resumes/publish/' + (resume.publishStep === 4 ? 4 : resume.publishStep+1)" v-if="resume && resume._id"></CSidebarNavItem>
            </template>

            <template v-if="authorised([Roles.Admin_RH, Roles.Assistant_RH, Roles.Admin, Roles.User, Roles.Manager, Roles.Referral_Support])">
                <CSidebarNavDivider></CSidebarNavDivider>
                <CSidebarNavItem :name="$t('routes.admin_hr.partner_offers')" fontIcon="fas fa-handshake" to="/app/search/joboffers"></CSidebarNavItem>
            </template>

            <template v-if="(authorised([Roles.User]) && userFromApi.roles.length === 1) || (authorised([Roles.Manager]) && userFromApi.roles.length === 2) || (authorised([Roles.Referral_Support]))">
                <CSidebarNavDivider></CSidebarNavDivider>
                <CSidebarNavItem to="/app/applications" :name="authorised(Roles.Referral_Support) ? $t('dashboard.shortcuts.applications_cip') : $t('dashboard.shortcuts.applications')" fontIcon="fas fa-file-alt"></CSidebarNavItem>
            </template>

            <template v-if="authorised([Roles.Company_Administrator, Roles.Admin, Roles.Recruiter])">
                <CSidebarNavDivider></CSidebarNavDivider>
                <CSidebarNavItem :name="$t('routes.search.profiles.index')" fontIcon="fas fa-search" to="/app/search/profiles"></CSidebarNavItem>
                
                <CSidebarNavTitle>{{ $t('routes.company_administrator.recruit.index') }}</CSidebarNavTitle>
                <CSidebarNavItem :name="$t('routes.company_administrator.recruit.publish_offer')" fontIcon="fas fa-upload" to="/app/joboffers/create"></CSidebarNavItem>
                <CSidebarNavItem :name="$t('routes.company_administrator.recruit.my_profil_selection')" fontIcon="fas fa-user-check" to="/app/favorites"></CSidebarNavItem>
                <CSidebarNavItem :name="$t('routes.search.saved.index')" fontIcon="fas fa-save" to="/app/search/saved"></CSidebarNavItem>
                <CSidebarNavItem :name="$t('routes.company_administrator.recruit.my_reruitements')" fontIcon="fas fa-tasks" to="/app/joboffers"></CSidebarNavItem>
            </template>

            <template v-if="authorised([Roles.Admin, Roles.Admin_RH, Roles.Assistant_RH, Roles.Company_Administrator, Roles.Recruiter, Roles.Referral_Support])">
                <CSidebarNavTitle>{{ $t('routes.admin.index') }}</CSidebarNavTitle>
                <CSidebarNavItem :name="$t('routes.company_administrator.admin.members')" fontIcon="fas fa-users" to="/app/members" v-if="authorised([Roles.Company_Administrator])"></CSidebarNavItem>
                <CSidebarNavItem :name="$t('routes.admin_hr.admin.resume')" fontIcon="fas fa-id-card" to="/app/resumes/published" v-if="authorised([Roles.Admin_RH, Roles.Assistant_RH, Roles.Referral_Support])"></CSidebarNavItem>
                <CSidebarNavItem :name="$t('routes.companies.page')" fontIcon="fas fa-building" :to="'/app/companies/'+company_id" v-if="authorised([Roles.Admin_RH, Roles.Assistant_RH, Roles.Recruiter, Roles.Company_Administrator]) && company_id"></CSidebarNavItem>
            </template>
            <template v-if="authorised([Roles.Admin])">
                <CSidebarNavTitle>{{ $t('routes.admin.index_super') }}</CSidebarNavTitle>
                <CSidebarNavDropdown fontIcon="fas fa-users" :name="$t('routes.admin.members.category')">
                    <CSidebarNavItem :name="$t('routes.admin.members.index')" fontIcon="fas fa-users" to="/app/admin/members"></CSidebarNavItem>
                    <CSidebarNavItem :name="$t('routes.admin.members.create')" fontIcon="fas fa-user-plus" to="/app/admin/members/create"></CSidebarNavItem>
                </CSidebarNavDropdown>
                <CSidebarNavDropdown fontIcon="fas fa-city" :name="$t('routes.admin.companies.category')">
                    <CSidebarNavItem :name="$t('routes.admin.companies.index')" fontIcon="fas fa-city" to="/app/admin/companies"></CSidebarNavItem>
                    <CSidebarNavItem :name="$t('routes.admin.companies.create')" fontIcon="fas fa-building" to="/app/admin/companies/create"></CSidebarNavItem>
                </CSidebarNavDropdown>
            </template>

            <!-- Exemple avec icone CoreUI -->
            <!-- <CSidebarNavItem :name="$t('routes.members.index')" icon="cilSettings" to="/app/admin/members"></CSidebarNavItem> -->
            <!-- Exemple avec icone FontAwesome -->
            <!-- <CSidebarNavItem :name="$t('routes.members.index')" fontIcon="fas fa-users" to="/app/admin/members"></CSidebarNavItem> -->
        </CSidebarNav>
        <!--<CRenderFunction flat :content-to-render="$options.nav"/>-->
        <CSidebarMinimizer class="d-md-down-none" @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"/>
    </CSidebar>
</template>

<script>
    import {Roles} from '@/config';
    import {memberService, authenticationService, resumesService, efaSocieteService, companiesService} from '@/services';
    import {authorised_in_view} from '@/helpers';

    export default {
        name: 'TheSidebar',
        data() {
            return {
                Roles: Roles,
                currentUser: authenticationService.currentUserValue,
                userFromApi: null,
                resume: null,
                company_id: null,
            }
        },
        methods: {
            authorised(authorised_roles = []) {
                if(this.userFromApi) return authorised_in_view(authorised_roles, this.userFromApi);
                return false;
            },
        },
        computed: {
            show() {
                return this.$store.state.sidebarShow
            },
            minimize() {
                return this.$store.state.sidebarMinimize
            }
        },
        mounted() {
            memberService.getById(this.currentUser.id).then(user => {
                this.userFromApi = user;
                resumesService.getByUuid(user.uuid).then(r => this.resume = r);
                if(user.id_company) this.company_id = user.id_company; //lien entreprise recruteuses
                if(user.id_company_efa) {
                    efaSocieteService.getByEfaId(user.id_company_efa).then(societe => {
                        if(societe.siret) {
                            companiesService.getBySiret(societe.siret).then(c => this.company_id = c._id);
                        }
                    });
                }
            });
        }
    }
</script>
