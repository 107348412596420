<template>
  <router-view></router-view>
</template>

<script>
import { authenticationService } from '@/services';
import { router } from '@/routes/router';

export default {
  name: 'App',
  /*components: {
    HelloWorld
  },*/
  data () {
    return {
      currentUser: null
    };
  },
  created () {
    authenticationService.currentUser.subscribe(x => this.currentUser = x);
  },
  methods: {
    logout () {
      authenticationService.logout();
      router.push('/login');
    }
  }
}
</script>
<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
