<template>
    <CDropdown
            inNav
            class="c-header-nav-items"
            placement="bottom-end"
            add-menu-classes="pt-0"
    >
        <template #toggler>
            <CHeaderNavLink>
                <div class="c-avatar">
                    <img
                            :src="avatar"
                            class="c-avatar-img "
                            v-if="avatar"
                    />
                </div>
                <b class="ml-2">{{ userfullname }}</b>
            </CHeaderNavLink>
        </template>
        <!--    <CDropdownHeader tag="div" class="text-center" color="light">-->
        <!--      <strong>Account</strong>-->
        <!--    </CDropdownHeader>-->
        <!--    <CDropdownItem>-->
        <!--      <CIcon name="cil-bell"/> Updates-->
        <!--      <CBadge color="info" class="ml-auto">{{ itemsCount }}</CBadge>-->
        <!--    </CDropdownItem>-->
        <!--    <CDropdownItem>-->
        <!--      <CIcon name="cil-envelope-open" /> Messages-->
        <!--      <CBadge color="success" class="ml-auto">{{ itemsCount }}</CBadge>-->
        <!--    </CDropdownItem>-->
        <!--    <CDropdownItem>-->
        <!--      <CIcon name="cil-task" /> Tasks-->
        <!--      <CBadge color="danger" class="ml-auto">{{ itemsCount }}</CBadge>-->
        <!--    </CDropdownItem>-->
        <!--    <CDropdownItem>-->
        <!--      <CIcon name="cil-comment-square" /> Comments-->
        <!--      <CBadge color="warning" class="ml-auto">{{ itemsCount }}</CBadge>-->
        <!--    </CDropdownItem>-->
        <!--    <CDropdownHeader-->
        <!--      tag="div"-->
        <!--      class="text-center"-->
        <!--      color="light"-->
        <!--    >-->
        <!--      <strong>Settings</strong>-->
        <!--    </CDropdownHeader>-->
        <!--    <CDropdownItem>-->
        <!--      <CIcon name="cil-user" /> Profile-->
        <!--    </CDropdownItem>-->
        <!--    <CDropdownItem>-->
        <!--      <CIcon name="cil-settings" /> Settings-->
        <!--    </CDropdownItem>-->
        <!--    <CDropdownItem>-->
        <!--      <CIcon name="cil-dollar" /> Payments-->
        <!--      <CBadge color="secondary" class="ml-auto">{{ itemsCount }}</CBadge>-->
        <!--    </CDropdownItem>-->
        <!--    <CDropdownItem>-->
        <!--      <CIcon name="cil-file" /> Projects-->
        <!--      <CBadge color="primary" class="ml-auto">{{ itemsCount }}</CBadge>-->
        <!--    </CDropdownItem>-->
        <!--    <CDropdownDivider/>-->
        <!--    <CDropdownItem>-->
        <!--      <CIcon name="cil-shield-alt" /> Lock Account-->
        <!--    </CDropdownItem>-->
        <template v-if="authorised([Roles.Company_Administrator, Roles.Recruiter])">
            <CDropdownItem to="/app/changepassword">
                <font-awesome-icon icon="key" class="mr-2"></font-awesome-icon>
                {{$t('routes.change_password')}}
            </CDropdownItem>
            <CDropdownDivider></CDropdownDivider>
        </template>
        <CDropdownItem to="/app/help/contact">
            <font-awesome-icon :icon="['far', 'question-circle']" class="mr-2"></font-awesome-icon>
            Contacter le support
        </CDropdownItem>
        <CDropdownDivider></CDropdownDivider>
        <CDropdownItem to="/app/logout">
            <CIcon name="cil-lock-locked"/>
            {{ $t('routes.logout') }}
        </CDropdownItem>
    </CDropdown>
</template>

<script>
    import {memberService, authenticationService} from '@/services';
    import {AvatarColors, Roles} from '@/config';
    import {randomIntFromInterval, initialsAvatar, authorised_in_view} from "@/helpers";

    export default {
        name: 'TheHeaderDropdownAccent',
        data() {
            return {
                Roles: Roles,
                currentUser: authenticationService.currentUserValue,
                userFromApi: null,
                itemsCount: 42
            }
        },
        methods: {
            randomAvatarColor() {
                return AvatarColors[randomIntFromInterval(0, AvatarColors.length - 1)].replace(/#/g, '');
            },
            authorised(authorised_roles = []) {
                if (this.userFromApi) return authorised_in_view(authorised_roles, this.userFromApi);
                return false;
            },
        },
        computed: {
            userfullname() {
                return this.currentUser.firstName + ' ' + this.currentUser.lastName;
            },
            avatar() {
                if (this.userFromApi) {
                    const firtsname = this.userFromApi.firstName.trim().replace(/ /g, "+");
                    const lastname = this.userFromApi.lastName.trim().replace(/ /g, "+");
                    let color = this.randomAvatarColor();
                    if (this.userFromApi.colorIndex) color = AvatarColors[this.userFromApi.colorIndex];
                    return initialsAvatar(firtsname + '+' + lastname, color);
                }
                return null;
            }
        },
        created() {
            memberService.getById(this.currentUser.id).then(user => this.userFromApi = user);
        }
    }
</script>

<style scoped>
    .c-icon {
        margin-right: 0.3rem;
    }
</style>