<template>
    <CContainer class="d-flex content-center min-vh-100">
        <CRow>
            <CCol>
                <CCard
                        class="p-6"
                        body-wrapper
                        style="min-width: 500px;"
                >
                    <CCardHeader>
                        <img src="../../assets/images/Logo_long_color.png" height="50">
                    </CCardHeader>
                    <CCardBody class="text-center">
                        <CLink class="btn btn-primary" to="/login">Se Connecter</CLink>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </CContainer>
</template>

<script>
    import { router } from '@/routes/router';
    import { authenticationService } from '@/services';

    export default {
        name: "Home",
        data () {
            return {
            };
        },
        created() {
            if (authenticationService.currentUserValue) {
                return router.push('/app/dashboard');
            }
        }
    };
</script>