import {i18n} from "../lang/lang";
import JobOffers from "../views/joboffers/JobOffers";
import JobOfferForm from "../views/joboffers/JobOfferForm";
import JobOfferApplications from "../views/joboffers/JobOfferApplications";
import {Roles} from "../config";

const roles_authorized = [Roles.Recruiter, Roles.Company_Administrator, Roles.Admin];

export default [
    {
        path: 'joboffers',
        name: i18n.t('joboffers.index'),
        component: JobOffers,
        meta: {
            authorize: roles_authorized,
            title: "JobInclusif.fr - " + i18n.t('joboffers.index'),
        }
    },
    {
        path: 'joboffers/create',
        name: i18n.t('joboffers.create'),
        component: JobOfferForm,
        meta: {
            authorize: roles_authorized,
            title: "JobInclusif.fr - " + i18n.t('joboffers.create'),
        }
    },
    {
        path: 'joboffers/:id/update',
        name: i18n.t('joboffers.edit'),
        component: JobOfferForm,
        meta: {
            authorize: [],
            title: "JobInclusif.fr - " + i18n.t('joboffers.edit'),
        },
        children: [
            {
                path: 'step_1',
                name: "Etape 1 : " + i18n.t('joboffers.steps.1'),
                component: JobOfferForm,
                meta: {
                    authorize: roles_authorized,
                    title: "JobInclusif.fr - " + i18n.t('joboffers.edit'),
                }
            },
            {
                path: 'step_2',
                name: "Etape 2 : " + i18n.t('joboffers.steps.2'),
                component: JobOfferForm,
                meta: {
                    authorize: roles_authorized,
                    title: "JobInclusif.fr - " + i18n.t('joboffers.edit'),
                }
            },
            {
                path: 'step_3',
                name: "Etape 3 : " + i18n.t('joboffers.steps.3'),
                component: JobOfferForm,
                meta: {
                    authorize: roles_authorized,
                    title: "JobInclusif.fr - " + i18n.t('joboffers.edit'),
                }
            },
        ]
    },
    {
        path: 'joboffers/company/:id',
        name: i18n.t('joboffers.edit'),
        component: JobOfferForm,
        meta: {
            authorize: roles_authorized,
            title: "JobInclusif.fr - " + i18n.t('joboffers.edit'),
        }
    },
    {
        path: 'joboffers/:id/applications',
        name: i18n.t('joboffers.applications.title'),
        component: JobOfferApplications,
        meta: {
            authorize: roles_authorized,
            title: "JobInclusif.fr - " + i18n.t('joboffers.consult'),
        }
    },
    {
        path: 'joboffers/:id',
        name: i18n.t('joboffers.consult'),
        component: JobOfferForm,
        meta: {
            authorize: [],
            title: "JobInclusif.fr - " + i18n.t('joboffers.consult'),
        },
    },
]