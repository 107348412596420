import {i18n} from "../../lang/lang";
import {Roles} from "../../config";

import AdminPage from "../../views/admin/AdminPage";

import members from "./members";
import companies from "./companies";

let routes = [];
routes = routes.concat(members);
routes = routes.concat(companies);

export default [
    {
        path: 'admin',
        name: i18n.t('routes.admin.index'),
        component: AdminPage,
        meta: {
            authorize: [Roles.Admin], //doit être connecté et avoir le role Admin
            title: "JobInclusif.fr - " + i18n.t('routes.dashboard')
        },
        children: routes
    }
]