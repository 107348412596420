import Vue from 'vue'
import VueI18n from 'vue-i18n'
import dateTimeFormats from "./dateTimeFormats";
import numberFormats from "./numberFormats";

import fr from './fr-FR/fr-FR';
// import us from './en-US/en-us';

Vue.use(VueI18n)

const messages = {
    "fr-FR": fr,
    // "en-US": us
}

let locale = navigator.language;
export const i18n = new VueI18n({
    fallbackLocale: 'fr-FR',
    locale: locale,
    dateTimeFormats,
    numberFormats,
    messages
});