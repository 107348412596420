//import config from 'config';
import {handleResponse, requestOptions} from '@/helpers';

export const helpService = {
    sendToSupport
};

function sendToSupport(subject, message, replyTo, getCopy) {
    return fetch(`${process.env.VUE_APP_API_URI}/help/contact`, requestOptions.post({
            subject: subject,
            message: message,
            replyTo: replyTo,
            getCopy: getCopy
        }))
        .then(handleResponse);
}