//import config from 'config';
import {handleResponse, requestOptions} from '@/helpers';

export const companiesService = {
    getAll,
    getById,
    getEtablissement,
    getBySiret,
    getByMetier,
    getBySource,
    create,
    update,
    remove
};

/**
 *
 * @returns {Promise<Response>}
 */
function getAll() {
    return fetch(`${process.env.VUE_APP_API_URI}/companies`, requestOptions.get())
        .then(handleResponse);
}

/**
 *
 * @param id
 * @returns {Promise<Response>}
 */
function getById(id) {
    return fetch(`${process.env.VUE_APP_API_URI}/companies/${id}`, requestOptions.get())
        .then(handleResponse);
}

/**
 *
 * @param siret
 * @returns {Promise<Response>}
 */
function getBySiret(siret) {
    return fetch(`${process.env.VUE_APP_API_URI}/companies/siret/${siret}`, requestOptions.get())
        .then(handleResponse);
}

/**
 *
 * @param metier
 * @returns {Promise<Response>}
 */
function getByMetier(metier) {
    return fetch(`${process.env.VUE_APP_API_URI}/companies/metier/${metier}`, requestOptions.get())
        .then(handleResponse);
}

/**
 *
 * @param id
 * @returns {Promise<Response>}
 */
function getEtablissement(id) {
    return fetch(`${process.env.VUE_APP_API_URI}/companies/${id}/etablissements`, requestOptions.get())
        .then(handleResponse);
}

/**
 *
 * @param company
 * @returns {Promise<Response>}
 */
function create(company){
    return fetch(`${process.env.VUE_APP_API_URI}/companies`, requestOptions.post({company:company}))
        .then(handleResponse);
}

/**
 *
 * @param company
 * @returns {Promise<Response>}
 */
function update(company){
    return fetch(`${process.env.VUE_APP_API_URI}/companies`, requestOptions.put({company:company}))
        .then(handleResponse);
}

/**
 *
 * @param id
 * @returns {Promise<Response>}
 */
function remove(id){
    return fetch(`${process.env.VUE_APP_API_URI}/companies`, requestOptions.delete({id_company:id}))
        .then(handleResponse);
}

/**
 *
 * @returns {Promise<Response>}
 */
function getBySource(source){
    return fetch(`${process.env.VUE_APP_API_URI}/companies/source/${source}`, requestOptions.get())
        .then(handleResponse);
}