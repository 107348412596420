//import config from 'config';
import {handleResponse, requestOptions} from '@/helpers';

export const structureService = {
    getAllCS,
    getActivitiesUNEA,
    getAllPartnerSectors
};

/**
 *
 * @returns {Promise<Response>}
 */
function getAllCS() {
    return fetch(`${process.env.VUE_APP_API_URI}/structure/cs`, requestOptions.get())
        .then(handleResponse);
}

/**
 *
 * @returns {Promise<Response>}
 */
function getActivitiesUNEA() {
    return fetch(`${process.env.VUE_APP_API_URI}/structure/unea/activities`, requestOptions.get())
        .then(handleResponse);
}

/**
 *
 * @returns {Promise<Response>}
 */
function getAllPartnerSectors() {
    return fetch(`${process.env.VUE_APP_API_URI}/structure/partner/sectors`, requestOptions.get())
        .then(handleResponse);
}