//import config from 'config';
import {handleResponse, requestOptions} from '@/helpers';

export const adaptationMeasuresService = {
    getAll
};

function getAll() {
    return fetch(`${process.env.VUE_APP_API_URI}/adaptationMeasures`, requestOptions.get())
        .then(handleResponse);
}