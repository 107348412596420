<template>
    <CCard class="h-100 border-secondary">
        <CCardHeader class="text-center">
            <CRow>
                <CCol>
                    <font-awesome-icon icon="building" size="3x"></font-awesome-icon>
                </CCol>
            </CRow>
            <CRow>
                <CCol>
                    <CButton :to="'/app/companies/' + company._id" class="h4 btn-link" target="_blank">{{company.name}}</CButton>
                </CCol>
            </CRow>
            <CRow>
                <CCol>
                    <small>{{company.address.zip}} {{company.address.city}} <i v-if="distance && distance > 0">({{Math.round(distance)}}Km)</i></small>
                </CCol>
            </CRow>
        </CCardHeader>
        <CCardBody>
            <CRow v-for="s in sectors" v-bind:key="s.name">
                <CCol>
                    <CBadge>
                        <i :class="'sector-icon ' + s.code"></i> {{s.name}}
                    </CBadge>
                </CCol>
            </CRow>
        </CCardBody>
    </CCard>
</template>

<script>
    export default {
        name: "resultCompany",
        props: [
            "company",
            "sectors",
            "distance"
         ],
        created() {
        }
    }
</script>

<style scoped>

</style>