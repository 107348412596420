<template>
    <div>
        <CRow>
            <CCol>
                <CCard>
                    <CCardHeader>
                        <CCardTitle v-if="this.$route.params.id">Modifier {{firstName || ""}} {{lastName || ""}}
                        </CCardTitle>
                        <CCardTitle v-else>Nouvel Utilisateur</CCardTitle>
                    </CCardHeader>
                    <CCardBody>
                        <CForm @submit.prevent="onSubmit()">
                            <CRow>
                                <CCol>
                                    <CInput
                                            v-model.trim="$v.lastName.$model"
                                            placeholder="Nom"
                                            label="Nom"
                                            invalidFeedback="Nom requis"
                                            :isValid="isLastNameValid"
                                    ></CInput>
                                </CCol>
                                <CCol>
                                    <CInput
                                            v-model.trim="$v.firstName.$model"
                                            placeholder="Prénom"
                                            label="Prénom"
                                            invalidFeedback="Prénom ou requis"
                                            :isValid="isFirstNameValid"
                                    ></CInput>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol>
                                    <CInput
                                            v-model.trim="$v.email.$model"
                                            type="email"
                                            placeholder="E-Mail"
                                            label="E-Mail"
                                            invalidFeedback="E-Mail invalide ou requis"
                                            :isValid="isEmailValid"
                                    ></CInput>
                                </CCol>
                                <CCol>
                                    <CInput
                                            type="password"
                                            v-model.trim="$v.password.$model"
                                            placeholder="Mot De Passe"
                                            label="Mot De Passe"
                                            invalidFeedback="Mot de passe requis, il doit faire plus de 8 charactères"
                                            :isValid="isPasswordValid"
                                    ></CInput>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol>
                                    <label>Roles :</label>
                                    <v-select
                                            :options="roles"
                                            v-model="selected_roles"
                                            multiple
                                            placeholder="Selectionnez un ou plusieurs role"
                                    ></v-select>
                                </CCol>
                            </CRow>
                            <CRow class="mt-3" :style="userFromApi && userFromApi.roles.includes(Roles.Admin) ? '' : 'display:none;'">
                                <CCol>
                                    <label>Entreprise :</label>
                                    <v-select
                                            :options="Companies"
                                            label="name"
                                            v-model="selected_company"
                                            placeholder="Selectionnez une entreprise"
                                    ></v-select>
                                </CCol>
                            </CRow>
                            <CRow class="mt-5">
                                <CCol>
                                    <CButton color="success" type="submit" v-if="this.$route.params.id" :disabled="loading">
                                        <font-awesome-icon icon="save"></font-awesome-icon>
                                        {{$t("buttons.save")}}
                                    </CButton>
                                    <CButton color="success" type="submit" :disabled="loading" v-else>
                                        <font-awesome-icon icon="plus"></font-awesome-icon>
                                        {{$t("buttons.create")}}
                                    </CButton>
                                </CCol>
                                <CCol class="text-right">
                                    <CButton color="danger" :to="'/app/' + baseRoute + 'members'">
                                        <font-awesome-icon icon="times"></font-awesome-icon>
                                        {{$t("buttons.cancel")}}
                                    </CButton>
                                </CCol>
                            </CRow>
                        </CForm>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import {Roles} from '@/config'
    import {memberService, authenticationService} from '@/services';
    import {router} from '@/routes/router';
    import {required, minLength, email} from "vuelidate/lib/validators";
    import {companiesService} from "@/services";

    export default {
        name: "MemberForm",
        data() {
            return {
                currentUser: authenticationService.currentUserValue,
                userFromApi: null,
                firstName: '',
                lastName: '',
                email: '',
                selected_roles: [],
                selected_company: [],
                password: '@0123456789JobInclusif', //mot de passe pré-complété
                Companies: [],
                Roles: Roles,
                loading: false,
                submitted: false,
                baseRoute: '',
            }
        },
        validations: {
            email: {required, type: email},
            password: {required, minLength: minLength(8)},
            firstName: {required},
            lastName: {required}
        },
        computed: {
            companies() {
                let companies = [];
                Object.keys(this.Companies).forEach(key => {
                    companies.push({
                        label: this.Companies[key].name,
                        value: this.Companies[key]._id
                    });
                });
                return companies;
            },
            roles() {
                let roles = [];
                if(this.userFromApi && this.userFromApi.roles.includes(Roles.Admin)) {
                    Object.keys(this.Roles).forEach(key => {
                        roles.push({
                            label: this.$t(`roles.${Roles[key]}`),
                            value: Roles[key]
                        });
                    });
                } else {
                    roles.push({
                        label: this.$t(`roles.${Roles.Company_Administrator}`),
                        value: Roles.Company_Administrator
                    });
                    roles.push({
                        label: this.$t(`roles.${Roles.Recruiter}`),
                        value: Roles.Recruiter
                    });
                    // roles = [Roles.Company_Administrator, Roles.Recruiter];
                }
                return roles;
            },
            isEmailValid() {
                if (!this.submitted) return null;
                else if (this.submitted && this.$v.email.$error) return false;
                return true;
            },
            isPasswordValid() {
                if (!this.submitted) return null;
                else if (this.submitted && this.$v.password.$error) return false;
                return true;
            },
            isFirstNameValid() {
                if (!this.submitted) return null;
                else if (this.submitted && this.$v.firstName.$error) return false;
                return true;
            },
            isLastNameValid() {
                if (!this.submitted) return null;
                else if (this.submitted && this.$v.lastName.$error) return false;
                return true;
            },
        },
        methods: {
            onSubmit() {
                this.submitted = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.loading = true;
                const user_id = this.$route.params.id || null;
                const user_roles = this.selected_roles.map(r => r.value);
                if (user_id) {
                    let selected_company_id = this.userFromApi.id_company;
                    if(this.selected_company && this.selected_company._id) selected_company_id = this.selected_company._id;
                    memberService.update({
                        _id: user_id,
                        firstName: this.firstName,
                        lastName: this.lastName,
                        email: this.email,
                        password: this.password,
                        roles: user_roles,
                        id_company : selected_company_id
                    })
                        .then(() => {
                            router.push('/app/' + this.baseRoute + 'members');
                        }, error => {
                            this.error = error;
                            this.loading = false;
                        });
                } else {
                    let selected_company_id = this.userFromApi.id_company;
                    if(this.selected_company && this.selected_company._id) selected_company_id = this.selected_company._id;
                    memberService.create({
                        firstName: this.firstName,
                        lastName: this.lastName,
                        email: this.email,
                        password: this.password,
                        roles: user_roles,
                        id_company : selected_company_id
                    })
                        .then(() => {
                            router.push('/app/' + this.baseRoute + 'members');
                        }, error => {
                            this.error = error;
                            this.loading = false;
                        });
                }

            }
        },
        mounted() {
            memberService.getById(this.currentUser.id).then(user => {
                this.userFromApi = user;
                if(user.roles.includes(Roles.Admin)) this.baseRoute = 'admin/';
            });
            if (this.$route.params.id) {
                memberService.getById(this.$route.params.id).then(u => {
                    this.user = u;
                    this.firstName = this.user.firstName;
                    this.lastName = this.user.lastName;
                    this.email = this.user.email;
                    this.user.roles.forEach(r => {
                        this.selected_roles.push({
                            label: this.$t(`roles.${r}`),
                            value: r
                        });
                    });
                    companiesService.getBySource("jobinclusif").then(response => {
                        this.Companies = response;
                        this.selected_company = this.Companies.find(company => company._id === this.user.id_company);
                    });
                });
            }
            else{
                companiesService.getBySource("jobinclusif").then(response => {
                    this.Companies = response;
                });
            }
        }
    }
</script>

<style scoped>

</style>