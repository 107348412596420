<template>
    <div>
        <CCard>
            <CCardHeader>
                <CCardTitle>
                    {{$t("routes.admin.companies.index")}}
                    <CButton color="success" to="/app/admin/companies/create" class="float-right">
                        <font-awesome-icon icon="plus"></font-awesome-icon>
                        {{$t('routes.admin.companies.create')}}
                    </CButton>
                </CCardTitle>
            </CCardHeader>
            <CCardBody>
                <CDataTable :items="companies"
                            :fields="Fields"
                            :striped="true"
                            :border="true"
                            :small="true"
                            :sorter="{ resetable: true }"
                            :columnFilter="true"
                            :columnFilterValue="{ username: '' }"
                            :tableFilter="{ placeholder: $t('datatable.search_placeholder'), label: $t('datatable.search') }"
                            :loading="false"
                            :pagination="true"
                            :itemsPerPage="25"
                            :itemsPerPageSelect="{ label: $t('datatable.elements_count'), values:[1,10,25,50,100,250,500,1000] }"
                            :noItemsView="{ noResults: $t('datatable.noResults'), noItems: $t('datatable.noItems') }"
                >
                    <td slot="description" slot-scope="{item}">
                        <CBadge v-c-tooltip="item.description" v-if="item.description">
                            {{item.description.substring(0, 25)}}...
                        </CBadge>
                    </td>
                    <td slot="sectors" slot-scope="{item}">
                        <template v-if="item.source === 'jobinclusif'">
                            <div v-for="sector in item.partner_sectors" v-bind:key="sector._id">
                                <CBadge><i class="fa-lg"></i>
                                    <font-awesome-icon :icon="sector.icon"></font-awesome-icon>
                                    {{sector.name}}
                                </CBadge>
                            </div>
                        </template>
                        <template v-else>
                            <div v-for="sector in item.sectors" v-bind:key="sector._id">
                                <CBadge><i :class="'fa-lg sector-icon ' + sector.code"></i>
                                    {{sector.code}}/{{sector.name}}
                                </CBadge>
                                <div class="ml-4"
                                     v-for="section in item.sections.filter(s => s.code_secteur === sector.code)"
                                     v-bind:key="section._id">
                                    <CBadge>- {{section.code}}/{{section.name}}</CBadge>
                                    <div class="ml-4"
                                         v-for=" act in item.acts.filter(a => a.code_section === section.code)"
                                         v-bind:key="act._id">
                                        <CBadge>{{act.code}}/{{act.name}}</CBadge>
                                    </div>
                                </div>
                                <br>
                            </div>
                        </template>
                    </td>


                    <td slot="actions" slot-scope="{item}">
                        <CButtonGroup>
                            <CButton :to="'/app/companies/' + item._id" color="success">
                                <font-awesome-icon icon="eye"></font-awesome-icon>
                                {{$t('buttons.show')}}
                            </CButton>
                            <CButton :to="'/app/admin/companies/update/' + item._id" color="warning" size="sm"
                                     v-if="item.source === 'jobinclusif'">
                                <font-awesome-icon icon="edit"></font-awesome-icon>
                                {{$t('buttons.update')}}
                            </CButton>
                            <button @click="delete(item._id)" class="btn btn-sm btn-danger"
                                    v-if="item.source === 'jobinclusif'">
                                <font-awesome-icon icon="trash"></font-awesome-icon>
                                {{$t('buttons.trash')}}
                            </button>
                        </CButtonGroup>
                    </td>
                </CDataTable>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
    import {companiesService} from '@/services';
    import {router} from '@/routes/router';

    export default {
        name: "Companies",
        data() {
            return {
                companies: [],
                uri: "",
                Activities: []
            };
        },
        methods: {
            test(id) {
                companiesService.getById(id);
            },
            delete(id) {
                companiesService.remove(id).then(c => {
                    console.log(c);
                    router.push('/app/admin/companies');
                })
            }
        },
        computed: {
            Fields() {
                return [
                    {
                        key: 'name',
                        label: "Raison Sociale",
                        filter: true
                    },
                    {
                        key: 'description',
                        label: "Description",
                        filter: true
                    },
                    {
                        key: 'sectors',
                        label: "Secteurs",
                        filter: true
                    },
                    {
                        key: 'source',
                        label: "Source",
                        filter: true
                    },
                    {
                        key: 'actions',
                        label: '',
                        filter: false,
                        sorter: false
                    },

                ];
            }
        },
        created() {
            companiesService.getAll().then(c => this.companies = c);
        }
    };
</script>

<style scoped>

</style>