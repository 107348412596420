<template>
    <CContainer v-if="resume && userFromApi && company">
        <CCard>
            <!-- Trois boutons en haut a droite-->
            <CRow>
                <CCol class="text-right" v-if="authorised([Roles.Company_Administrator, Roles.Recruiter, Roles.Admin])">
                    <CButtonGroup>
                        <template v-if="contactMail">
                            <a :href="`mailto:${contactMail}`">
                                <CButton>
                                    <font-awesome-icon icon="address-book" size="2x"/>
                                </CButton>
                            </a>
                        </template>
                        <!--                        <CButton>-->
                        <!--                            <font-awesome-icon icon="file-pdf" size="2x"/>-->
                        <!--                        </CButton>-->
                        <CButton @click="favoriteClick(resume.efa_resume_id)">
                            <font-awesome-icon icon="heart" class="pink-heart" size="2x"
                                               v-if="userFromApi.favorites.resumes.find(r => r === resume.efa_resume_id)"/>
                            <font-awesome-icon :icon="['far','heart']" size="2x" v-else/>
                        </CButton>
                    </CButtonGroup>
                </CCol>
            </CRow>
            <!-- / Trois boutons en haut a droite-->

            <!-- Les infos générales du cv-->
            <div class="m-3">
                <CRow>
                    <CCol col="3" class="text-center">
                        <font-awesome-icon icon="user" size="6x"/>
                    </CCol>
                    <CCol class="text-center">
                        <CRow>
                            <CCol v-if="resume.dt_available">
                                <header-callout color="info" :title="$t('cv.content.available')"
                                                :value="format_date(resume.dt_available)"></header-callout>
                            </CCol>
                            <CCol v-if="resume.position && resume.position.city">
                                <header-callout color="success" :title="$t('cv.content.address')"
                                                :value="resume.position.city"></header-callout>
                            </CCol>
                            <CCol v-if="resume.position && resume.position.context">
                                <header-callout color="success" :title="$t('cv.content.mobility')"
                                                :value="resume.position.context"></header-callout>
                            </CCol>
                        </CRow>
                    </CCol>
                </CRow>
            </div>
            <!-- / Les infos générales du cv-->

            <CCardBody>
                <!--Section des postes recherchés-->
                <div class="m-3">
                    <CRow>
                        <CCol>
                            <h3>{{$t('cv.content.search')}}</h3>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol>
                            <CBadge v-for="singleSearch in resume.jobdescriptions_target" v-bind:key="singleSearch.ID"
                                    color="success" class="fs-15 m-1">{{singleSearch.LIBELLE}}
                            </CBadge>
                        </CCol>
                    </CRow>
                </div>
                <!--/ Section des postes recherchés-->

                <!--Section des expériences pro-->
                <div class="m-3">
                    <CRow>
                        <CCol>
                            <h3>{{$t('cv.content.experience')}}</h3>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol>
                            <CCardGroup class="d-block align-items-center col-12">
                                <!--Partie des expériences pro (jobsdescription)-->
                                <template v-for="(experience, index) in resume.jobdescriptions">
                                    <CCard :key="experience.ID" v-if="experience.show">
                                        <CCardHeader @click="showCollapse('exp-'+index)" class="btn text-left">
                                            <CRow>
                                                <CCol col="9">
                                                    <h5>
                                                        <b>{{experience.LIBELLE}}</b>
                                                    </h5>
                                                    <span v-if="experience.DT_DEBUT">Depuis le {{format_date(experience.DT_DEBUT)}}</span>
                                                </CCol>
                                                <CCol class="text-right">
                                                    <b>{{company.name || efa_societe.libelle}}</b>
                                                    <br>
                                                    <small class="text-muted">
                                                        <i>
                                                            {{$t("cv.content.approved.true")}}
                                                            <font-awesome-icon icon="check" size="lg"
                                                                               class="text-success"></font-awesome-icon>
                                                        </i>
                                                    </small>
                                                </CCol>
                                            </CRow>
                                        </CCardHeader>
                                        <CCollapse :show="collapseShow === 'exp-'+index">
                                            <CCardBody>
                                                <CRow>
                                                    <template v-for="skill in experience.skills">
                                                        <CCol col="6" class="min-width-500"
                                                              v-if="resume.skills_with_level && resume.skills_with_level.length>0 && resume.skills_with_level.find(e=>e.skill_id === skill.ID) && resume.skills_with_level.find(e=>e.skill_id === skill.ID).manager_rated_level >= 2 "
                                                              v-bind:key="skill.ID">
                                                            <BadgeExperience
                                                                    :data="{name:skill.LIBELLE, description:skill.DESCRIPTIF, rating:resume.skills_with_level.find(e=>e.skill_id === skill.ID).manager_rated_level, ratename:resume.skills_with_level.find(e=>e.skill_id === skill.ID).rated_level_name}"></BadgeExperience>
                                                        </CCol>
                                                    </template>
                                                </CRow>
                                            </CCardBody>
                                        </CCollapse>
                                    </CCard>
                                </template>
                                <!--/ Partie des expériences pro (jobsdescription)-->

                                <!--Partie des expériences pro (jobsdescription_history)-->
                                <template v-for="(experience, index) in resume.jobdescriptions_history">
                                    <CCard v-bind:key="experience.ID" v-if="experience.show">
                                        <CCardHeader @click="showCollapse('exphistory-'+index)" class="btn text-left">
                                            <CRow>
                                                <CCol col="9">
                                                    <h5>
                                                        <b>{{experience.LIBELLE}}</b>
                                                    </h5>
                                                    <span v-if="experience.DT_DEBUT && experience.DT_FIN">Du {{format_date(experience.DT_DEBUT)}} au {{format_date(experience.DT_FIN)}}</span>
                                                    <span v-else-if="experience.DT_DEBUT">Depuis le {{format_date(experience.DT_DEBUT)}}</span>
                                                    <span v-else-if="experience.DT_FIN">Jusqu'au {{format_date(experience.DT_DEBUT)}}</span>
                                                </CCol>
                                                <CCol class="text-right">
                                                    <b>{{company.name || efa_societe.libelle}}</b>
                                                    <br>
                                                    <small class="text-muted">
                                                        <i>
                                                            {{$t("cv.content.approved.true")}}
                                                            <font-awesome-icon icon="check" size="lg"
                                                                               class="text-success"></font-awesome-icon>
                                                        </i>
                                                    </small>
                                                </CCol>
                                            </CRow>
                                        </CCardHeader>
                                        <CCollapse :show="collapseShow === 'exphistory-'+index">
                                            <CCardBody>
                                                <CRow>
                                                    <template v-for="skill in experience.skills">
                                                        <CCol col="6"
                                                              v-if="resume.skills_with_level && resume.skills_with_level.length>0 && resume.skills_with_level.find(e=>e.skill_id === skill.ID) && resume.skills_with_level.find(e=>e.skill_id === skill.ID).manager_rated_level >= 2 "
                                                              v-bind:key="skill.ID" class="w-50 min-width-500">
                                                            <BadgeExperience
                                                                    :data="{name:skill.LIBELLE,
                                                                    description:skill.DESCRIPTIF,
                                                                    rating:resume.skills_with_level.find(e=>e.skill_id === skill.ID).manager_rated_level,
                                                                    ratename:resume.skills_with_level.find(e=>e.skill_id === skill.ID).rated_level_name}">
                                                            </BadgeExperience>
                                                        </CCol>
                                                    </template>
                                                </CRow>
                                            </CCardBody>
                                        </CCollapse>
                                    </CCard>
                                </template>
                                <!--/ Partie des expériences pro (jobsdescription_history)-->

                                <!--Partie des expériences pro (jobsdescription_custom)-->
                                <template v-for="(experience, index) in resume.jobdescriptions_custom">
                                    <CCard v-bind:key="experience.ID" v-if="experience.show">
                                        <CCardHeader @click="showCollapse('expcustom-'+index)" class="btn text-left">
                                            <CRow>
                                                <CCol col="9"><h5><b>{{experience.FONCTION}}</b></h5></CCol>
                                                <CCol class="text-right"><b>{{experience.EMPLOYEUR}}</b></CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol col="8" v-if="experience.DTDEBUT && experience.DTFIN">
                                                    <span v-if="experience.DTDEBUT && experience.DTFIN">Du {{format_date(experience.DTDEBUT)}} au {{format_date(experience.DTFIN)}}</span>
                                                    <span v-else-if="experience.DTDEBUT">Depuis le {{format_date(experience.DTDEBUT)}}</span>
                                                    <span v-else-if="experience.DTFIN">Jusqu'au {{format_date(experience.DTDEBUT)}}</span>
                                                </CCol>
                                                <CCol class="text-right text-muted">
                                                    <small>
                                                        <i>
                                                            {{$t("cv.content.approved.false")}}
                                                        </i>
                                                    </small>
                                                </CCol>
                                            </CRow>
                                        </CCardHeader>
                                        <CCollapse :show="collapseShow === 'expcustom-'+index">
                                            <CCardBody>
                                                <CRow>
                                                    <template
                                                            v-for="skill in resume.skills_custom.filter(c => c.efa_experience_id === experience.ID)">
                                                        <CCol col="6" v-if="skill.level >= 2" v-bind:key="skill.efa_id"
                                                              class="w-50 min-width-500">
                                                            <BadgeExperience
                                                                    :data="{name:skill.name, description:skill.context, rating:skill.level }"></BadgeExperience>
                                                        </CCol>
                                                    </template>
                                                </CRow>
                                            </CCardBody>
                                        </CCollapse>
                                    </CCard>
                                </template>
                                <!--/ Partie des expériences pro (jobsdescription_custom)-->
                            </CCardGroup>
                        </CCol>
                    </CRow>
                </div>
                <!--/ Section des expériences pro-->

                <!-- Section des compétences extra pro -->
                <div v-if="resume.skills_custom.filter(c => c.efa_experience_id === null && c.level > 2).length > 0"
                     class="m-3">
                    <CRow>
                        <CCol>
                            <h3>{{$t('cv.content.skills')}}</h3>
                        </CCol>
                    </CRow>
                    <CCard>
                        <CCardBody>
                            <CRow>
                                <template
                                        v-for="skill in resume.skills_custom.filter(c => c.efa_experience_id === null)">
                                    <CCol v-bind:key="skill._id" v-if="skill.show">
                                        <div class="mx-auto">
                                            <BadgeExperience
                                                    :data="{name:skill.name, description:skill.context, rating:skill.level }"></BadgeExperience>
                                        </div>
                                    </CCol>
                                </template>
                            </CRow>
                        </CCardBody>
                    </CCard>
                </div>
                <!-- /Section des compétences extra pro -->

                <!--Section des formations-->
                <div v-if="resume.trainings.length > 0" class="m-3">
                    <CRow>
                        <CCol>
                            <h3>{{$t('cv.content.training')}}</h3>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol>
                            <CCardGroup class="d-block align-items-center col-12">
                                <template v-for="(training, index) in resume.trainings">
                                    <CCard v-bind:key="training.ID" v-if="training.show">
                                        <CCardHeader @click="showCollapse('tra-'+index)" class="btn text-left">
                                            <CRow>
                                                <CCol col="9"><h5><b>{{training.LIBELLE}}</b></h5></CCol>
                                                <CCol col="3" class="text-right"><b>{{company.name ||
                                                    efa_societe.libelle}}</b></CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol col="8">{{format_date(training.DTFINFORMATION)}}</CCol>
                                            </CRow>
                                        </CCardHeader>
                                        <CCollapse :show="collapseShow === 'tra-'+index">
                                            <CCardBody>
                                                <CRow>
                                                    <CCol v-if="training.DIPLOME" col="3" class="text-right">
                                                        <font-awesome-icon icon="graduation-cap" size="lg"
                                                                           v-c-tooltip="'Diplôme'" color="primary"/>
                                                        :
                                                        <span v-if="training.DIPLOME">{{training.DIPLOME}}</span>
                                                        <span v-else>Aucune diplône n'est renseigné</span>
                                                    </CCol>
                                                    <CCol>
                                                        <font-awesome-icon icon="info-circle" size="lg"/>
                                                        :
                                                        <span v-if="training.DESCRIPTION">{{training.DESCRIPTION}}</span>
                                                        <span v-else>Aucune compétences n'est disponible</span>
                                                    </CCol>
                                                </CRow>
                                            </CCardBody>
                                        </CCollapse>
                                    </CCard>
                                </template>
                                <template v-for="(training, index) in resume.trainings_custom">
                                    <CCard v-bind:key="training.ID" v-if="training.show">
                                        <CCardHeader @click="showCollapse('tra_custom-'+index)" class="btn text-left">
                                            <CRow>
                                                <CCol col="9"><h5><b>{{training.LIBELLE}}</b></h5></CCol>
                                            </CRow>
                                            <CRow v-if="training.DTDEBUT && training.DTFIN">
                                                <CCol col="8">Du {{format_date(training.DTDEBUT)}} au
                                                    {{format_date(training.DTFIN)}}
                                                </CCol>
                                            </CRow>
                                        </CCardHeader>
                                        <CCollapse :show="collapseShow === 'tra_custom-'+index">
                                            <CCardBody>
                                                <CRow>
                                                    <CCol v-if="training.DIPLOME" col="3" class="text-right">
                                                        <font-awesome-icon icon="graduation-cap" size="lg"
                                                                           v-c-tooltip="'Diplôme'" color="primary"/>
                                                        :
                                                        <span v-if="training.DIPLOME">{{training.DIPLOME}}</span>
                                                        <span v-else>Aucune diplône n'est renseigné</span>
                                                    </CCol>
                                                    <CCol>
                                                        <font-awesome-icon icon="info-circle" size="lg"/>
                                                        :
                                                        <span v-if="training.COMPETENCESACQUISES">{{training.COMPETENCESACQUISES}}</span>
                                                        <span v-else>Aucune compétences n'est disponible</span>
                                                    </CCol>
                                                </CRow>
                                            </CCardBody>
                                        </CCollapse>
                                    </CCard>
                                </template>
                            </CCardGroup>
                        </CCol>
                    </CRow>
                </div>
                <!--/ Section des formations-->

                <!--Section des réglementations-->
                <div v-if="resume.regulations.length && resume.regulations.length > 0" class="m-3">
                    <CRow>
                        <CCol>
                            <h3>{{$t('cv.content.regulation')}}</h3>
                        </CCol>
                    </CRow>
                    <CCard>
                        <CCardBody>
                            <CRow>
                                <template v-for="reglementation in resume.regulations">
                                    <CCol col="6" v-bind:key="reglementation.efa_regulation_id"
                                          v-if="reglementation.show">
                                        <CRow>
                                            <CCol class="text-center">
                                                <CBadge color="primary" class="fs-15 w-100 max-width-270 mt-2">
                                                    <span v-if="reglementation.libelle.length <= 30">{{reglementation.libelle}}</span>
                                                    <span v-else v-c-tooltip="reglementation.libelle" color="primary">{{textLengthLimit(reglementation.libelle, 30)}}</span>
                                                </CBadge>
                                            </CCol>
                                        </CRow>
                                        <CRow v-if="reglementation.enddate && reglementation.startdate">
                                            <CCol class="text-center">
                                                Du {{format_date(reglementation.startdate)}} au
                                                {{format_date(reglementation.enddate)}}
                                            </CCol>
                                        </CRow>
                                    </CCol>
                                </template>
                            </CRow>
                        </CCardBody>
                    </CCard>
                </div>
                <!--/ Section des réglementations-->

                <!--Section des permis -->
                <div v-if="resume.driving_licenses.length > 0" class="m-3">
                    <CRow>
                        <CCol>
                            <h3>{{$t('cv.content.permis')}}</h3>
                        </CCol>
                    </CRow>
                    <CCard>
                        <CCardBody class="d-flex flex-wrap">
                            <div class="d-block align-items-center w-50 min-width-500"
                                 v-for="singlePermis in resume.driving_licenses.filter(dl => dl.show)"
                                 v-bind:key="singlePermis.id_permis">
                                <CRow>
                                    <CCol class="text-center">
                                        <CBadge color="primary" class="fs-15 w-100 max-width-270 mx-auto">
                                            {{singlePermis.name}}
                                        </CBadge>
                                    </CCol>
                                </CRow>
                            </div>
                        </CCardBody>
                    </CCard>
                </div>
                <!--/ Section des permis-->

                <!--Section des langues-->
                <div v-if="resume.languages.length > 0" class="m-3">
                    <CRow>
                        <CCol>
                            <h3>{{$t('cv.content.language.index')}}</h3>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol>
                            <div class="d-block align-items-center w-90 mx-auto">
                                <CCard class="text-center">
                                    <CRow class="lang-head">
                                        <CCol><b>{{$t("cv.content.language.index")}}</b></CCol>
                                        <CCol><b>{{$t("cv.content.language.read")}}</b></CCol>
                                        <CCol><b>{{$t("cv.content.language.write")}}</b></CCol>
                                        <CCol><b>{{$t("cv.content.language.listen")}}</b></CCol>
                                        <CCol><b>{{$t("cv.content.language.speak")}}</b></CCol>
                                        <CCol><b>{{$t("cv.content.language.converse")}}</b></CCol>
                                    </CRow>
                                    <CRow class="fs-15 p-2" v-for="language in resume.languages.filter(l => l.show)"
                                          v-bind:key="language._id">
                                        <CCol>{{language.name}}</CCol>
                                        <CCol>{{language.lire}}</CCol>
                                        <CCol>{{language.ecrire}}</CCol>
                                        <CCol>{{language.ecouter}}</CCol>
                                        <CCol>{{language.s_exprimer}}</CCol>
                                        <CCol>{{language.converser}}</CCol>
                                    </CRow>
                                </CCard>
                            </div>
                        </CCol>
                    </CRow>
                </div>
                <!--/ Section des langues-->
            </CCardBody>
        </CCard>
    </CContainer>
</template>

<script>
    import BadgeExperience from "./partials/BadgeExperience";
    import HeaderCallout from "./partials/HeaderCallout";
    import {formatDate} from '@/helpers';
    import {
        resumesService,
        authenticationService,
        efaSocieteService,
        companiesService,
        memberService
    } from '@/services';
    import {
        authorised_in_view,
        textLengthLimit
    } from "@/helpers";
    import {Roles} from '@/config';

    export default {
        components: {BadgeExperience, HeaderCallout},
        data() {
            return {
                Roles: Roles,
                resume: null,
                inFavorite: false,
                collapseShow: '',
                company: null,
                efa_societe: null,
                userFromApi: null,
                contactMail : null
            }
        },
        methods: {
            authorised(authorised_roles = []) {
                if (this.userFromApi) return authorised_in_view(authorised_roles, this.userFromApi);
            },

            showCollapse: function (id) {
                if (id === this.collapseShow) {
                    this.collapseShow = '';
                } else {
                    this.collapseShow = id;
                }
            },
            format_date(value) {
                return formatDate(value)
            },

            getContactMail(resume){
                if(resume && resume.efa_cip_id){
                    memberService.getByEfaId(resume.efa_cip_id).then(cip => {
                        if(cip.email) {
                            this.contactMail = cip.email;
                        } else {
                            this.efa_societe && this.efa_societe.contact.mail ? this.contactMail =  this.efa_societe.contact.mail : null;
                        }
                    })
                }
            },

            favoriteClick: function (id) {
                if (this.userFromApi.favorites.resumes.find(r => r === this.resume.efa_resume_id)) {
                    this.userFromApi.favorites.resumes.splice(this.userFromApi.favorites.resumes.indexOf(id), 1);
                } else {
                    this.userFromApi.favorites.resumes.push(id);
                }
                memberService.toggleFavorite(this.userFromApi).catch(err => console.log(err));
            },
            textLengthLimit(text, length) {
                return textLengthLimit(text, length);
            }
        },
        mounted() {
            const resume_id = this.$route.params.id || this.$parent.$parent.resume._id;
            resumesService.getById(resume_id).then(resume => {
                this.resume = resume;
                efaSocieteService.getByEfaId(resume.efa_id_societe).then(s => {
                    this.efa_societe = s;
                    if (s.siret && s.siret.replace(/ /g, '').replace(/\./g, '').length === 14) {
                        companiesService.getBySiret(s.siret).then(c => {
                            this.company = c;
                        });
                    }
                });
                this.getContactMail(resume);
            });

            memberService.getById(authenticationService.currentUserValue._id).then(user => this.userFromApi = user);
        }
    }
</script>

<style scoped>

    .level-approved {
        text-align: right;
        font-size: 12px;
        font-style: oblique;
        color: #8a93a2;
    }

    .pink-heart {
        color: rgb(224, 36, 94);
    }

    .min-width-500 {
        max-width: 500px;
        min-width: 400px;
    }

    .fs-15 {
        font-size: 15px;
    }

    .lang-head {
        font-size: 20px;
        font-style: oblique;
        padding: 10px
    }

    .max-width-270 {
        max-width: 270px;
    }
</style>