//import config from 'config';
import {handleResponse, requestOptions} from '@/helpers';

export const joboffersService = {
    getAll,
    getById,
    getByCompanyId,
    create,
    update,
    remove,
    restore,
    publish,
    close,
};

const base_url = "joboffers"

/**
 *
 * @returns {Promise<Response>}
 */
function getAll() {
    return fetch(`${process.env.VUE_APP_API_URI}/${base_url}`, requestOptions.get())
        .then(handleResponse);
}

/**
 *
 * @param id
 * @returns {Promise<Response>}
 */
function getById(id) {
    return fetch(`${process.env.VUE_APP_API_URI}/${base_url}/${id}`, requestOptions.get())
        .then(handleResponse);
}

/**
 *
 * @param id
 * @returns {Promise<Response>}
 */
function getByCompanyId(id) {
    return fetch(`${process.env.VUE_APP_API_URI}/${base_url}/company/${id}`, requestOptions.get())
        .then(handleResponse);
}

/**
 *
 * @param joboffer
 * @returns {Promise<Response>}
 */
function create(joboffer) {
    return fetch(`${process.env.VUE_APP_API_URI}/${base_url}/`, requestOptions.post({joboffer: joboffer}))
        .then(handleResponse);
}

/**
 *
 * @param joboffer
 * @returns {Promise<Response>}
 */
function update(joboffer) {
    return fetch(`${process.env.VUE_APP_API_URI}/${base_url}/`, requestOptions.put({joboffer: joboffer}))
        .then(handleResponse);
}

/**
 *
 * @param id
 * @returns {Promise<Response>}
 */
function remove(id) {
    return fetch(`${process.env.VUE_APP_API_URI}/${base_url}/${id}`, requestOptions.delete())
        .then(handleResponse);
}

/**
 *
 * @param id
 * @returns {Promise<Response>}
 */
function restore(id) {
    return fetch(`${process.env.VUE_APP_API_URI}/${base_url}/restore`, requestOptions.put({id: id}))
        .then(handleResponse);
}
/**
 *
 * @param id
 * @returns {Promise<Response>}
 */
function publish(id) {
    return fetch(`${process.env.VUE_APP_API_URI}/${base_url}/publish`, requestOptions.put({id: id}))
        .then(handleResponse);
}

/**
 *
 * @param id
 * @returns {Promise<Response>}
 */
function close(id) {
    return fetch(`${process.env.VUE_APP_API_URI}/${base_url}/close`, requestOptions.put({id: id}))
        .then(handleResponse);
}

