<template>
    <CForm>
        <CRow>
            <CCol>
                <h4>Disponibilité et Contact</h4>
            </CCol>
        </CRow>
        <CRow>
            <CCol>
                <CInput
                        type="date"
                        v-model.trim="$v.$parent.resume.dt_available.$model"
                        label="Date de disponibilité"
                        invalidFeedback="Date requise"
                        :isValid="isFieldValid($v.$parent.resume.dt_available)"
                ></CInput>
            </CCol>
            <CCol>
                <CInput
                        type="email"
                        v-model.trim="$v.$parent.resume.user.email.$model"
                        label="Email de validation"
                        placeholder="Saisissez un email valide"
                        invalidFeedback="E-Mail invalide ou requis"
                        :isValid="isFieldValid($v.$parent.resume.user.email)"
                ></CInput>
            </CCol>
        </CRow>
        <CRow class="mt-5">
            <CCol>
                <h4>Adresse et Mobilité</h4>
            </CCol>
        </CRow>
        <CRow>
            <CCol>
                <h6>Ma ville de résidence</h6>
            </CCol>
        </CRow>
        <CRow>
            <CCol col="2">
                <CInput
                        v-model="$v.$parent.resume.position.zip.$model"
                        :label="$t('searches.zip')"
                        invalidFeedback="Code Postal Requis"
                        :isValid="isFieldValid($v.$parent.resume.position.zip)"
                        @keyup="zipChanged()"
                        :disabled="search_running"
                ></CInput>
            </CCol>
            <CCol>
                <label for="search_city">
                    <i class="fas fa-spinner fa-spin" v-if="search_running"></i>
                    {{$t('searches.city')}}
                </label>
                <v-select id="search_city" label="city_name" :options="results_cities"  v-model="search_coords" @input="cityChanged()" placeholder="Sélectionnez une ville"></v-select>
                <span class="text-danger" v-if="submitted && (!isFieldValid($v.$parent.resume.position.city) || !isFieldValid($v.$parent.resume.position.lat) || !isFieldValid($v.$parent.resume.position.lng))">Adresse Invalide</span>
            </CCol>
        </CRow>
        <CRow>
            <CCol>
                <h6>Ma mobilité</h6>
            </CCol>
        </CRow>
        <CRow>
            <CCol>
                <CInput
                        v-model="$parent.resume.position.context"
                        :disabled="search_running"
                ></CInput>
            </CCol>
        </CRow>
        <CRow class="mt-4 mx-3 mb-4">
            <CCol>
                <CButton color="danger" @click="cancelButton()">
                    <font-awesome-icon icon="times"></font-awesome-icon>
                    {{$t('buttons.cancel')}}
                </CButton>
            </CCol>
            <CCol class="text-right">
                <CButton color="success" @click="save()">
                    <font-awesome-icon icon="save"></font-awesome-icon>
                    {{$t('buttons.validate')}}
                </CButton>
            </CCol>
        </CRow>
    </CForm>
</template>

<script>
    import {required, minLength, email} from "vuelidate/lib/validators";
    import {geoService} from "@/services";
    import {removeAccent} from "@/helpers";

    export default {
        name: "ResumeFormStep3",
        data() {
            return {
                submitted: false,
                search_running: false,
                results_cities: [],
                search_coords: {
                    city_name: "",
                    context: "",
                    lat: null,
                    lng: null
                }
            };
        },
        methods: {
            cancelButton(){
                this.$parent.load();
                this.init();
            },
            zipChanged: async function() {
                this.search_coords = {
                    city_name: "",
                    context: "",
                    lat: null,
                    lng: null
                };
                if(this.$parent.resume.position.zip.length === 5){
                    this.search_running = true;
                    geoService.searchZipCode(this.$parent.resume.position.zip)
                        .then(cities => {
                            this.results_cities = cities;
                            this.search_running = false;
                        })
                        .catch(err => {
                            if(err) console.log(err);
                            this.search_running = false;
                        });
                }
            },
            cityChanged() {
                this.$parent.resume.position = {
                    city: this.search_coords.city_name,
                    context: this.search_coords.context,
                    zip: this.$parent.resume.position.zip,
                    lat: this.search_coords.lat,
                    lng: this.search_coords.lng
                }
            },
            isFieldValid(field) {
                if (!this.submitted) return null;
                else if (field.$error) return false;
                return true;
            },
            save() {
                this.submitted = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.$parent.save();
            },
            init() {
                this.search_coords = {
                    city_name: this.$parent.resume.position.city,
                    context: "",
                    lat: this.$parent.resume.position.lat,
                    lng: this.$parent.resume.position.lng
                };
                geoService.searchZipCode(this.$parent.resume.position.zip)
                    .then(cities => {
                        this.results_cities = cities;
                        for(let i=0; i < cities.length; i++) {
                            if(removeAccent(cities[i].city_name).includes(removeAccent(this.search_coords.city_name))) {
                                this.search_coords = cities[i];
                                this.cityChanged();
                                break;
                            }
                        }
                        this.search_running = false;
                    })
                    .catch(err => {
                        if(err) console.log(err);
                        this.search_running = false;
                    });
            }
        },
        validations: {
            $parent: {
                resume: {
                    user: {
                        email: {required, type: email},
                    },
                    dt_available: {required},
                    position: {
                        zip: {required, minLength: minLength(5)},
                        city: {required},
                        lat: {required},
                        lng: {required}
                    }
                }
            }
        },
        computed: {
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>

</style>