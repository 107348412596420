//import config from 'config';
import {handleResponse, requestOptions} from '@/helpers';

export const efaSocieteService = {
    getAll,
    getBySiret,
    getByEfaId
};

/**
 *
 * @param zip_code {String} - chaine de caractères à rechercher
 * @returns {Promise<Response>}
 */
function getAll() {
    return fetch(`${process.env.VUE_APP_API_URI}/efa/societes/`, requestOptions.get())
        .then(handleResponse);
}

/**
 *
 * @param siret
 * @returns {Promise<Response>}
 */
function getBySiret(siret){
    return fetch(`${process.env.VUE_APP_API_URI}/efa/societes/${siret}`, requestOptions.get())
        .then(handleResponse);
}

/**
 *
 * @param id
 * @returns {Promise<Response>}
 */
function getByEfaId(id){
    return fetch(`${process.env.VUE_APP_API_URI}/efa/societes/efaid/${id}`, requestOptions.get())
        .then(handleResponse);
}