<template>
    <div v-if="data.rating !== null && data.rating >= 2" class="p-4 text-center" style="max-width: 500px; min-width: 500px">
        <CRow class=".min-width-500">
            <CBadge color="primary" class="mx-auto min-width-500">
                <CRow>
                    <CCol style="text-align: left">
                        <span v-if="data.name.length <= 45">{{data.name}}</span>
                        <span v-else v-c-tooltip="data.name"
                              color="primary">{{(data.name).substr(0,41)+"..."}}</span>
                    </CCol>
                    <CCol v-if="data.description" col="2">
                        <font-awesome-icon icon="info-circle" v-c-tooltip="{content:data.description , appendToBody:true}" color="primary"/>
                    </CCol>
                </CRow>
            </CBadge>
        </CRow>
        <CRow v-if="data.rating">
            <CCol v-if="data.ratename" class="text-center">
                {{data.ratename}}
            </CCol>
            <CCol v-else class="text-center">
                <div v-if="data.rating === 2">
                    Application
                </div>
                <div v-else-if="data.rating === 3">
                    Maitrise
                </div>
                <div v-else-if="data.rating === 4">
                    Expert
                </div>
            </CCol>
            <CCol class="text-center">
                <StarRating :level="parseInt(data.rating)"></StarRating>
            </CCol>
        </CRow>
    </div>
</template>


<script>
    import StarRating from "./StarRating";

    export default {
        name: "BadgeExperience",
        props: {
            data: {
                type: Object,
                required: true
            }
        },
        components: {
            StarRating
        }
    }
</script>

<style scoped>
    span {
        font-size: 15px;
    }

    .min-width-500 {
        min-width: 400px;
    }
</style>