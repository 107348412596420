export const Roles = {
    Admin: 'administrator',//super admin
    User: 'user',//utilisateur / candidat
    Admin_RH: "admin_hr", //admin RH
    Assistant_RH: "assistant_hr", //Assistant RH
    Manager: "immediate_supervisor", // SHD
    Central_Administrator: "central_administrator", // Administrateur Central
    Referral_Support: "referral_support", //CIP
    /* --- Entreprises recruteuses --- */
    Company_Administrator: "company_administrator", //administrateur entreprise
    Recruiter: "recruiter", //chargé de recrutement
}