<template>
    <CContainer>
        <CRow>
            <CCol>
                <h2 class="mb-4">Nos activités</h2>
            </CCol>
        </CRow>
        <div v-if="sections && sectors && activities">
            <template v-for="(sector, sector_index) in sectors">
                <hr v-bind:key="'hr' + sector._id" v-if="sector_index > 0">
                <CRow v-bind:key="sector._id">
                    <CCol col="2" class="text-center h-100">
                        <i :class="'fa-4x sector-icon ' + sector.code"></i>
                    </CCol>
                    <CCol class="text-left">
                        <h4>{{sector.name}}</h4>
                        <CRow v-for="section in sections.filter(section => section.code_secteur === sector.code)" v-bind:key="section._id" class="ml-5">
                            <CCol>
                                <h5>{{section.name}}</h5>
                                <CRow v-for="activity in activities.filter(activity => activity.code_section === section.code)" v-bind:key="activity.id" class="ml-5">
                                    <CCol>
                                        <h6>{{activity.name}}</h6>
                                    </CCol>
                                </CRow>
                            </CCol>
                        </CRow>
                    </CCol>
                </CRow>
            </template>
        </div>
        <div v-else-if="partner_sectors">
            <template v-for="sector in partner_sectors">
                <CRow v-bind:key="sector._id" class="mb-3">
                    <CCol col="2" class="text-center h-100">
                        <font-awesome-icon :icon="sector.icon" size="2x"></font-awesome-icon>
                    </CCol>
                    <CCol class="text-left">
                        <h4>{{sector.name}}</h4>
                    </CCol>
                </CRow>
            </template>
        </div>
    </CContainer>
</template>

<script>
    export default {
        props: {
            activities: {
            },
            sections: {
            },
            sectors: {
            },
            partner_sectors: {
            }
        },
        computed: {},
        created() {
        }
    }
</script>
<style scoped>
    .test{color:white;}
</style>