import {i18n} from "../lang/lang";
import Dashboard from "../views/Dashboard/Dashboard";

export default [
    {
        path: 'dashboard',
        name: i18n.t('routes.dashboard'),
        component: Dashboard,
        meta: {
            authorize: [], //doit être connecté
            title: "JobInclusif.fr - " + i18n.t('routes.dashboard')
        }
    }
]