//import config from 'config';
import {handleResponse, requestOptions} from '@/helpers';

export const geoService = {
    searchZipCode
};

/**
 *
 * @param zip_code {String} - chaine de caractères à rechercher
 * @returns {Promise<Response>}
 */
function searchZipCode(zip_code) {
    return fetch(`${process.env.VUE_APP_API_URI}/geo/searchzipcode/${zip_code}`, requestOptions.get())
        .then(handleResponse);
}