import {i18n} from "../lang/lang";
import Resumes from "../views/resumes/Resumes";
import ResumeList from "../views/resumes/ResumeList";
import ResumeForm from "../views/resumes/ResumeForm";
import ResumeFormStep1 from "../views/resumes/partials/publish/ResumeFormStep1";
import ResumeFormStep2 from "../views/resumes/partials/publish/ResumeFormStep2";
import ResumeFormStep3 from "../views/resumes/partials/publish/ResumeFormStep3";
import ResumeFormValidate from "../views/resumes/partials/publish/ResumeFormValidate";
import ResumeImport from "../views/resumes/partials/publish/ResumeImport";

export default [
    {
        path: 'resumes/published',
        name: i18n.t('routes.resumes.published'),
        component: ResumeList,
        meta:{
            authorize: [],
            title: "JobInclusif.fr - " + i18n.t('routes.resumes.published')
        }
    },
    {
        path: 'resumes/:id',
        name : i18n.t('routes.resumes.index'),
        component:Resumes,
        meta:{
            authorize: [],
            title: "JobInclusif.fr - " + i18n.t('routes.resumes.index')
        }
    },
    {
        path: 'resumes/publish',
        redirect: '/app/resumes/publish/1',
        name : i18n.t('routes.resumes.publish.index'),
        component:ResumeForm,
        meta:{
            authorize: [],
            title: "JobInclusif.fr - " + i18n.t('routes.resumes.publish')
        },
        children: [
            {
                path: '1',
                name : i18n.t('routes.resumes.publish.steps')[0],
                component:ResumeFormStep1,
                meta:{
                    authorize: [],
                    title: "JobInclusif.fr - " + i18n.t('routes.resumes.publish.index') + ' - ' + i18n.t('routes.resumes.publish.steps')[0]
                }
            },
            {
                path: '1/:uuid',
                name : i18n.t('routes.resumes.publish.steps')[0] + " ",
                component:ResumeFormStep1,
                meta:{
                    authorize: [],
                    title: "JobInclusif.fr - " + i18n.t('routes.resumes.publish.index') + ' - ' + i18n.t('routes.resumes.publish.steps')[0]
                }
            },
            {
                path: '2',
                name : i18n.t('routes.resumes.publish.steps')[1],
                component:ResumeFormStep2,
                meta:{
                    authorize: [],
                    title: "JobInclusif.fr - " + i18n.t('routes.resumes.publish.index') + ' - ' + i18n.t('routes.resumes.publish.steps')[1]
                }
            },
            {
                path: '2/:uuid',
                name : i18n.t('routes.resumes.publish.steps')[1] + " ",
                component:ResumeFormStep2,
                meta:{
                    authorize: [],
                    title: "JobInclusif.fr - " + i18n.t('routes.resumes.publish.index') + ' - ' + i18n.t('routes.resumes.publish.steps')[1]
                }
            },
            {
                path: '3',
                name : i18n.t('routes.resumes.publish.steps')[2],
                component:ResumeFormStep3,
                meta:{
                    authorize: [],
                    title: "JobInclusif.fr - " + i18n.t('routes.resumes.publish.index') + ' - ' + i18n.t('routes.resumes.publish.steps')[2]
                }
            },
            {
                path: '3/:uuid',
                name : i18n.t('routes.resumes.publish.steps')[2] + " ",
                component:ResumeFormStep3,
                meta:{
                    authorize: [],
                    title: "JobInclusif.fr - " + i18n.t('routes.resumes.publish.index') + ' - ' + i18n.t('routes.resumes.publish.steps')[2]
                }
            },
            {
                path: '4',
                name : i18n.t('routes.resumes.publish.steps')[3],
                component:ResumeFormValidate,
                meta:{
                    authorize: [],
                    title: "JobInclusif.fr - " + i18n.t('routes.resumes.publish.index') + ' - ' + i18n.t('routes.resumes.publish.steps')[3]
                }
            },
            {
                path: '4/:uuid',
                name : i18n.t('routes.resumes.publish.steps')[3] + " ",
                component:ResumeFormValidate,
                meta:{
                    authorize: [],
                    title: "JobInclusif.fr - " + i18n.t('routes.resumes.publish.index') + ' - ' + i18n.t('routes.resumes.publish.steps')[3]
                }
            },
        ]
    },
    {
        path: 'resumes/publish/init',
        name : i18n.t('routes.resumes.publish.index'),
        component:ResumeImport,
        meta:{
            authorize: [],
            title: "JobInclusif.fr - " + i18n.t('routes.resumes.publish')
        },
    },
    {
        path: 'resumes/publish/init/:uuid',
        name : i18n.t('routes.resumes.publish.index'),
        component:ResumeImport,
        meta:{
            authorize: [],
            title: "JobInclusif.fr - " + i18n.t('routes.resumes.publish')
        },
    }
]