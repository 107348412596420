<template>
    <CContainer v-if="resumes">
        <CButton
                @click="collapse = !collapse"
                color="primary"
                class="mb-2"
        >
            Voir les CV de cette entreprise
        </CButton>
        <CCollapse :show="collapse">
            <CCard>
                <CCardBody>
                    <CRow class="mt-4" v-if="resumes.length > 0">
                        <CCol col="4"  v-for="resume in resumes" v-bind:key="resume._id" class="pt-3">
                            <result-resume :resume="resume" :company="resume.company"></result-resume>
                        </CCol>
                    </CRow>
                    <CRow class="mt-4" v-else>
                        <CCol>
                            <h4>{{$t("favorites.errors.no_resumes")}}</h4>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
        </CCollapse>
    </CContainer>

</template>
<script>
    import resultResume from "../../search/partials/resultResume";

    export default {
        name: "ResumeCollapse",
        components: {
            resultResume,
        },
        props: {
            resumes: {
                required: true
            }
        },
        data() {
            return {
                collapse: false,
                innerCollapse: false
            }
        }
    }
</script>

<style scoped>

</style>