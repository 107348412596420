<template>
    <div class="customBadge bg-light rounded-circle d-flex align-items-center">
        <div class="badgeContent">
            <CRow v-if="img">
                <CCol v-if="text">
                    <div :style="imagePath" :title="text"></div>
                </CCol>
                <CCol v-else>
                    <div :style="imagePath"></div>
                </CCol>
            </CRow>
            <template v-else>
                <CRow v-if="icon">
                    <CCol v-if="color">
                        <font-awesome-icon :icon="icon" size="3x" :style={color:color}></font-awesome-icon>
                    </CCol>
                    <CCol v-else>
                        <font-awesome-icon :icon="icon" size="3x"></font-awesome-icon>
                    </CCol>
                </CRow>
                <CRow v-if="text">
                    <CCol>
                        <b>{{text}}</b>
                    </CCol>
                </CRow>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CompaniesBadge",
        props: {
            icon: {},
            img: {},
            imgsize: {
                width: Number,
                height: Number
            },
            text: {},
            color: {}
        },
        computed: {
            imagePath() {
                return "background-image : url('" + require('@/assets/images/' + this.img) + "');height:" + this.imgsize.height + "px;width:" + this.imgsize.width + "px;display:inline-block;";
            }
        }
    }
</script>
<style scoped>
    .customBadge {
        font-size: 20px;
        width: 200px;
        height: 200px;
        margin-left: auto;
        margin-right: auto;
    }

    .badgeContent {
        margin-left: auto;
        margin-right: auto;
    }
</style>