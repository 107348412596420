<template>
    <CCallout :color="color" style="width: auto;">
        <small class="text-muted">{{ title }}</small>
        <br />
        <span class="h4">{{value}}</span>
    </CCallout>
</template>

<script>
    export default {
        name: "HeaderCallout",
        props: {
            color: String,
            title: String,
            value: String
        }
    }
</script>

<style scoped>

</style>