<template>
    <CCard>
        <CCardHeader>
            <CCardTitle v-if="authorised([Roles.Referral_Support]) && resume">
                {{$t('routes.resumes.publish.index_cip')}} {{resume.fullName}}
            </CCardTitle>
            <CCardTitle v-else>
                {{$t('routes.resumes.publish.index')}}
            </CCardTitle>
            <steps-menu v-if="resume && loaded" :maxvalidsteps="resume.publishStep"></steps-menu>
        </CCardHeader>
        <CCardBody v-if="resume">
            <router-view></router-view>
        </CCardBody>
        <CCardBody v-else-if="!loaded">
            <CAlert color="info">
                <font-awesome-icon icon="spinner" spin></font-awesome-icon>
                Chargement du CV en cours...
            </CAlert>
        </CCardBody>
        <CCardBody v-else>
            <CAlert color="warning">
                Aucun CV importé, rendez-vous sur <a href="https://v5.e-formadapt.com">e-Formadapt</a> pour publier votre CV.
            </CAlert>
        </CCardBody>
    </CCard>
</template>

<script>
    import StepsMenu from "./partials/publish/StepsMenu";
    import {Roles} from '@/config';
    import {authorised_in_view} from "@/helpers";
    import {
        memberService,
        authenticationService,
        resumesService,
        efaSocieteService,
        companiesService
    } from '@/services';
    import { router } from '@/routes/router';

    export default {
        name: "ResumeForm",
        components: {
            StepsMenu
        },
        data() {
            return {
                Roles: Roles,
                currentUser: authenticationService.currentUserValue,
                userFromApi: null,
                resume: null,
                loaded: false,
                company: null,
                efa_societe: null,
                actualStep: 0
            }
        },
        methods: {
            authorised(authorised_roles = []) {
                if (this.userFromApi) return authorised_in_view(authorised_roles, this.userFromApi);
                return false;
            },
            load() {
                memberService.getById(this.currentUser.id).then(user => {
                    this.userFromApi = user;
                    const uuid = this.$route.params.uuid || user.uuid;
                    resumesService.getByUuid(uuid).then(cv => {
                        cv.dt_available = cv.dt_available.substr(0, 10);
                        if(cv.position && !cv.position.context) cv.position.context = "";
                        if(!cv.user.email) cv.user.email = "";
                        this.resume = cv;
                        this.loaded = true;
                        efaSocieteService.getByEfaId(this.resume.efa_id_societe).then(s => {
                            this.efa_societe = s;
                            if (s.siret && s.siret.replace(/ /g, '').replace(/\./g, '').length === 14) {
                                companiesService.getBySiret(s.siret).then(c => this.company = c);
                            }
                        });
                    }).catch(() => {
                        this.loaded = true;
                    });
                });
            },
            save() {
                this.loaded = false;
                if (this.resume && this.resume.publishStep < this.actualStep) this.resume.publishStep = this.actualStep; //validation de l'étape actuelle si pas déjà validée dans le passé
                resumesService.update(this.resume).then(() => {
                    // this.resume = r;
                    if(this.actualStep !== 4) {
                        let uri = '/app/resumes/publish/' + (this.actualStep + 1).toString();
                        if(this.$route.params.uuid) uri += '/' + this.$route.params.uuid;
                        router.push(uri);
                    }
                    this.load();
                    this.loaded = true;
                });
            }
        },
        mounted() {
            this.load();
            const splitted_path = this.$router.currentRoute.path.split('/');
            if(this.$route.params.uuid) this.actualStep = parseInt(splitted_path[splitted_path.length - 2]);
            else this.actualStep = parseInt(splitted_path[splitted_path.length - 1]);
        },
        watch: {
            '$route'() {
                const splitted_path = this.$router.currentRoute.path.split('/');
                if(this.$route.params.uuid) this.actualStep = parseInt(splitted_path[splitted_path.length - 2]);
                else this.actualStep = parseInt(splitted_path[splitted_path.length - 1]);
            }
        }
    }
</script>

<style scoped>

</style>