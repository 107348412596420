import {i18n} from "../lang/lang";

import Container from "../views/partials/Container";
import Logout from "../views/logout/logout";

import dashboard from "./dashboard";
import admin from "./admin/admin";
import companies from "./companies";
import search from "./search/search";
import cv from "./resumes";
import joboffers from "./joboffers";
import favorites from "./favorites";
import members from "./members";
import applications from "./applications";
import help from "./help";
import ChangePassword from "../views/members/ChangePassword";

let routes = [
    {
        path: 'logout',
        name: i18n.t('routes.logout'),
        component: Logout,
        meta: {
            authorize: [], //doit être connecté
            title: "JobInclusif.fr - " + i18n.t('routes.logout')
        }
    },
    {
        path: 'changepassword',
        name: i18n.t('routes.change_password'),
        component: ChangePassword,
        meta: {
            authorize: [], //doit être connecté
            title: "JobInclusif.fr - " + i18n.t('routes.change_password')
        }
    }
];
routes = routes.concat(dashboard);
routes = routes.concat(admin);
routes = routes.concat(companies);
routes = routes.concat(cv);
routes = routes.concat(search);
routes = routes.concat(joboffers);
routes = routes.concat(favorites);
routes = routes.concat(members);
routes = routes.concat(applications);
routes = routes.concat(help);

export default [
    {
        path: '/app',
        redirect: '/app/dashboard',
        component: Container,
        meta: {authorize: []}, //doit être connecté
        children: routes
    }
]