<template>
    <div class="text-center">
        <h4>
            <font-awesome-icon icon="spinner" size="4x" spin></font-awesome-icon>
            Import du CV en cours ...
        </h4>
    </div>
</template>

<script>
    import { router } from '@/routes/router';
    import {
        memberService,
        authenticationService,
        resumesService
    } from "@/services";
    export default {
        name: "ResumeImport",
        data() {
            return {
                currentUser: authenticationService.currentUserValue,
                userFromApi: null,
            }
        },
        mounted() {
            memberService.getById(this.currentUser.id).then(user => {
                this.userFromApi = user;
                const uuid = this.$route.params.uuid || user.uuid;
                resumesService.create(uuid).then(() => {
                    let uri = '/app/resumes/publish/1';
                    if(this.$route.params.uuid) uri += '/' + this.$route.params.uuid;
                    router.push(uri);
                });
            });
        }
    }
</script>

<style scoped>

</style>