<template>
    <div>
        <h2 class="text-primary mb-5">Bienvenue {{currentUser.lastName}} {{currentUser.firstName}}</h2>
        <CCard>
            <CCardBody class="pb-0">
                <CRow class="d-md-flex justify-content-md-around">
                    <!-- Pour un utilisateur normal, il doit avoir le rose user et uniquement celui là -->
                    <template v-if="(authorised([Roles.User]) && userFromApi.roles.length === 1) || (authorised([Roles.Manager]) && userFromApi.roles.length === 2) || (authorised([Roles.Referral_Support]) && userFromApi.roles.length <= 3)">
                        <ShortcutWidget :to="'/app/resumes/publish/' + (userResume.publishStep === 4 ? 4 : userResume.publishStep+1)" v-if="userResume && userResume._id" :title="$t('dashboard.shortcuts.resume')" icon="id-card" color="complementary1"></ShortcutWidget>
                        <ShortcutWidget to="/app/applications" :title="authorised(Roles.Referral_Support) ? $t('dashboard.shortcuts.applications_cip') : $t('dashboard.shortcuts.applications')" icon="file-alt" color="complementary4"></ShortcutWidget>
                    </template>

                    <ShortcutWidget to="/app/search/joboffers" :title="$t('dashboard.shortcuts.partner_offers')" icon="handshake" color="complementary5" v-if="authorised([Roles.User, Roles.Manager, Roles.Admin_RH, Roles.Assistant_RH, Roles.Admin])"></ShortcutWidget>

                    <template v-if="authorised([Roles.Admin_RH, Roles.Assistant_RH, Roles.Admin, Roles.Referral_Support])">
                        <ShortcutWidget to="/app/resumes/published" :title="$t('dashboard.shortcuts.resumes')" icon="id-card" color="secondary"></ShortcutWidget>
                        <ShortcutWidget :to="'/app/companies/'+company_id" :title="$t('dashboard.shortcuts.company_page')" icon="building" color="secondary" v-if="company_id && authorised([Roles.Admin_RH, Roles.Assistant_RH, Roles.Admin])"></ShortcutWidget>
                    </template>

                    <template v-if="authorised([Roles.Company_Administrator, Roles.Recruiter, Roles.Admin])">
                        <ShortcutWidget :to="'/app/companies/'+company_id" :title="$t('dashboard.shortcuts.company')" icon="building" color="secondary"></ShortcutWidget>
                        <ShortcutWidget to="/app/search/profiles" :title="$t('dashboard.shortcuts.search_profil')" icon="id-card" color="secondary"></ShortcutWidget>
                        <ShortcutWidget to="/app/search/saved" :title="$t('dashboard.shortcuts.my_saved_searchs')" icon="save" color="complementary4"></ShortcutWidget>
                        <ShortcutWidget to="/app/joboffers" :title="$t('dashboard.shortcuts.my_reruitements')" icon="tasks" color="complementary6"></ShortcutWidget>
                        <ShortcutWidget to="/app/joboffers/create" :title="$t('dashboard.shortcuts.publish_offer')" icon="upload" color="complementary5"></ShortcutWidget>
                    </template>

<!--                    <template v-if="authorised([Roles.Admin])">-->
<!--                        <ShortcutWidget to="/app/resumes/published" :title="$t('dashboard.shortcuts.resumes')" icon="id-card" color="secondary"></ShortcutWidget>-->
<!--                        <ShortcutWidget to="/app/search/profiles" :title="$t('dashboard.shortcuts.search_profil')" icon="id-card" color="secondary"></ShortcutWidget>-->
<!--                    </template>-->
                </CRow>
            </CCardBody>
        </CCard>

<!--        <CRow>-->
<!--            <CCol class="justify-content-start" sm="7" mx="0" my="0">-->
<!--                <CCard class="border-0 m-0">-->
<!--                    <CCardHeader class="text-black-50" style="background-color: #f0f3f5">-->
<!--                        {{$t("dashboard.notifications.title")}}-->
<!--                    </CCardHeader>-->
<!--                    <CCardBody class="p-0">-->
<!--                        <div class="list-group list-group-flush">-->
<!--                            <ReminderWidget v-if="notifications > 0" :title="$t('dashboard.notifications.title')" :total="notifications" to="/app/admin/members" icon="bell" color="warning">-->
<!--                            </ReminderWidget>-->
<!--                        </div>-->
<!--                    </CCardBody>-->
<!--                </CCard>-->
<!--            </CCol>-->

<!--            <CCol class="justify-content-between"></CCol>-->

<!--            <CCol class="justify-content-end" sm="4" mx="0" my="0">-->
<!--                <CCard class="border-0 m-0">-->
<!--                    <CCardHeader class="text-black-50" style="background-color: #f0f3f5">-->
<!--                        {{$t("dashboard.reminders.title")}}-->
<!--                    </CCardHeader>-->
<!--                    <CCardBody class="p-0">-->
<!--                        <div class="list-group list-group-flush" id="rappels">-->
<!--                            <ReminderWidget v-if="resume > 0  && authorised([Roles.Admin_RH])" :title="$t('dashboard.reminders.resume')" :total="resume" to="/app/admin/members" icon="bullhorn" color="warning"></ReminderWidget>-->
<!--                            <ReminderWidget v-if="partner_offers > 0" :title="$t('dashboard.reminders.partner_offers')" :total="partner_offers" to="/app/admin/members" icon="bullhorn" color="warning"></ReminderWidget>-->
<!--                        </div>-->
<!--                    </CCardBody>-->
<!--                </CCard>-->
<!--            </CCol>-->
<!--        </CRow>-->

    </div>
</template>

<script>
    import {Roles} from '@/config';
    import {memberService, authenticationService, resumesService, efaSocieteService, companiesService} from '@/services';
    import {AvatarColors} from "@/config";
    import {initialsAvatar, authorised_in_view} from "@/helpers";
    import ShortcutWidget from "./partials/ShortcutWidget";
    // import ReminderWidget from "./partials/ReminderWidget";

    export default {
        components: {/*ReminderWidget, */ShortcutWidget},
        data() {
            return {
                Roles: Roles,
                currentUser: authenticationService.currentUserValue,
                userFromApi: null,
                colors: AvatarColors,
                resume: 0,
                partner_offers: 0,
                notifications: 0,
                userResume: null,
                company_id: null,
            };
        },

        methods: {
            authorised(authorised_roles = []) {
                if (this.userFromApi) return authorised_in_view(authorised_roles, this.userFromApi);
                return false;
            },
            avatar(color = '#000000') {
                if (this.userFromApi) {
                    const firtsname = this.currentUser.firstName.trim().replace(/ /g, "+");
                    const lastname = this.currentUser.lastName.trim().replace(/ /g, "+");
                    return initialsAvatar(firtsname + '+' + lastname, color);
                }
                return null;
            }
        },
        computed: {},
        created() {
            memberService.getById(this.currentUser.id).then(user => {
                this.userFromApi = user;
                resumesService.getByUuid(user.uuid).then(r => this.userResume = r);
                if(user.id_company) this.company_id = user.id_company; //lien entreprise recruteuses
                if(user.id_company_efa) {
                    efaSocieteService.getByEfaId(user.id_company_efa).then(societe => {
                        if(societe.siret) {
                            companiesService.getBySiret(societe.siret).then(c => this.company_id = c._id);
                        }
                    });
                }
            });
            this.resume = 5;
            this.partner_offers = 1;
            this.notifications = 2;
        }
    };
</script>

<style scoped>
</style>
