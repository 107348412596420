/**
 *
 * @param authorised_roles {[String]}
 * @param currentUser
 * @returns {boolean}
 */
import {currentUserIsSuperAdmin} from "./currentUserIsSuperAdmin";

export function authorised_in_view(authorised_roles = [], currentUser) {
    if (currentUserIsSuperAdmin()) return true;
    let included = false;
    currentUser.roles.forEach(r => {
        if (authorised_roles.includes(r)) included = true;
    });
    return !authorised_roles || authorised_roles.length === 0 || included;
}