<template>
    <div v-if="userFromApi">
        <CCard v-if="userFromApi.saved_searches && userFromApi.saved_searches.length === 0">
            <CCardBody>
                <CRow>
                    <CCol>
                        <CAlert color="info">
                            <font-awesome-icon icon="info-circle"></font-awesome-icon>
                            Aucune recherche sauvegardée.
                        </CAlert>
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
        <CCard v-for="(search, index) in userFromApi.saved_searches" v-bind:key="'search_'+index">
            <CCardHeader>
                <CRow>
                    <CCol>
                        <CBadge>{{search.secteur.name}} / {{search.metier.name}} / {{search.fonction.name}}</CBadge>
                    </CCol>
                    <CCol class="text-right" v-if="search.city">
                        <small>
                            {{search.zip}} {{search.city}}
                        </small>
                        <small class="ml-3"><i>{{search.context}}</i></small>
                    </CCol>
                </CRow>
            </CCardHeader>
            <CCardFooter>
                <CRow>
                    <CCol col="2">
                        <CButton color="primary" size="sm" :to="`/app/search/profiles/${search.timestamp}`">
                            <font-awesome-icon icon="search"></font-awesome-icon>
                            {{$t('buttons.search')}}
                        </CButton>
                    </CCol>
                    <CCol class="text-center">
                        <table class="mx-auto">
                            <tr>
                                <td>
                                    Etre alerté quand un nouveau profil correspond à cette recherche
                                </td>
                                <td>
                                    <CSwitch shape="pill" color="success" size="sm" :checked="search.notifications" @update:checked="switch_notifications(search)" class="ml-2 mt-2" label-on="OUI" label-off="NON"></CSwitch>
                                </td>
                            </tr>
                        </table>
                    </CCol>
                    <CCol col="2" class="text-right">
                        <CButtonGroup v-if="trashIndex === search.timestamp">
                            <CButton color="success" size="sm" @click="trashSearch(search.timestamp)">
                                <font-awesome-icon icon="check"></font-awesome-icon>
                            </CButton>
                            <CButton color="danger" size="sm" @click="trashIndex = 0">
                                <font-awesome-icon icon="times"></font-awesome-icon>
                            </CButton>
                        </CButtonGroup>
                        <CButton color="danger" size="sm" @click="trashIndex = search.timestamp" v-else>
                            <font-awesome-icon icon="trash"></font-awesome-icon>
                            {{$t('buttons.trash')}}
                        </CButton>
                    </CCol>
                </CRow>
            </CCardFooter>
        </CCard>
        <CToaster :autohide="3000">
            <template v-for="t in removedSearches">
                <CToast :key="'toast' + t" :show="true" close-button class="bg-success text-white">
                    <font-awesome-icon icon="check"></font-awesome-icon>
                    la recherche a été supprimée !
                </CToast>
            </template>
        </CToaster>
    </div>
</template>

<script>
    import {memberService, authenticationService} from '@/services';
    export default {
        name: "savedSearch",
        data() {
            return {
                currentUser: authenticationService.currentUserValue,
                userFromApi: null,
                trashIndex: 0,
                removedSearches: 0,
            }
        },
        methods: {
            getUserFromApi() {
                memberService.getById(this.currentUser.id).then(user => this.userFromApi = user);
            },
            trashSearch(timestamp){
                let searches = [];
                this.userFromApi.saved_searches.forEach(s => {
                    if(s.timestamp !== timestamp) searches.push(s);
                })
                memberService.updateSearches(this.currentUser._id, searches).then(() => {
                    this.getUserFromApi();
                    this.removedSearches++;
                });
            },
            switch_notifications(sauvegarde) {
                sauvegarde.notifications = !sauvegarde.notifications;
                memberService.updateSearches(this.currentUser._id, this.userFromApi.saved_searches).then(() => {
                    this.getUserFromApi();
                });
            }
        },
        mounted() {
            this.getUserFromApi();
        }
    }
</script>

<style scoped>

</style>