<template>
    <CCard v-if="jobOffer">
        <CCardHeader>
            <CCardTitle class="text-center">
                <CButton class="float-left" color="primary" :to="'/app/joboffers/'+this.$route.params.id">
                    <font-awesome-icon icon="eye"></font-awesome-icon>
                    Consulter l'offre
                </CButton>
                <span>{{jobOffer.title}} : {{$t("joboffers.applications.title")}}</span>
            </CCardTitle>
        </CCardHeader>
        <CCardBody>
            <CDataTable :items="items"
                        :fields="fields"
                        :striped="true"
                        :border="true"
                        :small="true"
                        :sorter="{ resetable: true }"
                        :columnFilter="false"
                        :columnFilterValue="{}"
                        :tableFilter="{ placeholder: $t('datatable.search_placeholder'), label: $t('datatable.search') }"
                        :loading="false"
                        :pagination="true"
                        :itemsPerPage="25"
                        :itemsPerPageSelect="{ label: $t('datatable.elements_count') }"
                        :noItemsView="{ noResults: $t('datatable.noResults'), noItems: $t('datatable.noItems') }"
            >
                <td slot="resumes" slot-scope="{item}">
                    {{item.resumes[0].efa_resume_id}}
                </td>
                <td slot="status" slot-scope="{item}">
                    <div v-if="!editIndex || editIndex !== item._id">
                        {{item.status}}
                    </div>
                    <div v-else-if="editIndex && editIndex === item._id">
                        <select v-model="item.status" class="form-control">
                            <option v-for="option in status" v-bind:key="option.id">
                                {{ option.name }}
                            </option>
                        </select>
                    </div>
                </td>
                <td slot="jobdescriptions_target" slot-scope="{item}">
                    <template v-if="item.resumes.length > 0">
                        <CBadge class="m-1 border border-dark" v-for="target in item.resumes[0].jobdescriptions_target" v-bind:key="target.LIBELLE">
                            {{target.LIBELLE.substr(0,55)}}
                        </CBadge>
                    </template>
                </td>
                <td slot="jobdescriptions" slot-scope="{item}">
                    <template v-if="item.resumes.length > 0">
                        <CBadge class="m-1 border border-dark" v-for="jobdescription in item.resumes[0].jobdescriptions" v-bind:key="jobdescription.ID">
                            {{jobdescription.LIBELLE.substr(0,55)}}
                        </CBadge>
                    </template>
                </td>
                <td slot="dt_available" slot-scope="{item}">
                    {{format_date(item.resumes[0].dt_available)}}
                </td>
                <td slot="actions" slot-scope="{item}">
                    <div v-if="!editIndex || editIndex !== item._id">
                        <CButton :to="getUrlResume(item)" target="_blank" color="primary" size="sm">
                            <font-awesome-icon icon="eye"></font-awesome-icon>
                            Consulter le CV
                        </CButton>
                        <CButton color="warning" size="sm" @click="editApplication(item)">
                            <font-awesome-icon icon="edit"></font-awesome-icon>
                            Modifier
                        </CButton>
                    </div>
                    <div v-else-if="editIndex && editIndex === item._id">
                        <CButton color="success" size="sm" @click="updateStatus(item)">
                            <font-awesome-icon icon="check"></font-awesome-icon>
                            {{$t('buttons.save')}}
                        </CButton>
                        <CButton color="danger" size="sm" @click="cancelEdit()">
                            <font-awesome-icon icon="times"></font-awesome-icon>
                            {{$t('buttons.cancel')}}
                        </CButton>
                    </div>
                </td>
            </CDataTable>
        </CCardBody>
    </CCard>
</template>

<script>
    import {applicationsService, joboffersService, statusService} from "@/services";
    import {formatDate} from "@/helpers";

    export default {
        name: "JobOfferApplications",
        data() {
            return {
                items: null,
                jobOffer: null,
                editIndex: null,
                status: [],
                selectedStatus: null,
            };
        },
        methods: {
            format_date(value) {
                return formatDate(value)
            },
            initJobOffer() {
                joboffersService.getById(this.$route.params.id).then(response => {
                    this.jobOffer = response;
                })
            },
            initApplications() {
                applicationsService.getByIdJoboffer(this.$route.params.id).then(response => {
                    this.items = response;
                });
            },
            initStatus() {
                statusService.getAllApplicationsManual().then(response => {
                    Object.entries(response).forEach(([key, value]) => this.status.push({id: key, name: value}));
                })
            },
            updateStatus(application) {
                applicationsService.update(application).finally(this.editIndex = null);
            },
            editApplication(application) {
                this.editIndex = application._id;
                this.selectedStatus = application.status;
            },
            cancelEdit() {
                this.editIndex = null;
                this.initApplications();
            },
            getUrlResume(item) {
                return item.resumes[0] ? "/app/resumes/" + item.resumes[0]._id : "";
            },
        },
        computed: {
            fields() {
                return [
                    {
                        key: "resumes",
                        label: "CV",
                        filter: true,
                        sorter: false
                    },
                    {
                        key: "status",
                        label: "Etat",
                        filter: true,
                        sorter: false
                    },
                    {
                        key: "jobdescriptions_target",
                        label: "Fonctions recherchées",
                        filter: true,
                        sorter: false
                    },
                    {
                        key: "jobdescriptions",
                        label: "Fonctions actuelles",
                        filter: true,
                        sorter: false
                    },
                    {
                        key: "dt_available",
                        label: "Date de disponibilité",
                        filter: true,
                        sorter: false
                    },
                    {
                        key: 'actions',
                        label: '',
                        filter: false,
                        sorter: false
                    },
                ];
            }
        },
        mounted: async function () {
            this.initApplications();
            this.initJobOffer();
            this.initStatus();
        },
    }
</script>

<style scoped>

</style>