<template>
    <CCol xs="12" sm="4" md="3" lg="2" xl="1">
        <CCard class="pointer" @click="redirect(to)" style="width: 135px">
            <CCardHeader class="text-center p-1" style="height: 150px">
                <font-awesome-layers :class="`fa-5x text-${color} m-2`">
                    <font-awesome-icon icon="circle"/>
                    <font-awesome-icon :icon="icon" transform="shrink-7" :style="{ color: 'white' }"/>
                </font-awesome-layers>

                <h5 class="mt-2" style="overflow-wrap: normal">{{ title }}</h5>
            </CCardHeader>
            <!--        <CCardBody></CCardBody>-->
            <!--        <CCardFooter></CCardFooter>-->
        </CCard>
    </CCol>
</template>

<!--                   sinon : A creuser                                   -->
<!--                        <CCol xs="12" sm="6" md="4" lg="3" xl="1">-->
<!--                            <a href="" class="btn btn-outline-light btn-sm ">-->
<!--                                <div class="text-complementary my-0 mb-xl-2">-->
<!--                                    <font-awesome-layers class="fa-5x text-black m-2">-->
<!--                                        <font-awesome-icon icon="circle"/>-->
<!--                                        <font-awesome-icon icon="id-card" transform="shrink-7" :style="{ color: 'white' }"/>-->
<!--                                    </font-awesome-layers>-->
<!--                                </div>-->
<!--                                <h5 class="text-body m-0">eeeee</h5>-->
<!--                            </a>-->
<!--                        </CCol>-->
<script>
    import {router} from '@/routes/router';

    export default {
        name: "ShortcutWidget",
        props: {
            title: String,
            to: String,
            icon: String,
            color: String,
        },
        methods: {
            redirect(to) {
                router.push(to);
            }
        }
    }
</script>

<style scoped>
    .pointer:hover {
        cursor: pointer;
    }

    .pointer > header:hover {
        background-color: #ccc;
    }
</style>