<template>
    <CContainer v-if="companies && companies.filter(c => parentid !== c._id).length">
        <CButton
                @click="collapse = !collapse"
                color="primary"
                class="mb-2"
        >
            Voir les autres établissements
        </CButton>
        <CCollapse :show="collapse">
            <CCard>
                <CCardBody>
                    <table class="c-table c-table-striped">
                        <thead>
                            <tr>
                                <th style="width: 35%">Nom</th>
                                <th style="width: 35%">Rue</th>
                                <th style="width: 25%">Ville</th>
                                <th>Code Postal</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="company in companies.filter(c => parentid !== c._id)" v-bind:key="company._id">
                                <a :href="`/#/app/companies/${company._id}`">
                                    <td>{{company.name}}</td>
                                </a>
                                <td>{{company.address.street[0]}}</td>
                                <td>{{company.address.city}}</td>
                                <td>{{company.address.zip}}</td>
                            </tr>
                        </tbody>
                    </table>
                </CCardBody>
            </CCard>
        </CCollapse>
    </CContainer>

</template>
<script>

    export default {
        props: {
            companies: {
                required: true
            },
            parentid: String
        },
        data() {
            return {
                collapse: false,
                innerCollapse: false
            }
        }
    }
</script>

