import {i18n} from "../../lang/lang";
import {Roles} from "../../config";

import JobOffer from "../../views/search/JobOffer";

export default [
    {
        path: 'joboffers',
        name: i18n.t('routes.search.joboffers.index'),
        component: JobOffer,
        meta: {
            authorize: [Roles.Admin, Roles.Admin_RH, Roles.Assistant_RH, Roles.User, Roles.Manager],
            title: "JobInclusif.fr - " + i18n.t('routes.search.joboffers.index')
        }
    },
    {
        path: 'joboffers/:timestamp',
        name: i18n.t('routes.search.joboffers.index') + " ",
        component: JobOffer,
        meta: {
            authorize: [Roles.Admin, Roles.Admin_RH, Roles.Assistant_RH, Roles.User, Roles.Manager],
            title: "JobInclusif.fr - " + i18n.t('routes.search.joboffers.index')
        }
    }
];