import Vue from 'vue';
import App from './App.vue';
import store from './store';
import {i18n} from './lang/lang';
import {router} from './routes/router';

Vue.config.productionTip = false;

//----------------------------------------------Vuelidate----------------------------------------------
import Vuelidate from 'vuelidate';

Vue.use(Vuelidate);

//---------------------------------------------CoreUi---------------------------------------------------
import CoreuiVue from '@coreui/vue';
import {iconsSet as icons} from './assets/icons/icons.js';

Vue.use(CoreuiVue);

//---------------------------------------------FontAweosme-----------------------------------------------
import {fas} from "@fortawesome/free-solid-svg-icons";
import {fab} from "@fortawesome/free-brands-svg-icons";
import {library} from '@fortawesome/fontawesome-svg-core';

library.add(fas, fab);
import {FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText} from '@fortawesome/vue-fontawesome';
import {far} from "@fortawesome/free-regular-svg-icons";

library.add(fas, fab, far);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);

//--------------------------------------------sentry-------------------------------------------------------
import * as Sentry from '@sentry/browser';
import {Vue as VueIntegration} from '@sentry/integrations';

if (process.env.NODE_ENV !== "development" && process.env.VUE_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [new VueIntegration({Vue, attachProps: true})],
        environment: process.env.NODE_ENV,
    });
}

//-----------------------------------------------VueSelect-------------------------------------------------
import vSelect from 'vue-select'

Vue.component('v-select', vSelect)

new Vue({
    i18n,
    router,
    store,
    icons,
    template: '<App/>',
    components: {
        App
    }
    //render: h => h(App),
}).$mount('#app')

