export default {
    reminders: {
        title: "Rappels",
        total: "Total",
        news: "Nouveaux",
        resume: "CV Publiés",
        partner_offers: "Offres partenaires",
    },

    shortcuts: {
        title: "Raccourcis",
        resumes: "CV Publiés",
        members: "Utilisateurs",
        partner_offers: "Offres partenaires",
        company_page: "Page entreprise",
        companies: "Entreprises",
        search_profil: "Rechercher un profil",
        my_saved_searchs: "Mes recherches sauvegardées",
        my_reruitements: "Mes recrutements",
        publish_offer: "Publier une offre",
        resume: "Mon CV",
        applications: "Mes Candidatures",
        applications_cip: "Candidatures Suivies",
        company : "Mon entreprise"
    },

    notifications: {
        title: "Notifications",
    },
}