export default {
    name: 'Raison Sociale',
    description: 'Description',
    adhesion: 'Adhésion UNEA',
    address: 'Adresse',
    phone: 'Téléphone',
    fax: 'Fax',
    mail: "Adresse E-Mail",
    employees_count: "Masse Salariale",
    website: "Site Web",
    activities: "Activités"
}