<template>
    <span>
        <font-awesome-icon icon="star" size="lg" class="text-warning" v-for="index in level" v-bind:key="index"></font-awesome-icon>
        <font-awesome-icon :icon="['far', 'star']" size="lg" class="text-secondary" v-for="index in (4-level)" v-bind:key="'r-'+index+level"></font-awesome-icon>
    </span>
</template>

<script>
	export default {
		props: {
			level: Number
		},
		name: "starRating"
	}
</script>

<style scoped>

</style>