import { render, staticRenderFns } from "./JobOffer.vue?vue&type=template&id=245c9dd8&scoped=true&"
import script from "./JobOffer.vue?vue&type=script&lang=js&"
export * from "./JobOffer.vue?vue&type=script&lang=js&"
import style0 from "./JobOffer.vue?vue&type=style&index=0&id=245c9dd8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "245c9dd8",
  null
  
)

/* custom blocks */
import block0 from "./JobOffer.vue?vue&type=custom&index=0&blockType=link&rel=stylesheet&href=https%3A%2F%2Funpkg.com%2Fleaflet%401.6.0%2Fdist%2Fleaflet.css"
if (typeof block0 === 'function') block0(component)

export default component.exports