export default {
    titles:{
        resumes: "Les candidats sauvegardés",
        companies: "Les entreprises sauvegardées",
        filters: "Filtres"
    },
    filters:{
        not_available : "Voir les candidats non disponibles"
    },
    errors:{
        no_resumes:"Vous n'avez pas sauvegardé de candidats",
        no_companies:"Vous n'avez pas sauvegardé d'entreprises"
    }
}