export default {
    content: {
        contact: "Contact",
        sendMail: "Envoyer un email",
        web: "Site Web",
        address: "Adresse",
        btnCollapse: "Voir les autres établissement de cette entreprise",
        badge: {
            gpec: "Engagé dans une démarche de GPEC",
            cddt: "Expérimentation CDD Tremplin"
        },
    },
    form: {
        cardHeader:{
            new:"Création d'une entreprise",
            modif:"Modification d'une entreprise"
        },
        sectionTitle: {
            general : "Informations générales",
            locate: "Adresse et localisation",
            activities : "Secteurs d'activités",
            contact : "Contacts"
        },
        label : {
            name : "Raison sociale",
            description : "Qui sommes nous ?",
            logo : "Logo de l'entreprise",
            ca : "Chiffre d'affaire en €",
            employes : "Nombre de salariés",
            web: "Site web",
            mail: "Email de contact",
            city: "Ville",
            address: "Adresse",
            zip:"Code postal",
            email:"Adresse email de contact",
            phone:"Numéro de téléphone",
            siren:"SIREN de la société",
            siret:"SIRET de l'entreprise",
            year_ca : "Année du chiffre d'affaire"
        },
    }

}