export default {
    //general
    administrator: 'Super Admin',
    user: 'Utilisateur',
    admin_hr: "Admin RH",
    assistant_hr: "Assistant RH",
    immediate_supervisor: "Manager",
    central_administrator: "Administrateur Central",
    referral_support: "Référent Accompagnement",
    recruiter: "Chargé de recrutement",
    company_administrator: "Administrateur Entreprise",
}