<template>
    <CProgress height="70px" v-if="steps && steps.length">
        <template v-for=" (step, i) in steps">
            <CProgressBar :value="25" :color="getColor(i)" :class="getClass(i)" :key="'step_' + i">
                <CRow>
                    <CCol>
                        <CButton @click="redirectTo(step.to, i)" class="btn-link text-light" v-if="i <= maxvalidsteps && i !== activestepindex">
                            <font-awesome-icon :icon="getIcon(i)" size="2x"></font-awesome-icon>
                            <br>
                            {{step.label}}
                            <br>
                        </CButton>
                        <template v-else>
                            <font-awesome-icon :icon="getIcon(i)" size="2x"></font-awesome-icon>
                            <br>
                            {{step.label}}
                            <br>
                        </template>
                    </CCol>
                </CRow>
            </CProgressBar>
        </template>
    </CProgress>
</template>

<script>
    import {router} from '@/routes/router';

    export default {
        name: "ProgressMenu",
        props: [
            'steps',
            'activestepindex',
            'maxvalidsteps'
        ],
        methods: {
            getColor(index) {
                if (index === this.activestepindex) return 'info';
                else if (index === this.maxvalidsteps) return 'primary';
                else if (index < this.maxvalidsteps) return 'success';
                return 'gray-500';
            },
            getIcon(index) {
                /*if (index === this.activestepindex) return 'info-circle';
                else */if (index === this.maxvalidsteps) return 'arrow-circle-down';
                else if (index < this.maxvalidsteps) return 'check-circle';
                return 'times-circle';
            },
            getClass(index) {
                let classe = '';
                if (index > 0) classe += 'border-left ';
                return classe;
            },
            redirectTo(to, index) {
                if (index <= this.maxvalidsteps && index !== this.activestepindex) router.push(to);
            }
        }
    }
</script>

<style scoped>
    .pointer {
        cursor: pointer;
    }
</style>