<template>
    <progress-menu :steps="steps" :activestepindex="actualStep" :maxvalidsteps="maxStep"></progress-menu>
</template>

<script>
    import ProgressMenu from "../../../partials/ProgressMenu";
    import {i18n} from "../../../../lang/lang";

    export default {
        name: "StepsMenu",
        components: {
            ProgressMenu
        },
        props: [
            'maxvalidsteps'
        ],
        data() {
            return {
                steps: [
                    {
                        label: i18n.t('routes.resumes.publish.steps')[0],
                        to: '/app/resumes/publish/1',
                    },
                    {
                        label: i18n.t('routes.resumes.publish.steps')[1],
                        to: '/app/resumes/publish/2',
                    },
                    {
                        label: i18n.t('routes.resumes.publish.steps')[2],
                        to: '/app/resumes/publish/3',
                    },
                    {
                        label: i18n.t('routes.resumes.publish.steps')[3],
                        to: '/app/resumes/publish/4',
                    }
                ],
                actualStep: 0,
                maxStep: 0,
            };
        },
        mounted() {
            const splitted_path = this.$router.currentRoute.path.split('/');
            this.actualStep =  parseInt(splitted_path[splitted_path.length - 1]) - 1;
            this.maxStep = this.maxvalidsteps;
        },
        watch: {
            '$route'() {
                this.maxStep = this.$parent.resume.publishStep || this.maxvalidsteps;
                const splitted_path = this.$router.currentRoute.path.split('/');
                this.actualStep =  parseInt(splitted_path[splitted_path.length - 1]) - 1;
            }
        }
    }
</script>

<style scoped>
    .progress-bar:hover {
        cursor: pointer;
    }

</style>