<template>
    <CForm v-if="joboffer" @submit.prevent="onSubmit()">
        <CRow>
            <CCol>
                <div class="bg-light text-dark text-center rounded border p-1 mb-2"><strong> Etape {{this.current_step}} : {{$t('joboffers.steps.' + this.current_step)}}</strong></div>
            </CCol>
        </CRow>
        <br>
        <CRow>
            <CCol col="4">
                <label for="select_contract">{{$t('joboffers.contract.label')}}</label>
                <v-select id="select_contract" :options="contracts" v-model="selected_contract" :placeholder="$t('joboffers.contract.placeholder')" label="name"></v-select>
                <CInput type="hidden" v-model.trim="$v.selected_contract.name.$model"
                        :invalidFeedback="$t('joboffers.errors_messages.contract')" :isValid="isFieldValid($v.selected_contract.name)"></CInput>
            </CCol>
            <CCol col="4">
                <label for="select_work_times">{{$t('joboffers.work_times.label')}}</label>
                <v-select id="select_work_times" :options="work_times" v-model="selected_work_time" :placeholder="$t('joboffers.work_times.placeholder')" label="name"></v-select>
                <CInput type="hidden" v-model.trim="$v.selected_contract.name.$model"></CInput>
            </CCol>
            <CCol col="4">
                <CInput id="start_date_wanted" v-model="joboffer.start_date_wanted" :label="$t('joboffers.start_date.wanted')" type="date"
                        :invalidFeedback="$t('joboffers.errors_messages.start_date_wanted')" :isValid="isFieldValid($v.joboffer.start_date_wanted)"></CInput>
            </CCol>
        </CRow>
        <br>
        <CRow>
            <CCol col="4">
                <CInput id="payment_min" type="number" min="0" v-model="joboffer.payment.min" :label="$t('joboffers.payment.min.label')"
                        :placeholder="$t('joboffers.payment.min.placeholder')" :description="$t('joboffers.payment.small')"></CInput>
            </CCol>
            <CCol col="4">
                <CInput id="payment_max" type="number" min="0" v-model="joboffer.payment.max" :label="$t('joboffers.payment.max.label')"
                        :placeholder="$t('joboffers.payment.max.placeholder')" :description="$t('joboffers.payment.small')"></CInput>
            </CCol>
        </CRow>
        <br>
        <CRow>
            <CCol col="6">
                <label for="select_complementary_requirements">{{$t('joboffers.complementary_requirements.label')}}</label>
                <v-select id="select_complementary_requirements" :options="complementary_requirements" v-model="selected_complementary_requirements" multiple
                          :placeholder="$t('joboffers.complementary_requirements.placeholder')" label="name"></v-select>

            </CCol>
            <CCol col="6">
                <label for="select_adaptation_measures">{{$t('joboffers.adaptation_measures.label')}}</label>
                <v-select id="select_adaptation_measures" :options="adaptation_measures" v-model="selected_adaptation_measures" multiple
                          :placeholder="$t('joboffers.adaptation_measures.placeholder')" label="name"></v-select>
            </CCol>
        </CRow>
        <br>
        <JobOfferForm_buttons :base_url="base_url" :previous_step='this.base_url + "/step_" + this.previous_step' :next_step='this.base_url + "/step_" + this.next_step'></JobOfferForm_buttons>
    </CForm>
</template>

<script>
    import {joboffersService, contractsService, workTimesService, complementaryRequirementsService, adaptationMeasuresService} from '@/services';
    import JobOfferForm_buttons from "../JobOfferForm_buttons";
    import {required} from "vuelidate/lib/validators";

    export default {
        name: "JobOffer_step_2",
        components: {JobOfferForm_buttons},
        data() {
            return {
                loading: false,
                submitted: false,
                base_url: null,
                previous_step: 1,
                current_step: 2,
                next_step: 3,
                joboffer: null,
                contracts: [],
                complementary_requirements: [],
                work_times: [],
                adaptation_measures: [],
                selected_contract: false,
                selected_complementary_requirements: false,
                selected_work_time: false,
                selected_adaptation_measures: false,
            }
        },
        validations: {
            selected_contract: {
                name: {required},
            },
            joboffer: {
                start_date_wanted: {required},
            },

        },
        methods: {
            isFieldValid(field) {
                if (!this.submitted) return null;
                if (field.$error) return false;
                return true;
            },
            onSubmit() {
                this.submitted = true;
                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return false;
                }
                this.submitted = false;
                this.loading = true;

                this.joboffer.status = "step_" + this.current_step;

                if (this.selected_contract !== false) this.joboffer.contract = this.selected_contract;
                if (this.selected_work_time !== false) this.joboffer.work_times = this.selected_work_time;
                if (this.selected_complementary_requirements !== false) this.joboffer.complementary_requirements = this.selected_complementary_requirements;
                if (this.selected_adaptation_measures !== false) this.joboffer.adaptation_measures = this.selected_adaptation_measures;

                this.$parent.update(this.joboffer);
                return true;
            },
        },
        mounted: async function () {
            const id = this.$route.params.id || null;
            if (id) {
                joboffersService.getById(id).then(response => {
                    this.joboffer = response;
                    this.selected_contract = response.contract;
                    this.selected_complementary_requirements = response.complementary_requirements;
                    this.selected_work_time = response.work_times;
                    this.selected_adaptation_measures = response.adaptation_measures;
                    this.joboffer.start_date_wanted = response.start_date_wanted.substr(0, 10);
                    this.search_zip = response.location.zip;
                    this.search_coords = response.location;
                    this.base_url = "/app/joboffers/" + response._id + "/update";
                });
            }
        },
        created() {
            contractsService.getAll().then(response => {
                Object.entries(response).forEach(([key, value]) => this.contracts.push({id: key, name: value}));
            });

            workTimesService.getAll().then(response => {
                Object.entries(response).forEach(([key, value]) => this.work_times.push({id: key, name: value}));
            });

            complementaryRequirementsService.getAll().then(response => {
                Object.entries(response).forEach(([key, value]) => this.complementary_requirements.push({id: key, name: value}));
            });

            adaptationMeasuresService.getAll().then(response => {
                Object.entries(response).forEach(([key, value]) => this.adaptation_measures.push({id: key, name: value}));
            });
        }
    }
</script>

<style scoped>

</style>