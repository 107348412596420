<template>
    <CRow>
        <CCol>
            <CButton color="primary" @click="previousStep()" v-if="previous_step">
                <font-awesome-icon icon="arrow-left"></font-awesome-icon>
                Etape précédante
            </CButton>
            &nbsp;
            <CButton color="danger" @click="cancel()">
                <font-awesome-icon icon="times"></font-awesome-icon>
                Annuler
            </CButton>
        </CCol>
        <CCol class="text-right">
            <div v-if="this.$route.params.id">
                <CButton color="success" type="submit">
                    <font-awesome-icon icon="save"></font-awesome-icon>
                </CButton>
                &nbsp;
                <CButton color="primary" @click="nextStep()">
                    <font-awesome-icon icon="arrow-right"></font-awesome-icon>
                    Etape Suivante
                </CButton>
            </div>
            <CButton color="success" @click="submitForm()" v-else>
                <font-awesome-icon icon="plus"></font-awesome-icon>
                Créer
            </CButton>
        </CCol>
    </CRow>
</template>

<script>
    import {router} from '@/routes/router';

    export default {
        name: "JobOfferForm_buttons",
        props: {
            base_url: null,
            previous_step: null,
            next_step: null,
        },
        methods: {
            submitForm() {
                return this.$parent.onSubmit();
            },
            redirectPreviousStep() {
                return this.previous_step ? router.push(this.previous_step) : this.cancel();
            },
            previousStep() {
                const ok = this.submitForm();
                if (ok !== false) {
                    this.redirectPreviousStep();
                }
            },
            redirectNextStep() {
                return this.next_step ? router.push(this.next_step) : this.cancel();
            },
            nextStep() {
                const ok = this.submitForm();
                if (ok !== false) {
                    this.redirectNextStep();
                }
            },
            cancel() {
                return router.push(this.base_url);
            },
        }
    }
</script>

<style scoped>

</style>