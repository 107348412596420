//import config from 'config';
import {handleResponse, requestOptions} from '@/helpers';

export const searchService = {
    searchProfiles,
    searchJobOffers
};

/**
 *
 * @param search_text {String} - chaine de caractères à rechercher
 * @returns {Promise<Response>}
 */
function searchProfiles(search_text) {
    return fetch(`${process.env.VUE_APP_API_URI}/search/profiles/${search_text}`, requestOptions.get())
        .then(handleResponse);
}

/**
 *
 * @param search_text {String} - chaine de caractères à rechercher
 * @returns {Promise<Response>}
 */
function searchJobOffers(search_text) {
    return fetch(`${process.env.VUE_APP_API_URI}/search/joboffers/${search_text}`, requestOptions.get())
        .then(handleResponse);
}