<template>
    <CHeader fixed with-subheader light>
        <CToggler
                in-header
                class="ml-3 d-lg-none"
                @click="$store.commit('toggleSidebarMobile')"
        />
        <CToggler
                in-header
                class="ml-3 d-md-down-none"
                @click="$store.commit('toggleSidebarDesktop')"
        />
        <CHeaderBrand class="mx-auto d-lg-none" to="/">
            <!--      <CIcon name="logo" height="48" alt="Logo"/>-->
            <img src="../../assets/images/Logo_long_color.png" style="height: 48px"/>
        </CHeaderBrand>
        <CHeaderNav class="d-md-down-none mr-auto">
            <!--      <CHeaderNavItem class="px-3">-->
            <!--        <CHeaderNavLink to="/dashboard">-->
            <!--          Dashboard-->
            <!--        </CHeaderNavLink>-->
            <!--      </CHeaderNavItem>-->
            <!--      <CHeaderNavItem class="px-3">-->
            <!--        <CHeaderNavLink to="/users" exact>-->
            <!--          Users-->
            <!--        </CHeaderNavLink>-->
            <!--      </CHeaderNavItem>-->
            <!--      <CHeaderNavItem class="px-3">-->
            <!--        <CHeaderNavLink>-->
            <!--          Settings-->
            <!--        </CHeaderNavLink>-->
            <!--      </CHeaderNavItem>-->
        </CHeaderNav>
        <CHeaderNav class="mr-4">
            <CHeaderNavItem>
                <font-awesome-icon icon="sun" class="mb-1"></font-awesome-icon>
                <CSwitch color="primary" size="sm" class="mx-2" shape="pill" :checked="isDark" @update:checked="switchTheme()"></CSwitch>
                <font-awesome-icon icon="moon" class="mb-1"></font-awesome-icon>
            </CHeaderNavItem>
            <!--      <CHeaderNavItem class="d-md-down-none mx-2">-->
            <!--        <CHeaderNavLink>-->
            <!--          <CIcon name="cil-bell"/>-->
            <!--        </CHeaderNavLink>-->
            <!--      </CHeaderNavItem>-->
            <!--      <CHeaderNavItem class="d-md-down-none mx-2">-->
            <!--        <CHeaderNavLink>-->
            <!--          <CIcon name="cil-list"/>-->
            <!--        </CHeaderNavLink>-->
            <!--      </CHeaderNavItem>-->
            <!--      <CHeaderNavItem class="d-md-down-none mx-2">-->
            <!--        <CHeaderNavLink>-->
            <!--          <CIcon name="cil-envelope-open"/>-->
            <!--        </CHeaderNavLink>-->
            <!--      </CHeaderNavItem>-->
            <HeaderDropdownAccent/>
        </CHeaderNav>
        <CSubheader class="px-3">
            <CBreadcrumbRouter class="border-0 mb-0"/>
        </CSubheader>
    </CHeader>
</template>

<script>
    import HeaderDropdownAccent from './HeaderDropdownAccent'

    export default {
        name: 'TheHeader',
        data() {
            return {
                theme: 'light',
            }
        },
        methods: {
            switchTheme() {
                if(this.theme === 'light') {
                    this.theme = 'dark';
                    document.getElementsByClassName('c-app')[0].classList.add('c-dark-theme');
                } else {
                    this.theme = 'light';
                    document.getElementsByClassName('c-app')[0].classList.remove('c-dark-theme')
                }
            }
        },
        computed: {
            isDark() {
                return this.theme === 'dark';
            }
        },
        components: {
            HeaderDropdownAccent
        }
    }
</script>
