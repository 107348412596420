<template>
    <CCard class="h-100 mb-2 border-secondary text">
        <CCardHeader class="text-center border-0">
            <CRow>
                <CCol>
                    <font-awesome-icon icon="briefcase" size="3x"></font-awesome-icon>
                </CCol>
            </CRow>
            <CRow>
                <CCol>
                    <CButton :to="'/app/joboffers/' + offer._id" class="h4 btn-link" target="_blank">{{offer.function.name}}</CButton>
                </CCol>
            </CRow>
            <CRow>
                <CCol>
                    <small>
                        {{offer.location.zip}} {{offer.location.city_name}}
                        <br>
                        {{offer.location.context.substring(4, offer.location.context.length)}}
                    </small>
                </CCol>
            </CRow>
            <CRow v-if="offer.companies && offer.companies.length">
                <CCol>
                    <br>Chez <a :href="'/#/app/companies/' + offer.companies[0]._id" target="_blank">{{offer.companies[0].name}}</a>
                </CCol>
            </CRow>
        </CCardHeader>
    </CCard>
</template>

<script>
    export default {
        name: "resultOffer",
        props: ['offer']
    }
</script>

<style scoped>

</style>