import {i18n} from "../lang/lang";
import Favorites from "../views/favorites/Favorites";

export default [
    {
        path: 'favorites',
        name: i18n.t('routes.favorites'),
        component: Favorites,
        meta: {
            authorize: [], //doit être connecté
            title: "JobInclusif.fr - " + i18n.t('routes.favorites')
        }
    }
]