export default {
    show: "Consulter",
    create: "Créer",
    update: "Modifier",
    save: "Sauvegarder",
    cancel: "Annuler",
    trash: 'Supprimer',
    search: 'Rechercher',
    save_search: 'Sauvegarder la recherche',
    add: "Ajouter",
    validate: "Valider",
    publish: "Valider et Publier",
    unpublish: "Dépublier",
    unpublish_resume: "Dépublier le CV",
    restore: "Restaurer"
}