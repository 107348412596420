export default {
    //general
    home: 'Accueil',
    dashboard: "Tableau de bord",
    login: "Se connecter",
    logout: "Se déconnecter",
    //Super Admin
    admin: {
        index: 'Administration',
        index_super: 'Super Admin',
        members: {
            category: "Gestion des Utilisateurs",
            index: "Liste des Utilisateurs",
            create: "Création d'un utilisateur",
            update: "Mise à jour d'un utilisateur",
            delete: "Supprimer un Utilisateur"
        },
        companies: {
            category: "Gestion des Entreprises",
            index: "Liste des Entreprises",
            create: "Création d'une Entreprise",
            update: "Mise à jour d'une Entreprise",
            delete: "Supprimer une Entreprise"
        }
    },
    admin_hr: {
        partner_offers: "Offres d'emploi partenaires",
        admin: {
            index: 'Administration',
            resume: "CV publiés",
        },
    },
    company_administrator: {
        search_profil: "Rechercher un profil",
        recruit: {
            index: "Recruter",
            publish_offer: "Publier une offre",
            my_profil_selection: "Ma sélection de profils",
            my_saved_searchs: "Mes recherches sauvegardées",
            my_reruitements: "Mes recrutements",
        },
        admin: {
            index: "Administration",
            members: "Utilisateurs",
        },
        companies: {
            category: "Gestion de l'entreprise",
            consult: "Consulter l'entreprise",
            update: "Modification de l'entreprise",
        }
    },
    //entreprises
    companies: {
        index: "Les Entreprises",
        unique: "L'entreprise",
        page: "Page entreprise",
        update: "Modification de l'entreprise"
    },
    search: {
        index: 'Recherche',
        profiles: {
            index: "Rechercher un profil",
        },
        joboffers: {
            index: "Rechercher une offre d'emploi partenaire",
        },
        saved: {
            index: 'Mes Recherches Sauvegardées'
        }
    },
    applications: {
        index:"Consultation des candidatures"
    },
    favorites:"Mes favoris",
    resumes: {
        index: 'CV',
        published: 'Liste des CV publiés',
        publish: {
            index: 'Publier mon CV',
            index_cip: 'Publier le CV de',
            steps: [
                "Ce que j'affiche sur mon CV",
                'Mes postes recherchés',
                'Ma disponibilité et ma mobilité',
                'Valider et publier mon CV'
            ]
        }
    },
    help: {
        index: 'Aide',
        contact: 'Contacter le support'
    },
    change_password: "Modifier mon mot de passe"
}