<template>
    <div>
        <CCard>
            <CCardHeader>
                <CCardTitle>
                    {{$t('routes.admin_hr.admin.resume')}}
                </CCardTitle>
            </CCardHeader>
            <CCardBody>
                <CDataTable :items="resumes"
                            :fields="resumesFields"
                            :striped="true"
                            :border="true"
                            :small="true"
                            :sorter="{ resetable: true }"
                            :columnFilter="false"
                            :columnFilterValue="{}"
                            :tableFilter="{ placeholder: $t('datatable.search_placeholder'), label: $t('datatable.search') }"
                            :loading="false"
                            :pagination="true"
                            :itemsPerPage="25"
                            :itemsPerPageSelect="{ label: $t('datatable.elements_count'), }"
                            :noItemsView="{ noResults: $t('datatable.noResults'), noItems: $t('datatable.noItems') }"
                >
                    <td slot="updatedAt" slot-scope="{item}">
                        <span>{{new Date(item.updatedAt).toLocaleDateString()}}</span>
                    </td>
                    <td slot="AvailableDate" slot-scope="{item}">
                        <span>{{new Date(item.dt_available).toLocaleDateString()}}</span>
                    </td>
                    <td slot="cip_fullName" slot-scope="{item}">
                        <span>{{item.cip_fullName || ""}}</span>
                    </td>
                    <td slot="manager_fullName" slot-scope="{item}">
                        <span>{{item.manager_fullName || ""}}</span>
                    </td>
                    <td slot="cip" slot-scope="{item}">
                        <span>{{item.cip_id || ""}}</span>
                    </td>
                    <td slot="publishDate" slot-scope="{item}">
                        <span>{{item.publishDate || ""}}</span>
                    </td>
                    <td slot="jobdescriptions_names" slot-scope="{item}">
                        <CBadge class="border m-1" v-for="(jobdescription, index) in item.jobdescriptions_names" v-bind:key="'jd_' + index + '_' + jobdescription">{{jobdescription}}</CBadge>
                    </td>
                    <td slot="jobdescriptions_target_names" slot-scope="{item}">
                        <CBadge class="border m-1" v-for="(jobdescription_target, index) in item.jobdescriptions_target_names" v-bind:key="'jd_target_' + index + '_' + jobdescription_target">
                            {{jobdescription_target}}
                        </CBadge>
                    </td>
                    <td slot="contract" slot-scope="{item}">
                        <span>{{item.user.ID_CONTRAT || ""}}</span>
                    </td>
                    <td slot="actions" slot-scope="{item}">
                        <CButtonGroup>
                            <CButton :to="base_url + item._id" color="primary" size="sm">
                                <font-awesome-icon icon="eye"></font-awesome-icon>
                                {{$t('buttons.show')}}
                            </CButton>
                            <CButton :to="'/app/resumes/publish/' + (item.publishStep === 4 ? '4' : (item.publishStep+1).toString()) + '/' + item.efa_user_uuid" color="warning" size="sm"
                                     v-if="authorised([Roles.Referral_Support])">
                                <font-awesome-icon icon="edit"></font-awesome-icon>
                                {{$t('buttons.update')}}
                            </CButton>
                        </CButtonGroup>
                    </td>
                </CDataTable>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
    import {memberService, authenticationService, resumesService} from '@/services';
    import {Roles} from '@/config';
    import {authorised_in_view} from "@/helpers";

    //TODO: Renommer composant en 'Resumes' : pour le moment impossible car Alex bosse dessus (exemple de cv)
    export default {
        name: "ResumeList",
        data() {
            return {
                Roles: Roles,
                resumes: [],
                base_url: "/app/resumes/",
                currentUser: authenticationService.currentUserValue,
                userFromApi: null,
            };
        },
        methods: {
            authorised(authorised_roles = []) {
                if (this.userFromApi) return authorised_in_view(authorised_roles, this.userFromApi);
                return false;
            }
        },
        computed: {
            resumesFields() {
                return [
                    {
                        key: 'fullName',
                        label: "Nom Prénom",
                        filter: true
                    },
                    {
                        key: 'manager_fullName',
                        label: "Manager",
                        filter: true
                    },
                    {
                        key: 'cip_fullName',
                        label: "Référent Accompagnement",
                        filter: true
                    },
                    {
                        key: 'updatedAt',
                        label: "Date de publication",
                        filter: true
                    },
                    {
                        key: 'jobdescriptions_names',
                        label: "Fonctions actuelles",
                        filter: true
                    },
                    {
                        key: 'jobdescriptions_target_names',
                        label: "Postes recherchés",
                        filter: true
                    },
                    {
                        key: 'AvailableDate',
                        label: "Date de disponibilité",
                        filter: true
                    },
                    {
                        key: 'actions',
                        label: '',
                        filter: false,
                        sorter: false
                    }
                ];
            }
        },
        created() {
            memberService.getById(this.currentUser.id).then(user => {
                this.userFromApi = user;
                resumesService.getByEfaIdSociete(user.id_company_efa).then(r => this.resumes = r);
            });
        },
    }
</script>

<style scoped>

</style>