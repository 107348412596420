<template>
    <CContainer v-if="userFromApi">
        <CCard>
            <CCardHeader>
                <CRow>
                    <CCol class="test-left">
                        <h3>{{$t("favorites.titles.resumes")}} :</h3>
                    </CCol>
                    <CCol class="text-right ">
                        <span class="mt-2"><b>{{$t("favorites.titles.filters")}} :</b></span>
                        {{$t("favorites.filters.not_available")}} <CSwitch class="mx-1 align-middle" color="secondary" shape="pill" @update:checked="switchResumeVisibility"/>
                    </CCol>
                </CRow>
            </CCardHeader>
            <CCardBody>
                <CRow class="mt-4" v-if="resumes && resumes.length > 0">
                    <template v-for="resume in resumes" >
                        <CCol col="4" v-if="resume.status !== 'draft' || showDeleted"   v-bind:key="resume._id" class="pt-3">
                            <result-resume :resume="resume" :company="resume.company"></result-resume>
                        </CCol>
                    </template>
                </CRow>
                <CRow class="mt-4" v-else>
                    <CCol>
                        <h4>{{$t("favorites.errors.no_resumes")}}</h4>
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
        <CCard>
            <CCardHeader>
                <h3>{{$t("favorites.titles.companies")}} :</h3>
            </CCardHeader>
            <CCardBody>
                <CRow class="mt-4" v-if="userFromApi.favorites.companies.length > 0">
                    <CCol col="4" v-for="company in userFromApi.favorites.companies" v-bind:key="company._id" class="pt-2">
                        <result-company :company="company" :sectors="company.sectors"></result-company>
                    </CCol>
                </CRow>
                <CRow class="mt-4" v-else>
                    <CCol>
                        <h4>{{$t("favorites.errors.no_companies")}}</h4>
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
    </CContainer>
</template>

<script>
    import {authenticationService} from '@/services';
    import resultCompany from "../search/partials/resultCompany";
    import resultResume from "../search/partials/resultResume";
    import {memberService, resumesService} from "../../services";

    export default {
        name: "Favorites",
        components: {
            resultResume,
            resultCompany
        },
        data() {
            return {
                userFromApi:null,
                resumes:[],
                showDeleted:false
            }
        },
        methods:{
            switchResumeVisibility(){
                this.showDeleted === true ? this.showDeleted = false : this.showDeleted = true;
            }
        },
        mounted() {
            memberService.getById(authenticationService.currentUserValue._id).then(user => {
                this.userFromApi = user;
                if(user.favorites.resumes && user.favorites.resumes.length > 0) {
                    user.favorites.resumes.forEach(resume_id => {
                        resumesService.getByEfaResumeId(resume_id).then(resume => this.resumes.push(resume));
                    })
                }
            });
        }
    }
</script>

<style scoped>

</style>