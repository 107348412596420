import {authenticationService} from "@/services";
import {Roles} from "@/config";

/**
 *
 * @param user
 * @returns {*|Observable<unknown>|[string]|[string]|[string]|[string]|boolean}
 */
export function currentUserIsSuperAdmin(user) {
    const currentUser = user || authenticationService.currentUserValue;
    return currentUser && currentUser.roles && currentUser.roles.includes(Roles.Admin);
}