export default {
    index: "Offres d'emploi",
    create: "Créer une offre",
    edit: "Editer une offre",
    consult: "Consulter une offre",

    close: "Clôturer",
    rePublish: "Re-Publier",

    deleted: {
        show: "Afficher les offres suprimées",
        hide: "Masquer les offres suprimées",
    },
    closed: {
        show: "Afficher les offres clôturées",
        hide: "Masquer les offres clôturées",
    },
    applications: {
        title: "Candidatures",
    },
    steps: {
        1: "Description",
        2: "Conditions de travail",
        3: "Localisation du poste",
    },
    status: {
        published: "Publiée",
        draft: "Brouillon",
        deleted: "Supprimée",
        closed: "Clôturée",
    },
    title: "Titre de l'offre",
    secteur: "Sélectionner un secteur",
    metier: "Sélectionner un métier",
    fonction: "Sélectionner une fonction",
    description: {
        label: "Descriptif du poste",
        placeholder: "Description facultative. \nLes conditions de travail (type de contrat, temps de travails, éventuellement aménagements de postes et rémunération sont abordés dans l’étape suivante)\n",
    },
    payment: {
        label: "Rémunération",
        small: "(En k€ brut annuel sur la base d'un temps plein)",
        min: {
            label: "Rémunération minimum",
            placeholder: "Minimum en k€",
        },
        max: {
            label: "Rémunération maximum",
            placeholder: "Maximum en k€",
        },
    },
    start_date: {
        wanted: "Date de prise de poste envisagée",
    },
    contract: {
        label: "Type de contrat proposé",
        placeholder: "Sélectionnez un type de contrat",
    },
    complementary_requirements: {
        label: "Conditions de travail spécifiques",
        placeholder: "Sélectionnez une exigence",
    },
    work_times: {
        label: "Temps de travail",
        placeholder: "Sélectionnez un temps de travail",
    },
    adaptation_measures: {
        label: "Mesures envisageables pour l'adaptation aux handicaps",
        // label: "Mesures d'adaptation aux handicaps envisageables",
        placeholder: "Sélectionnez une mesure",
    },

    address: "Adresse exacte",
    zip: "Code Postal",
    city: {
        label: "Ville",
        placeholder: "Selectionnez une ville",
    },

    errors_messages: {
        title: "Veuillez saisir un titre",
        secteur: "Veuillez sélectionner un secteur",
        metier: "Veuillez sélectionner un métier",
        fonction: "Veuillez sélectionner une fonction",
        contract: "Veuillez sélectionner un contrat",
        start_date_wanted: "Veuillez saisir une date",
        zip: "Veuillez saisir un Code Postal",
        city: "Veuillez sélectionner une ville",
    },
}