<template>
    <CCard class="h-100 mb-2 border-secondary">
        <CCardHeader class="text-center">
            <CRow>
                <CCol>
                    <CBadge class="mb-2 h6" color="secondary" v-if="resume.status==='draft'"><b>Supprimé</b></CBadge>
                </CCol>
            </CRow>
            <CRow>
                <CCol>
                    <font-awesome-icon icon="user" size="3x"></font-awesome-icon>
                </CCol>
            </CRow>
            <CRow>
                <CCol>
                    <CButton v-if="resume.status==='draft'" class="h4 btn-link" target="_blank">CV {{resume.efa_user_id}}</CButton>
                    <CButton v-else :to="'/app/resumes/' + resume._id" class="h4 btn-link" target="_blank">CV {{resume.efa_user_id}}</CButton>
                </CCol>
            </CRow>
            <CRow>
                <CCol>
                    <small>{{resume.position.zip}} {{resume.position.city}}</small>
                </CCol>
            </CRow>
        </CCardHeader>
        <CCardBody>
            <CRow>
                <CCol class="border-right">
                    <CRow>
                        <CCol>
                            <small><b>Recherche un poste de :</b></small>
                        </CCol>
                    </CRow>
                    <CRow v-for="f in resume.jobdescriptions_target" :key="'ft_' + resume._id + '_' + f.LIBELLE">
                        <CCol class="text-uppercase">
                            - <small>{{f.LIBELLE}}</small>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol>
                            <small>
                                <b>Disponibilité : </b>
                                <span v-if="new Date(resume.dt_available).getTime() - Date.now() <= 0">immédiate</span>
                                <span v-else>à partir du {{new Date(resume.dt_available).toLocaleDateString()}}</span>
                            </small>
                        </CCol>
                    </CRow>
                </CCol>
                <CCol>
                    <CRow>
                        <CCol>
                            <small><b>Actuellement :</b></small>
                        </CCol>
                    </CRow>
                    <CRow v-for="f in resume.jobdescriptions" v-bind:key="'f' + f.ID">
                        <CCol class="text-uppercase">
                            - <small>{{f.LIBELLE}}</small>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol>
                            <small v-if="company"><b>Chez :</b>
                                <a v-if="company.crm" :href="'/#/app/companies/' + company.crm._id" target="_blank"> {{company.crm.name}}</a>
                                <span v-else> {{company.libelle}}</span>
                            </small>
                        </CCol>
                    </CRow>
                </CCol>
            </CRow>
            <!--            <small><i>{{u.adresse.cp}} {{u.adresse.ville}}</i></small>-->
        </CCardBody>
    </CCard>
</template>

<script>
    export default {
        name: "resultResume",
        props: {
            resume: {},
            company: {}
        },
        methods: {},
        created() {
        }
    }
</script>

<style scoped>

</style>