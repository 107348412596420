<template>
    <CContainer class="d-flex content-center min-vh-100">
        <CRow>
            <CCol>
                <CCard
                        class="p-6"
                        body-wrapper
                        style="min-width: 500px;"
                >
                    <CCardBody>
                        <CForm @submit.prevent="onSubmit" v-if="!passwordlost">
                            <h1>Connexion</h1>
                            <p class="text-muted">Connectez-vous à votre compte</p>
                            <CInput
                                    placeholder="E-Mail"
                                    autocomplete="username email"
                                    v-model.trim="$v.username.$model"
                                    invalidFeedback="E-Mail requis"
                                    :isValid="isUsernameValid()"
                            >
                                <template #prepend-content><CIcon name="cil-user"/></template>
                            </CInput>
                            <CInput
                                    placeholder="Mot de Passe"
                                    type="password"
                                    autocomplete="curent-password"
                                    v-model.trim="$v.password.$model"
                                    invalidFeedback="Mot de passe requis"
                                    :isValid="isPasswordValid()"
                            >
                                <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                            </CInput>
                            <CRow>
                                <CCol class="text-left">
                                    <CButton type="submit" color="primary" class="px-4">Se Connecter</CButton>
                                </CCol>
                                <CCol class="text-right">
                                    <CButton color="link" v-if="!passwordlost" @click="passwordlost = true">Mot de passe oublié</CButton>
                                </CCol>
                            </CRow>
                        </CForm>
                        <CForm v-else>
                            <h1>Mot de passe oublié</h1>
                            <p class="text-muted">Saisissez votre adresse email pour recevoir un nouveau mot de passe</p>
                            <CInput
                                    placeholder="E-Mail"
                                    autocomplete="email"
                                    v-model="email"
                                    type="email"
                            >
                                <template #prepend-content><CIcon name="cil-user"/></template>
                            </CInput>
                            <CRow>
                                <CCol class="text-left">
                                    <CButton color="primary" @click="resetPassword()">Réinitialiser le mot de passe</CButton>
                                </CCol>
                                <CCol class="text-right">
                                    <CButton color="danger" @click="cancelResetPassword()">
                                        <font-awesome-icon icon="times"></font-awesome-icon>
                                        {{$t('buttons.cancel')}}
                                    </CButton>
                                </CCol>
                            </CRow>
                        </CForm>
                        <br>
                        <CAlert color="danger" v-if="error">
                            {{error}}
                        </CAlert>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <CToaster :autohide="3000">
            <template v-for="t in passwordChangedToastr">
                <CToast :key="'toast' + t" :show="true" close-button class="bg-success text-white">
                    <font-awesome-icon icon="check"></font-awesome-icon>
                    Les informations d'identifications ont été envoyées a l'adresse spécifiée
                </CToast>
            </template>
        </CToaster>
    </CContainer>
</template>

<script>
    import { required } from 'vuelidate/lib/validators';

    import { router } from '@/routes/router';
    import { authenticationService } from '@/services';

    export default {
        name: "Login",
        data () {
            return {
                username: '',
                password: '',
                email: '',
                submitted: false,
                loading: false,
                returnUrl: '',
                error: '',
                passwordlost: false,
                passwordChangedToastr: 0
            };
        },
        validations: {
            username: { required },
            password: { required }
        },
        created () {
            //récupération de returnUrl depuis l'url ou '/app/dashboard' par défaut
            this.returnUrl = this.$route.query.returnUrl || '/app/dashboard';
            //récupération du token si il existe dans la requête
            const token = this.$route.params.token || this.$route.query.token || null;
            // redirection si l'utilisateur est déjà connecté et aucun token spécifié
            if (authenticationService.currentUserValue && !token) {
                return router.push(this.returnUrl);
            }
            //connexion efa si le token est spécifié
            if(token) {
                authenticationService.logout();//forçage fermeture de session
                authenticationService.efaLogin(token)
                    .then(
                        user => {
                            if(user) console.log(user);
                            router.push(this.returnUrl);
                        },
                        error => {
                            this.error = error;
                            this.loading = false;
                        }
                    );
            }
        },
        methods: {
            cancelResetPassword() {
                this.email = "";
                this.passwordlost = false;
            },
            resetPassword() {
                authenticationService.lostpassword(this.email)
                    .then(() => {
                        this.cancelResetPassword();
                        this.passwordChangedToastr++;
                    });
            },
            isUsernameValid() {
                if(!this.submitted) return null;
                else if(this.submitted && this.$v.username.$error) return false;
                return true;
            },
            isPasswordValid() {
                if(!this.submitted) return null;
                else if(this.submitted && this.$v.password.$error) return false;
                return true;
            },
            onSubmit () {
                this.submitted = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }

                this.loading = true;
                authenticationService.login(this.username, this.password)
                    .then(
                        user => {
                            if(user) router.push(this.returnUrl);
                        },
                        error => {
                            this.error = error;
                            this.loading = false;
                        }
                    );
            }
        }
    };
</script>