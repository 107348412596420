/**
 *
 * @param value
 * @param withTime {boolean}
 * @returns {string|null}
 */
export function formatDate(value, withTime = false) {
    if (!value) return null;

    const date = new Date(value);

    if (withTime === false) return date.toLocaleDateString();

    return date.toLocaleString([], {year: "numeric", month: "2-digit", day: "2-digit", hour: '2-digit', minute: '2-digit'});
}