<template>
    <CContainer class="d-flex align-items-center min-vh-100">
        <CRow class="w-100 justify-content-center">
            <CCol md="6">
                <div class="w-100">
                    <div class="clearfix">
                        <h1 class="float-left display-3 mr-4">404</h1>
                        <h4 class="pt-3">Oops! Vous vous êtes perdu.</h4>
                        <p class="text-muted">La page que vous recherchez n'a pas été trouvée.</p>
                    </div>
                    <div class="text-center">
                        <CLink to="/app/dashboard" class="btn btn-primary">Retour à l'accueil</CLink>
                    </div>
                </div>
            </CCol>
        </CRow>
    </CContainer>
</template>

<script>
    export default {
        name: "err404"
    }
</script>

<style scoped>

</style>