import {i18n} from "../lang/lang";

import Help from "../views/help/Help";

import Contact from "../views/help/Contact";

export default [
    {
        path: 'help',
        name: i18n.t('routes.help.index'),
        component: Help,
        meta:{
            authorize: [],
            title: "JobInclusif.fr - " + i18n.t('routes.help.index')
        },
        children: [
            {
                path: 'contact',
                name: i18n.t('routes.help.contact'),
                component: Contact,
                meta:{
                    authorize: [],
                    title: "JobInclusif.fr - " + i18n.t('routes.help.contact')
                }
            },
        ]
    }
]