<template>
    <CForm v-if="joboffer" @submit.prevent="onSubmit()">
        <CRow>
            <CCol>
                <div class="bg-light text-dark text-center rounded border p-1 mb-2"><strong> Etape {{this.current_step}} : {{$t('joboffers.steps.' + this.current_step)}}</strong></div>
            </CCol>
        </CRow>
        <br>
        <CRow>
            <CCol col="4">
                <CInput id="search_zip" type="text" :label="$t('joboffers.zip')" :placeholder="$t('joboffers.zip')" @keyup="zipChanged()" :disabled="search_running"
                        v-model.trim="$v.search_zip.$model" :invalidFeedback="$t('joboffers.errors_messages.zip')" :isValid="isFieldValid($v.search_zip)"></CInput>
            </CCol>
            <CCol col="8">
                <label for="search_city">
                    <i class="fas fa-spinner fa-spin" v-if="search_running"></i>
                    {{$t('joboffers.city.label')}}
                </label>
                <v-select id="search_city" label="city_name" :options="results_cities" v-model="search_coords" :placeholder="$t('joboffers.city.placeholder')"></v-select>
                <CInput type="hidden" v-model.trim="$v.search_coords.city_name.$model"
                        :invalidFeedback="$t('joboffers.errors_messages.city')" :isValid="isFieldValid($v.search_coords.city_name)"></CInput>
            </CCol>
        </CRow>
        <br>
        <CRow>
            <CCol col="12">
                <CInput id="address" :label="$t('joboffers.address')" v-model="joboffer.location.street" :placeholder="$t('joboffers.address')"></CInput>
            </CCol>
        </CRow>
        <br>
        <JobOfferForm_buttons :base_url="base_url" :previous_step='this.base_url + "/step_" + this.previous_step' :next_step='null'></JobOfferForm_buttons>
    </CForm>

</template>

<script>
    import {joboffersService, geoService} from '@/services';
    import JobOfferForm_buttons from "../JobOfferForm_buttons";
    import {minLength, required} from "vuelidate/lib/validators";

    export default {
        name: "JobOffer_step_3",
        components: {JobOfferForm_buttons},
        data() {
            return {
                loading: false,
                submitted: false,
                search_running: false,
                base_url: null,
                previous_step: 2,
                current_step: 3,
                next_step: "",
                joboffer: null,
                address: "",
                search_zip: "",
                search_city: "",
                search_coords: {
                    city_name: "",
                    context: "",
                    lat: null,
                    lng: null
                },
                results_cities: [],
            }
        },
        validations: {
            search_zip: {required, minLength: minLength(5)},
            search_coords: {
                city_name: {required},
            },
        },
        methods: {
            isFieldValid(field) {
                if (!this.submitted) return null;
                if (field.$error) return false;
                return true;
            },
            onSubmit() {
                this.submitted = true;
                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return false;
                }
                this.submitted = false;
                this.loading = true;

                this.joboffer.status = "step_" + this.current_step;

                if (this.search_coords) {
                    if (this.search_coords.city_name) this.joboffer.location.city_name = this.search_coords.city_name;
                    if (this.search_coords.context) this.joboffer.location.context = this.search_coords.context;
                    if (this.search_coords.lat) this.joboffer.location.lat = this.search_coords.lat;
                    if (this.search_coords.lng) this.joboffer.location.lng = this.search_coords.lng;
                    if (this.search_zip) this.joboffer.location.zip = this.search_zip;
                }

                this.$parent.update(this.joboffer);
                return true;
            },
            zipChanged: async function () {
                this.search_coords = {
                    city_name: "",
                    context: "",
                    lat: null,
                    lng: null
                };
                if (this.search_zip.length === 5) {
                    this.search_running = true;
                    geoService.searchZipCode(this.search_zip).then(cities => {
                        this.results_cities = cities;
                        this.search_running = false;
                    }).catch(err => {
                        if (err) console.log(err);
                        this.search_running = false;
                    });
                }
            },
        },
        mounted: async function () {
            const id = this.$route.params.id || null;
            if (id) {
                joboffersService.getById(id).then(response => {
                    this.joboffer = response;
                    this.search_zip = response.location.zip;
                    this.zipChanged();
                    this.search_coords = response.location;
                    this.base_url = "/app/joboffers/" + response._id + "/update";
                });
            }
        },

    }
</script>

<style scoped>

</style>