<template>
    <div>
        <CRow>
            <CCol>
                <CForm v-if="company" @submit.prevent="onSubmit()">
                    <CCard>
                        <CCardHeader>
                            <h4 v-if="this.$route.params.id">{{$t('company.form.cardHeader.modif')}} :
                                {{company.name}}</h4>
                            <h4 v-else>{{$t('company.form.cardHeader.new')}}</h4>
                        </CCardHeader>
                        <CCardBody>
                            <div class="formContent">


                                <!--Section informations générales-->
                                <CRow>
                                    <CCol>
                                        <h4>{{$t('company.form.sectionTitle.general')}}</h4>
                                        <CRow>
                                            <CCol>
                                                <CInput
                                                        v-model.trim="$v.company.name.$model"
                                                        :label="$t('company.form.label.name')"
                                                        invalidFeedback="Nom requis"
                                                        :isValid="isValid(this.$v.company.name)"
                                                ></CInput>
                                            </CCol>
                                            <CCol>
<!--                                                <CInput-->
<!--                                                        :label="$t('company.form.label.logo')"-->
<!--                                                        type="file"-->
<!--                                                        v-model="logo"-->
<!--                                                ></CInput>-->
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            <!--Champ description de l'entreprise-->
                                            <CCol col="7">
                                                <CTextarea
                                                        :label="$t('company.form.label.description')"
                                                        type="textarea"
                                                        rows="12"
                                                        v-model="company.description"
                                                ></CTextarea>
                                            </CCol>
                                            <!-- / Champ description de l'entreprise-->
                                            <CCol>
                                                <!--Chiffre d'affaire, nombre de salariés et Site Web-->
                                                <CRow>
                                                    <CCol>
                                                        <CInput v-model="$v.company.ca.$model"
                                                                :label="$t('company.form.label.ca')" append=",00"
                                                                prepend="€"
                                                                invalidFeedback="Le chiffre d'affaire doit être un nombre"
                                                                :isValid="isValid(this.$v.company.ca)"></CInput>
                                                    </CCol>
                                                    <CCol>
                                                        <CInput v-model="company.year_ca"
                                                                :label="$t('company.form.label.year_ca')">
                                                        </CInput>
                                                    </CCol>
                                                </CRow>
                                                <CRow>
                                                    <CCol>
                                                        <CInput :label="$t('company.form.label.employes')"
                                                                v-model="company.employees_count"></CInput>
                                                    </CCol>
                                                </CRow>
                                                <CRow>
                                                    <CCol>
                                                        <CInput v-model="$v.company.website.$model"
                                                                :label="$t('company.form.label.web')"
                                                                invalidFeedback="Ce n'est pas une URL valide"
                                                                :isValid="isValid(this.$v.company.website)"
                                                        ></CInput>
                                                    </CCol>
                                                </CRow>
                                                <CRow>
                                                    <CCol>
                                                        <CInput :label="$t('company.form.label.siret')"
                                                                v-model="company.siret"></CInput>
                                                    </CCol>
                                                    <CCol>
                                                        <CInput :label="$t('company.form.label.siren')"
                                                                v-model="company.siren"></CInput>
                                                    </CCol>
                                                </CRow>
                                                <!--/ Chiffre d'affaire, nombre de salariés et Site Web-->
                                            </CCol>
                                        </CRow>
                                    </CCol>
                                </CRow>
                                <!--/ Section informations générales-->


                                <!--Section adresse-->
                                <h4 class="mb-3">{{$t('company.form.sectionTitle.locate')}}</h4>
                                <CRow class="mb-3">
                                    <CCol col="4">
                                        <label for="search_zip">Code Postal</label>
                                        <CInput id="search_zip" type="text" v-model="search_zip"
                                                placeholder="Code Postal" @keyup="zipChanged()"
                                                :disabled="search_running"></CInput>
                                    </CCol>
                                    <CCol col="8">
                                        <label for="search_city">
                                            <i class="fas fa-spinner fa-spin" v-if="search_running"></i>
                                            Ville
                                        </label>
                                        <v-select id="search_city" label="city_name" :options="results_cities"
                                                  v-model="search_coords"
                                                  placeholder="Sélectionnez une ville"></v-select>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol>
                                        <CInput id="address" :label="$t('company.form.label.address')"
                                                v-model="street"
                                                :placeholder="$t('company.form.label.address')"></CInput>
                                    </CCol>
                                </CRow>
                                <!--/ Section adresse-->

                                <h4 class="mb-3">{{$t('company.form.sectionTitle.activities')}}</h4>
                                <CRow>
                                    <CCol>
                                        <v-select class="mb-3"
                                                  :options="sectors"
                                                  v-model="selected_sectors"
                                                  multiple
                                                  placeholder="Selectionnez un ou plusieurs secteurs"
                                        ></v-select>
                                    </CCol>
                                </CRow>


                                <CRow>
                                    <CCol>
                                        <h4>{{$t('company.form.sectionTitle.contact')}}</h4>
                                        <CRow>
                                            <CCol>
                                                <CInput :label="$t('company.form.label.email')"
                                                        v-model="$v.company.mail.$model"
                                                        invalidFeedback="Ce n'est pas un email valide"
                                                        :isValid="isValid(this.$v.company.mail)"
                                                        type="email"></CInput>
                                            </CCol>
                                            <CCol>
                                                <CInput :label="$t('company.form.label.phone')"
                                                        v-model="company.phone"></CInput>
                                            </CCol>
                                        </CRow>
                                    </CCol>
                                </CRow>

                            </div>
                            <CRow class="mt-1">
                                <CCol>
                                    <CButton color="danger" to="/app/admin/companies">
                                        <font-awesome-icon icon="times"></font-awesome-icon>
                                        Annuler
                                    </CButton>
                                </CCol>
                                <CCol class="text-right">
                                    <CButton color="success" type="submit" v-if="this.$route.params.id">
                                        <font-awesome-icon icon="save"></font-awesome-icon>
                                        Sauvegarder
                                    </CButton>
                                    <CButton color="success" type="submit" v-else>
                                        <font-awesome-icon icon="plus"></font-awesome-icon>
                                        Créer
                                    </CButton>
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                </CForm>
            </CCol>
        </CRow>

    </div>
</template>

<script>
    import {required, numeric, url, email} from "vuelidate/lib/validators";
    import {companiesService, geoService, structureService} from "@/services";
    import {router} from '@/routes/router';

    export default {
        name: "CompanyForm",
        data() {
            return {
                loading: false,
                submitted: false,
                search_running: false,
                logo: null,
                Sectors: [],
                selected_sectors: [],
                company: {
                    address: {
                        street: []
                    }
                },
                street: "",
                search_zip: "",
                search_city: "",
                search_coords: {
                    city_name: "",
                    context: "",
                    lat: null,
                    lng: null
                },
                results_cities: [],
            }
        },
        validations: {
            company: {
                name: {required},
                mail: {required, type: email},
                ca: {type: numeric},
                website: {type: url}
            }
        },
        computed: {
            sectors() {
                let sectors = [];
                Object.keys(this.Sectors).forEach(key => {
                    let current_activity = this.Sectors[key];
                    sectors.push({
                        label: current_activity[1].name,
                        value: current_activity[1]
                    });
                });
                return sectors;
            },

        },
        mounted() {
            if (this.$route.params.id) {
                companiesService.getById(this.$route.params.id).then(company => {
                    this.company = company;
                    this.street = company.address.street[0];
                    this.search_zip = company.address.zip;
                    this.zipChanged();
                    this.search_coords = {
                        city_name: company.address.city,
                        context: company.address.context,
                        lat: company.address.lat,
                        lng: company.address.lng,
                    }

                    if (this.company.partner_sectors && this.company.partner_sectors.length > 0) {
                        this.company.partner_sectors.forEach(sector => {
                            this.selected_sectors.push({
                                label: sector.name,
                                value: sector
                            });
                        })
                    }
                })
            }
        },
        methods: {

            isValid(element) {
                if (!this.submitted) return null;
                else if (element.$error) return false;
                return true;
            },

            zipChanged: async function () {
                this.search_coords = {
                    city_name: "",
                    context: "",
                    lat: null,
                    lng: null
                };
                if (this.search_zip.length === 5) {
                    this.search_running = true;
                    geoService.searchZipCode(this.search_zip).then(cities => {
                        this.results_cities = cities;
                        this.search_running = false;
                    }).catch(err => {
                        if (err) console.log(err);
                        this.search_running = false;
                    });
                }
            },

            onSubmit() {
                this.submitted = true;
                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.submitted = false;
                this.loading = true;
                const company_id = this.$route.params.id || null;
                const sectors = this.selected_sectors.map(function (sector) {
                    return sector.value;
                });

                if (company_id) {

                    companiesService.update({

                        _id: company_id,
                        source: "jobinclusif",
                        name: this.company.name,
                        siren: this.company.siren,
                        siret: this.company.siret,
                        logo: this.logo,
                        description: this.company.description,
                        ca: this.company.ca,
                        year_ca : this.company.year_ca,
                        employees_count: this.company.employees_count,
                        website: this.company.website,
                        partner_sectors: sectors,
                        address: {
                            street: this.company.address.street[0] = this.street,
                            context: this.search_coords.context,
                            zip: this.search_zip,
                            city: this.search_coords.city_name,
                            lng: this.search_coords.lng,
                            lat: this.search_coords.lat,
                        },
                        phone: this.company.phone,
                        mail: this.company.mail

                    }).then(company => {
                        if (company) console.log(company);
                        router.push('/app/admin/companies');
                    }, error => {
                        this.error = error;
                        this.loading = false;
                    })

                } else {
                    companiesService.create({
                        source: "jobinclusif",
                        name: this.company.name,
                        siren: this.company.siren,
                        siret: this.company.siret,
                        logo: this.logo,
                        description: this.company.description,
                        ca: this.company.ca,
                        year_ca : this.company.year_ca,
                        employees_count: this.company.employees_count,
                        website: this.company.website,
                        partner_sectors: sectors,
                        address: {
                            street: this.company.address.street[0] = this.street,
                            context: this.search_coords.context,
                            zip: this.search_zip,
                            city: this.search_coords.city_name,
                            lng: this.search_coords.lng,
                            lat: this.search_coords.lat,
                        },
                        phone: this.company.phone,
                        mail: this.company.mail
                    }).then(company => {
                        if (company) console.log(company);
                        router.push('/app/admin/companies');
                    }, error => {
                        this.error = error;
                        this.loading = false;
                    })
                }
            }
        },

        created() {

            structureService.getAllPartnerSectors().then(response => {
                Object.entries(response).forEach(sector => this.Sectors.push(sector));
            })

        }

    }
</script>

<style scoped>
    .formContent {
        font-size: 15px;
    }
</style>