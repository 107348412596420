<template>
    <CContainer>
        <CCard>
            <CCardHeader>
                <CCardTitle>
                    {{$t('routes.change_password')}}
                </CCardTitle>
            </CCardHeader>
            <CCardBody>
                <CRow>
                    <CCol>
                        <CInput
                                label="Mot de passe actuel"
                                placeholder="Mot de passe actuel"
                                type="password"
                                v-model.trim="$v.oldPassword.$model"
                                invalidFeedback="Mot de passe Invalide"
                                :isValid="isValid(this.$v.oldPassword)"
                        ></CInput>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>
                        <CInput
                                label="Nouveau mot de passe"
                                placeholder="Nouveau mot de passe"
                                type="password"
                                v-model.trim="$v.newPassword.$model"
                                invalidFeedback="Le mot de passe doit faire au moins 8 caractères"
                                :isValid="isValid(this.$v.newPassword)"
                        ></CInput>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>
                        <CInput
                                label="Confirmez le nouveau mot de passe"
                                placeholder="Doit correspondre au champ précédent"
                                type="password"
                                v-model.trim="$v.newPassword2.$model"
                                invalidFeedback="Mot de passe Invalide"
                                :isValid="isValid(this.$v.newPassword2)"
                        ></CInput>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol class="text-right">
                        <CButton color="success" @click="changePassword()">
                            <font-awesome-icon icon="save"></font-awesome-icon>
                            {{$t('buttons.validate')}}
                        </CButton>
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
        <CToaster :autohide="3000">
            <template v-for="t in toastrPasswordChanged">
                <CToast :key="'toast' + t" :show="true" close-button class="bg-success text-white">
                    <font-awesome-icon icon="check"></font-awesome-icon>
                    Mot de passe Modifié, vous allez recevoir un e-mail de confirmation.
                </CToast>
            </template>
        </CToaster>
    </CContainer>
</template>

<script>
    import { required, sameAs, minLength } from 'vuelidate/lib/validators'
    import {memberService, authenticationService} from '@/services';
    export default {
        name: "ChangePassword",
        data() {
            return {
                currentUser: authenticationService.currentUserValue,
                userFromApi: null,
                oldPassword: '',
                newPassword: '',
                newPassword2: '',
                submitted: false,
                toastrPasswordChanged: 0
            };
        },
        validations: {
            oldPassword: {
                required,
                goodPassword: async (pwd) => {
                    return await memberService.isPasswordGood(pwd);
                }
            },
            newPassword:{required, minLength: minLength(8)},
            newPassword2: {required, sameAsPassword: sameAs('newPassword')}
        },
        methods: {
            isValid(element) {
                if (!this.submitted) return null;
                else if (element.$error) return false;
                return true;
            },
            changePassword() {
                this.submitted = true;
                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                memberService.changePassword(this.newPassword)
                    .then(() => {
                        this.toastrPasswordChanged++;
                    });
            }
        },
        mounted() {
            memberService.getById(this.currentUser.id).then(user => {
                this.userFromApi = user;
            });
        }
    }
</script>

<style scoped>

</style>