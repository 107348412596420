//import config from 'config';
import {handleResponse, requestOptions} from '@/helpers';

export const resumesService = {
    getAll,
    getById,
    getByUuid,
    update,
    create,
    getByEfaIdSociete,
    getByEfaId,
    getByCipId,
    getByEfaResumeId
};

function getAll() {
    return fetch(`${process.env.VUE_APP_API_URI}/resumes`, requestOptions.get())
        .then(handleResponse);
}

function getById(id) {
    return fetch(`${process.env.VUE_APP_API_URI}/resumes/${id}`, requestOptions.get())
        .then(handleResponse);
}

function getByEfaIdSociete(id) {
    return fetch(`${process.env.VUE_APP_API_URI}/resumes/efaidsociete/${id}`, requestOptions.get())
        .then(handleResponse);
}

function getByUuid(uuid) {
    return fetch(`${process.env.VUE_APP_API_URI}/resumes/me/${uuid}`, requestOptions.get())
        .then(handleResponse);
}

function getByEfaId(id) {
    return fetch(`${process.env.VUE_APP_API_URI}/resumes/efaid/${id}`, requestOptions.get())
        .then(handleResponse);
}

function getByCipId(id) {
    return fetch(`${process.env.VUE_APP_API_URI}/resumes/cipid/${id}`, requestOptions.get())
        .then(handleResponse);
}

function update(resume) {
    return fetch(`${process.env.VUE_APP_API_URI}/resumes`, requestOptions.put({resume: resume}))
        .then(handleResponse);
}

function create(uuid) {
    return fetch(`${process.env.VUE_APP_API_URI}/resumes/`, requestOptions.post({uuid: uuid}))
        .then(handleResponse);
}

function getByEfaResumeId(id){
    return fetch(`${process.env.VUE_APP_API_URI}/resumes/resumeefaid/${id}`, requestOptions.get())
        .then(handleResponse);
}