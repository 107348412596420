import {i18n} from "../../lang/lang";
import {Roles} from "../../config";

import savedSearch from "../../views/search/savedSearch";

export default [
    {
        path: 'saved',
        name: i18n.t('routes.search.saved.index'),
        component: savedSearch,
        meta: {
            authorize: [Roles.Admin, Roles.Company_Administrator, Roles.Recruiter], //doit être connecté avec le role admin
            title: "JobInclusif.fr - " + i18n.t('routes.search.saved.index')
        }
    }
];