import { BehaviorSubject } from 'rxjs';

//import config from 'config';
import { requestOptions, handleResponse } from '@/helpers';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    efaLogin,
    logout,
    lostpassword,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

function login(username, password) {
    const token = null;
    return fetch_authenticate(username, password, token);
}

function efaLogin(token) {
    const username = '', password = '';
    return fetch_authenticate(username, password, token);
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}

function fetch_authenticate(username, password, token) {
    return fetch(`${process.env.VUE_APP_API_URI}/members/authenticate`, requestOptions.post({ username, password, token }))
        .then(handleResponse)
        .then(user => {
            if(user._id) user.id = user._id;
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);

            return user;
        });
}

function lostpassword(email) {
    return fetch(`${process.env.VUE_APP_API_URI}/members/lostpassword`, requestOptions.post({email: email}))
        .then(handleResponse);
}