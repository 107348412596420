<template>
    <div>
        <CRow>
            <CCol>
                <CCard>
                    <CCardHeader>
                        <CCardTitle>
                            Envoyer un e-mail
                        </CCardTitle>
                    </CCardHeader>
                    <CCardBody>
                        <CForm>
                            <CRow>
                                <CCol>
                                    <CInput label="Nom" v-model.trim="$v.lastname.$model" invalidFeedback="Ce champ est obligatoire" :isValid="isValid($v.lastname)"></CInput>
                                </CCol>
                                <CCol>
                                    <CInput label="Prénom" v-model.trim="$v.firstname.$model" invalidFeedback="Ce champ est obligatoire" :isValid="isValid($v.firstname)"></CInput>
                                </CCol>
                                <CCol>
                                    <CInput label="Société" v-model.trim="$v.company.$model" invalidFeedback="Ce champ est obligatoire" :isValid="isValid($v.company)"></CInput>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol>
                                    <CInput type="email" label="Adresse E-Mail" v-model.trim="$v.email.$model" invalidFeedback="Ce champ est obligatoire et doit être un email valide" :isValid="isValid($v.email)"></CInput>
                                </CCol>
                                <CCol>
                                    <CInput type="phone" label="Numéro de Téléphone" v-model="phone"></CInput>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol>
                                    <CInput label="Sujet" v-model.trim="$v.subject.$model" invalidFeedback="Ce champ est obligatoire" :isValid="isValid($v.subject)"></CInput>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol>
                                    <CTextarea label="Message" rows="15" v-model.trim="$v.message.$model" invalidFeedback="Ce champ est obligatoire" :isValid="isValid($v.message)"></CTextarea>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol>
                                    <CInputCheckbox inline :checked="getcopy" @update:checked="getcopy = !getcopy"></CInputCheckbox> Recevoir une copie du message.
                                </CCol>
                                <CCol col="3" class="text-right">
                                    <CButton color="success" @click="send()" :disabled="sending">
                                        <font-awesome-icon icon="spinner" spin v-if="sending"></font-awesome-icon>
                                        <font-awesome-icon icon="paper-plane"></font-awesome-icon>
                                        Envoyer
                                    </CButton>
                                </CCol>
                            </CRow>
                        </CForm>
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol col="4">
                <CCard>
                    <CCardHeader>
                        <CCardTitle>
                            Contacter par téléphone
                        </CCardTitle>
                    </CCardHeader>
                    <CCardBody>
                        Un conseiller vous répond au : <b><a href="tel:05 62 12 49 88">05 62 12 49 88</a></b>
                        <br>
                        <br>
                        du lundi au vendredi : 9h-12h et 14h-17h
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <CToaster :autohide="3000">
            <template v-for="t in messagesSent">
                <CToast :key="'toast' + t" :show="true" close-button class="bg-success text-white">
                    <font-awesome-icon icon="check"></font-awesome-icon>
                    Le message a été envoyé.
                </CToast>
            </template>
            <template v-for="t in messagesErrors">
                <CToast :key="'errortoast' + t" :show="true" close-button class="bg-danger text-white">
                    <font-awesome-icon icon="times"></font-awesome-icon>
                    Erreur lors de l'envoie du message.
                </CToast>
            </template>
        </CToaster>
    </div>
</template>

<script>
    import {Roles} from '@/config';
    import {memberService, authenticationService, efaSocieteService, companiesService, helpService} from '@/services';
    import {required, email} from "vuelidate/lib/validators";

    export default {
        name: "Contact",
        data() {
            return {
                Roles: Roles,
                currentUser: authenticationService.currentUserValue,
                userFromApi: null,
                lastname: "",
                firstname: "",
                company: "",
                email: "",
                subject: "",
                phone: "",
                message: "",
                messagesSent: 0,
                messagesErrors: 0,
                submitted: false,
                sending: false,
                getcopy: false
            };
        },
        validations: {
            lastname: {required},
            firstname: {required},
            company: {required},
            email: {required, type:email},
            subject: {required},
            message: {required},

        },
        methods: {
            isValid(element) {
                if (!this.submitted) return null;
                else if (element.$error) return false;
                return true;
            },
            send() {
                this.submitted = true;
                this.sending = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.sending = false;
                    return;
                }
                const fullMessage = `
                    <b>FORMULAIRE DE CONTACT</b><br>
                    <b>SOCIETE:</b> ${this.company}<br>
                    <b>NOM:</b> ${this.firstname} ${this.lastname}<br>
                    <b>EMAIL:</b> ${this.email}<br>
                    <b>TELEPHONE:</b> ${this.phone}<br>
                    <b>SUJET:</b> ${this.subject}<br>
                    <b>MESSAGE:</b> ${this.message}
                `;
                helpService.sendToSupport(this.subject, fullMessage, this.email, this.getcopy).then(() => {
                    this.resetForm();
                    this.messagesSent++;
                    this.sending = false;
                    this.submitted = false;
                }).catch(err => {
                    this.messagesErrors++;
                    throw err;
                });
            },
            resetForm() {
                this.subject = "";
                this.message = "";
            },
            init() {
                memberService.getById(this.currentUser.id).then(user => {
                    this.userFromApi = user;
                    this.lastname = user.lastName;
                    this.firstname = user.firstName;
                    this.email = user.email;
                    if(user.id_company) {
                        companiesService.getById(user.id_company).then(c => {
                            this.company = c.name;
                        })
                    } else if(user.id_company_efa) {
                        efaSocieteService.getByEfaId(user.id_company_efa).then(societe => {
                            this.company = societe.libelle;
                        });
                    }
                });
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>

</style>