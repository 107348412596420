import {i18n} from "../lang/lang";
import {Roles} from "../config";

import Members from "../views/admin/members/Members";
import MemberForm from "../views/admin/members/MemberForm";

export default [
    {
        path: 'members',
        name: i18n.t('routes.admin.members.index'),
        component: Members,
        meta: {
            authorize: [Roles.Admin, Roles.Company_Administrator], //doit être connecté avec le role admin
            title: "JobInclusif.fr - " + i18n.t('routes.admin.members.index')
        }
    },
    {
        path: 'members/create',
        name: i18n.t('routes.admin.members.create'),
        component: MemberForm,
        meta: {
            authorize: [Roles.Admin, Roles.Company_Administrator], //doit être connecté avec le role admin
            title: "JobInclusif.fr - " + i18n.t('routes.admin.members.create')
        }
    },
    {
        path: 'members/update/:id',
        name: i18n.t('routes.admin.members.update'),
        component: MemberForm,
        meta: {
            authorize: [Roles.Admin, Roles.Company_Administrator], //doit être connecté avec le role admin
            title: "JobInclusif.fr - " + i18n.t('routes.admin.members.update')
        }
    },
];