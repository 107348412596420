<template>
    <div>
        <CRow>
            <CCol>
                <CCard>
                    <CCardHeader class="text-center">
                        <!--                        <CCardTitle v-if="!canEdit()">{{$t('joboffers.consult')}}</CCardTitle>-->
                        <CCardTitle v-if="isRouteSteps()">{{$t('joboffers.edit')}}</CCardTitle>
                        <CCardTitle v-else-if="isRouteCreate()">{{$t('joboffers.create')}}</CCardTitle>
                        <CCardTitle v-else-if="joboffer">
                            <CRow>
                                <CCol col="1" v-if="canSeeButtons()">
                                    <CButton :to="base_url" color="primary">
                                        <font-awesome-icon icon="arrow-left"></font-awesome-icon>
                                        Retour
                                    </CButton>
                                </CCol>
                                <CCol class="text-center">
                                    {{joboffer.title}}<small>&nbsp;({{getStatus()}})</small>
                                </CCol>
                                <CCol col="1" class="p-0 " v-if="canSeeButtons()">
                                    <CButton :to="getRouteApplications()" color="primary">
                                        <font-awesome-icon icon="eye"></font-awesome-icon>
                                        Candidatures
                                    </CButton>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol class="text-value-sm mt-2">
                                    <span v-if="joboffer.location">
                                        <span v-if="joboffer.location.street">{{ joboffer.location.street }} :</span>
                                        {{joboffer.location.city_name}} ({{joboffer.location.context}})
                                    </span>
                                    <router-link v-if="canEdit()" :to="getRouteStep_3()">
                                        <font-awesome-icon icon="pen"></font-awesome-icon>
                                        Modifier
                                    </router-link>
                                </CCol>
                            </CRow>
                        </CCardTitle>

                    </CCardHeader>
                    <CCardBody>
                        <template v-if="isRouteCreate() || isRouteSteps()">
                            <CRow>
                                <CCol col="1"></CCol>
                                <CCol col="9">
                                    <CRow>
                                        <CCol>
                                            <JobOffer_step_1 v-if="isRouteCreate() || isRouteStep_1()"></JobOffer_step_1>
                                            <JobOffer_step_2 v-else-if="isRouteStep_2()"></JobOffer_step_2>
                                            <JobOffer_step_3 v-else-if="isRouteStep_3()"></JobOffer_step_3>
                                        </CCol>
                                    </CRow>
                                </CCol>
                                <CCol col="2">
                                    <ul class="list-group" v-if="isRouteSteps()">
                                        <router-link :class=" 'list-group-item' + (isRouteStep_1() ? ' active' : '')" :to="getRouteStep_1()">{{$t('joboffers.steps.1')}}</router-link>
                                        <router-link :class=" 'list-group-item' + (isRouteStep_2() ? ' active' : '')" :to="getRouteStep_2()">{{$t('joboffers.steps.2')}}</router-link>
                                        <router-link :class=" 'list-group-item' + (isRouteStep_3() ? ' active' : '')" :to="getRouteStep_3()">{{$t('joboffers.steps.3')}}</router-link>
                                    </ul>
                                </CCol>
                            </CRow>
                        </template>

                        <!-------------------------------------- Partie postuler -------------------------------------->
                        <template v-else-if="joboffer">
                            <CRow v-if="authorised([Roles.User, Roles.Referral_Support])">
                                <CCol col="2" class="text-right">
                                    <font-awesome-icon icon="users"></font-awesome-icon>
                                </CCol>
                                <CCol col="5" v-if="resume_id || authorised([Roles.Referral_Support]) && !canEdit()">
                                    <CRow v-if="authorised([Roles.Referral_Support]) && alreadyApply.length > 0" class="mb-4">
                                        <CCol col="3" class="align-middle">
                                            <h6>A déjà postulé : </h6>
                                        </CCol>
                                        <CCol class="text-left">
                                            <CBadge v-for="resume in alreadyApply" v-bind:key="resume._id" class="ml-3 mb-1 border-primary text-primary">
                                                <h6>{{resume.fullName}}</h6>
                                            </CBadge>
                                        </CCol>
                                    </CRow>
                                </CCol>
                                <CCol col="5">
                                    <CRow>

                                        <CCol v-if="!switchApplication" class="text-right">
                                            <CBadge color="success" v-if="apply && !authorised([Roles.Referral_Support])">
                                                <h6>
                                                    <b>
                                                        Déjà postulé
                                                        <font-awesome-icon icon="check"></font-awesome-icon>
                                                    </b>
                                                </h6>
                                            </CBadge>
                                            <CBadge color="success" v-else-if="authorised([Roles.Referral_Support]) && applyResumes.length === 0">
                                                <h6>
                                                    <b>Tous vos candidats ont déjà postulé
                                                        <font-awesome-icon icon="check"></font-awesome-icon>
                                                    </b>
                                                </h6>
                                            </CBadge>
                                            <CButton v-else color="success" @click="switchApplication=true">
                                                <b>Postuler</b>
                                            </CButton>
                                        </CCol>
                                        <CCol v-if="switchApplication" class="text-right">
                                            <v-select v-if="authorised(Roles.Referral_Support)"
                                                      class="h6"
                                                      :options="applyResumes"
                                                      label="fullName"
                                                      multiple
                                                      v-model="selected_resumes"
                                                      placeholder="Selectionnez les utilisateurs">
                                            </v-select>
                                            <CButtonGroup>
                                                <CButton color="success" @click="applyApplication()">
                                                    <font-awesome-icon icon="check"></font-awesome-icon>
                                                    {{$t('buttons.validate')}}
                                                </CButton>
                                                <CButton class="btn btn-sm btn-danger" @click="switchApplication=false">
                                                    <font-awesome-icon icon="times"></font-awesome-icon>
                                                    {{$t('buttons.cancel')}}
                                                </CButton>
                                            </CButtonGroup>
                                        </CCol>


                                    </CRow>
                                </CCol>

                            </CRow>

                            <!--------------------------------------Step 1 : Description -------------------------------------->


                            <CRow>
                                <CCol col="4"></CCol>
                                <CCol col="4">
                                    <span class="text-black-50 text-uppercase">{{$t('joboffers.steps.1')}}</span>
                                </CCol>
                            </CRow>
                            <br>
                            <CRow>
                                <CCol col="2">
                                    <CRow>
                                        <CCol col="8" class="text-left">
                                            <router-link v-if="canEdit()" :to="getRouteStep_1()" class="float-right">
                                                <font-awesome-icon icon="pen"></font-awesome-icon>
                                                Modifier
                                            </router-link>
                                        </CCol>
                                        <CCol class="text-right">
                                            <font-awesome-icon icon="briefcase"></font-awesome-icon>
                                        </CCol>
                                    </CRow>
                                </CCol>
                                <CCol col="2">
                                    <CRow>
                                        <CCol v-if="joboffer.function">{{joboffer.function.name}}</CCol>
                                    </CRow>
                                </CCol>
                                <CCol col="7">
                                    <CRow>
                                        <CCol class="text-justify" v-if="joboffer.description">
                                            {{joboffer.description}}
                                        </CCol>
                                    </CRow>
                                </CCol>
                            </CRow>
                            <!------------------------------------- Step 2 : Conditions travail -------------------------------------->
                            <br>
                            <CRow>
                                <CCol col="4"></CCol>
                                <CCol col="4">
                                    <hr class="mb-0">
                                    <span class="text-black-50 text-uppercase">{{$t('joboffers.steps.2')}}</span>
                                </CCol>
                            </CRow>
                            <br>
                            <CRow>
                                <CCol col="2">
                                    <CRow>
                                        <CCol col="8" class="text-left">
                                            <router-link v-if="canEdit()" :to="getRouteStep_2()" class="float-right">
                                                <font-awesome-icon icon="pen"></font-awesome-icon>
                                                Modifier
                                            </router-link>
                                        </CCol>
                                        <CCol class="text-right">
                                            <font-awesome-icon icon="file-signature"></font-awesome-icon>
                                        </CCol>
                                    </CRow>
                                </CCol>
                                <CCol col="2">
                                    <span v-if="joboffer.contract">{{joboffer.contract.name}}</span>
                                    <span v-if="joboffer.work_times">&nbsp;({{joboffer.work_times.name}})</span>
                                </CCol>
                                <CCol col="7"></CCol>
                            </CRow>
                            <br>
                            <CRow>
                                <CCol col="2" class="text-right">
                                    <font-awesome-icon icon="calendar"></font-awesome-icon>
                                </CCol>
                                <CCol col="2">
                                    <span v-if="joboffer.start_date_wanted">A partir du : {{new Date(joboffer.start_date_wanted.substr(0,10)).toLocaleDateString()}}</span>
                                </CCol>
                                <CCol col="7"></CCol>
                            </CRow>
                            <br>
                            <template v-if="joboffer.payment && joboffer.payment.max > 0">
                                <CRow>
                                    <CCol col="2" class="text-right">
                                        <font-awesome-icon icon="euro-sign"></font-awesome-icon>
                                    </CCol>
                                    <CCol col="2">
                                        <span>Rémunération entre {{joboffer.payment.min || 0}} et {{joboffer.payment.max || 0}} k€ <br> brut/an</span>
                                    </CCol>
                                    <CCol col="7"></CCol>
                                </CRow>
                                <br>
                            </template>
                            <template
                                    v-if="joboffer.complementary_requirements && joboffer.complementary_requirements.length > 0">
                                <CRow>
                                    <CCol col="2" class="text-right">
                                        <font-awesome-icon icon="list-ol"></font-awesome-icon>
                                    </CCol>
                                    <CCol col="2">
                                        {{$t('joboffers.complementary_requirements.label')}}
                                    </CCol>
                                    <CCol col="7" v-if="joboffer.complementary_requirements">
                                        <CBadge class="m-1 border border-dark"
                                                v-for="item in joboffer.complementary_requirements" :key="item.id">
                                            {{item.name.substr(0,55)}}
                                        </CBadge>
                                    </CCol>
                                </CRow>
                                <br>
                            </template>
                            <template v-if="joboffer.adaptation_measures && joboffer.adaptation_measures.length > 0">
                                <CRow>
                                    <CCol col="2" class="text-right">
                                        <font-awesome-icon icon="hand-holding-heart"></font-awesome-icon>
                                    </CCol>
                                    <CCol col="2">
                                        {{$t('joboffers.adaptation_measures.label')}}
                                    </CCol>
                                    <CCol col="7" v-if="joboffer.adaptation_measures">
                                        <CBadge class="m-1 border border-dark"
                                                v-for="item in joboffer.adaptation_measures"
                                                :key="item.id">{{item.name.substr(0,55)}}
                                        </CBadge>
                                    </CCol>
                                </CRow>
                            </template>
                            <!--------------------------------------Compétences-------------------------------------->
                            <!--                            <br>-->
                            <!--                            <CRow>-->
                            <!--                                <CCol col="4"></CCol>-->
                            <!--                                <CCol col="4">-->
                            <!--                                    <hr class="mb-0">-->
                            <!--                                    <span class="text-black-50 text-uppercase">Compétences</span>-->
                            <!--                                </CCol>-->
                            <!--                            </CRow>-->
                            <!--                            <br>-->
                            <!--                            <CRow>-->
                            <!--                                <CCol col="2" class="text-right">-->
                            <!--                                    <font-awesome-icon icon="clipboard-list"></font-awesome-icon>-->
                            <!--                                </CCol>-->
                            <!--                                <CCol col="2">-->
                            <!--                                    Liste des compétences (exemple)-->
                            <!--                                </CCol>-->
                            <!--                                <CCol col="7" class="text-left">-->
                            <!--                                    <CBadge class="m-1 border border-dark">Lorem ipsum dolor sit amet</CBadge>-->
                            <!--                                    <CBadge class="m-1 border border-dark">Lorem ipsum dolor sit amet</CBadge>-->
                            <!--                                    <CBadge class="m-1 border border-dark">Lorem ipsum dolor sit amet</CBadge>-->
                            <!--                                    <CBadge class="m-1 border border-dark">Lorem ipsum dolor sit amet</CBadge>-->
                            <!--                                    <CBadge class="m-1 border border-dark">Lorem ipsum dolor sit amet</CBadge>-->
                            <!--                                </CCol>-->
                            <!--                            </CRow>-->
                            <!--------------------------------------Entreprise-------------------------------------->
                            <template v-if="companyExist()">
                                <br>
                                <CRow>
                                    <CCol col="4"></CCol>
                                    <CCol col="4">
                                        <hr class="mb-0">
                                        <span class="text-black-50 text-uppercase">L'entreprise</span>
                                    </CCol>
                                </CRow>
                                <br>
                                <CRow>
                                    <CCol col="2">
                                        <CRow>
                                            <CCol col="8" class="text-left">
                                                <router-link v-if="canEditCompany()" :to="getRouteCompany()" target="_blank" class="float-right">
                                                    <font-awesome-icon icon="pen"></font-awesome-icon>
                                                    Modifier
                                                </router-link>
                                            </CCol>
                                            <CCol class="text-right">
                                                <font-awesome-icon icon="building"></font-awesome-icon>
                                            </CCol>
                                        </CRow>
                                    </CCol>
                                    <CCol col="2">
                                        <span class="text-uppercase" v-if="companyExist()">{{getCompany().name}}</span>
                                    </CCol>
                                    <CCol col="7">
                                        <span class="text-justify"
                                              v-if="companyExist()">{{getCompany().description}}</span>
                                    </CCol>
                                </CRow>
                                <br>
                                <CRow>
                                    <CCol col="2" class="text-right">
                                        <font-awesome-icon icon="globe"></font-awesome-icon>
                                    </CCol>
                                    <CCol col="2">
                                        <span v-if="companyExist()"><a :href="getCompany().website" target="_blank">{{getCompany().website}}</a></span>
                                    </CCol>
                                </CRow>
                            </template>
                            <!--------------------------------------Bouton publier-------------------------------------->
                            <template v-if="authorised([Roles.Recruiter, Roles.Company_Administrator, Roles.Admin])">
                                <br>
                                <CRow>
                                    <CCol col="4"></CCol>
                                    <CCol col="4">
                                        <hr>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol col="4"></CCol>
                                    <CCol col="4" class="text-center">
                                        <CButton @click="publish()" v-if="isPublished() === false" color="success">
                                            Valider et publier mon offre
                                        </CButton>
                                        <CButton @click="publish()" v-else color="danger">Dépublier mon offre</CButton>
                                    </CCol>
                                </CRow>
                            </template>
                        </template>

                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import JobOffer_step_1 from "./partials/steps/JobOffer_step_1";
    import JobOffer_step_2 from "./partials/steps/JobOffer_step_2";
    import JobOffer_step_3 from "./partials/steps/JobOffer_step_3";
    import {
        joboffersService,
        authenticationService,
        memberService,
        resumesService,
        applicationsService
    } from "@/services";
    import {Roles} from '@/config';
    import {authorised_in_view, currentUserIsSuperAdmin} from '@/helpers';
    import {i18n} from "../../lang/lang";

    export default {
        name: "JobOfferForm",
        components: {JobOffer_step_3, JobOffer_step_2, JobOffer_step_1},
        data() {
            return {
                Roles: Roles,
                currentUser: authenticationService.currentUserValue,
                base_url: "/app/joboffers",
                joboffer: null,
                resume_id: null,
                application_id: null,
                apply: false,
                switchApplication: false,
                resumes: [],
                alreadyApply: [],
                selected_resumes: []
            };
        },
        methods: {
            getRouteParamId: function () {
                return this.$route.params.id
            },
            getRouteEdit: function () {
                return this.base_url + "/" + this.getRouteParamId() + "/update";
            },
            getRouteCreate: function () {
                return this.base_url + "/create";
            },
            isRoutePath: function (path) {
                return this.$route.path === path;
            },
            isRouteCreate: function () {

                return this.isRoutePath(this.getRouteCreate());
            },
            isRouteEdit: function () {
                return this.isRoutePath(this.getRouteEdit());
            },
            isRouteStep_1: function () {
                return this.isRoutePath(this.getRouteStep_1());
            },
            isRouteStep_2: function () {
                return this.isRoutePath(this.getRouteStep_2());
            },
            isRouteStep_3: function () {
                return this.isRoutePath(this.getRouteStep_3());
            },
            isRouteSteps: function () {
                return this.isRouteStep_1() || this.isRouteStep_2() || this.isRouteStep_3();
            },
            getRouteStep_1: function () {
                return this.getRouteEdit() + "/step_1";
            },
            getRouteStep_2: function () {
                return this.getRouteEdit() + "/step_2";
            },
            getRouteStep_3: function () {
                return this.getRouteEdit() + "/step_3";
            },
            getRouteCompany: function () {
                return "/app/admin/companies/update/" + this.getCompany()._id;
                // return "/app/companies/" + this.joboffer.companies[0]._id;
            },
            getRouteApplications: function () {
                return this.base_url + "/" + this.getRouteParamId() + "/applications";
            },
            getStatus: function () {
                if (this.joboffer.status.indexOf("step_") >= 0) {
                    return i18n.t("joboffers.status.draft");
                } else {
                    return i18n.t("joboffers.status." + this.joboffer.status);
                }
            },
            initApplication: function (id_joboffer) {
                this.alreadyApply = [];
                this.selected_resumes = [];
                this.resumes = [];
                if (this.authorised(Roles.Referral_Support)) {
                    memberService.getById(this.currentUser._id).then(user => {
                        if (user.id_efa) {
                            resumesService.getByCipId(user.id_efa).then(resumes => this.resumes = resumes);
                        }
                    })
                    applicationsService.getByIdCipAndIdJoboffer(this.currentUser.id_efa, id_joboffer).then(applications => {
                        if (applications && applications.length > 0) {
                            applications.forEach(application => {
                                this.alreadyApply.push(application.resumes[0]);
                            })
                        }
                    });
                } else {
                    memberService.getById(this.currentUser._id).then(user => {
                        if (user.id_efa) {
                            resumesService.getByEfaId(user.id_efa).then(resume => {
                                if (resume._id) {
                                    this.resume_id = resume._id;
                                    applicationsService.getByResumeAndJobId(resume._id, id_joboffer).then(application => {
                                        if (application) {
                                            this.apply = true;
                                        }
                                    });
                                }
                            });
                        }
                    })
                }
            },
            initJobOffer: async function () {
                this.joboffer = null;
                const id = this.$route.params.id || null;
                if (id) {
                    joboffersService.getById(id).then(response => {
                        this.joboffer = response;
                        this.initApplication(response._id);
                    })
                }
            },
            isPublished() {
                return this.joboffer.status === "published";
            },
            companyExist() {
                return this.joboffer.companies.length > 0;
            },
            getCompany() {
                if (this.companyExist()) {
                    return this.joboffer.companies[0];
                }
                return null;
            },
            publish() {
                if (this.isPublished()) {
                    this.joboffer.status = "step_3";
                    this.joboffer.published_at = null;
                } else {
                    this.joboffer.status = "published";
                    this.joboffer.published_at = new Date();

                }
                this.update();
            },
            update(offer) {
                if (!offer) {
                    offer = this.joboffer;
                }
                if (!offer) {
                    return null;
                }
                return joboffersService.update(offer).catch(error => {
                    if (error) console.log(error);
                });
            },
            canEdit() {
                return this.isPublished() === false
            },
            canSeeButtons() {
                /** super admin **/
                if (currentUserIsSuperAdmin()) return true;

                const isFromSameCompany = this.joboffer.members[0].id_company === this.currentUser.id_company;
                return this.authorised([Roles.Recruiter, Roles.Company_Administrator]) && isFromSameCompany;
            },
            canEditCompany() {
                return this.canEdit() && this.authorised([this.Roles.Company_Administrator, this.Roles.Admin]) && this.companyExist()
            },
            applyApplication() {
                if (this.authorised([Roles.Referral_Support])) {
                    if (this.selected_resumes.length === 0) {
                        return null;
                    } else {
                        this.selected_resumes.forEach(resume => {
                            applicationsService.create({
                                id_joboffer: this.joboffer._id,
                                id_resume: resume._id,
                            }).then(application => {
                                if (application) {
                                    this.apply = true;
                                    this.switchApplication = false;
                                }
                            }, error => {
                                this.error = error;
                                this.loading = false;
                            })
                        })
                    }
                } else {
                    applicationsService.create({
                        id_joboffer: this.joboffer._id,
                        id_resume: this.resume_id,
                    }).then(application => {
                        if (application) {
                            this.apply = true;
                            this.switchApplication = false;
                        }
                    }, error => {
                        this.error = error;
                        this.loading = false;
                    })
                }
                this.initApplication(this.joboffer._id);
            },
            authorised(authorised_roles = []) {
                if (this.currentUser) return authorised_in_view(authorised_roles, this.currentUser);
                return false;
            },
        },
        computed: {
            applyResumes() {
                let applications = [];
                this.resumes.forEach(resume => {
                    !this.alreadyApply.find(e => e._id === resume._id) ? applications.push(resume) : null;
                });
                return applications;
            }
        },
        mounted: async function () {
            await this.initJobOffer();
        },
        watch: {
            '$route'() {
                this.initJobOffer();
            }
        },
    }
</script>

<style scoped>

</style>