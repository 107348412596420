<template>
    <CContainer v-if="company && userFromApi" class="text-center">
        <CCard>
            <CCardHeader>
                <CRow>
                    <CCol class="text-right">
                        <CButtonGroup v-if="authorised([Roles.Recruiter, Roles.Company_Administrator, Roles.Admin])">
                            <CButton :to="'/app/companies/update/'+company._id" v-if="userFromApi.id_company === company._id">
                                <font-awesome-icon icon="edit" size="2x"></font-awesome-icon>
                            </CButton>
                            <CButton @click="favoriteClick(company._id)">
                                <font-awesome-icon icon="heart" class="pink-heart" size="2x" v-if="userFromApi.favorites.companies.find(c => c._id === company._id)"></font-awesome-icon>
                                <font-awesome-icon :icon="['far','heart']" size="2x" v-else></font-awesome-icon>
                            </CButton>
                        </CButtonGroup>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>
                        <h2 class="text-primary">{{company.name}}</h2>
                    </CCol>
                </CRow>
            </CCardHeader>
            <CCardBody>
                <CRow v-if="company.description && company.description.trim().length">
                    <CCol>
                        <CCard>
                            <CCardBody>
                                <h5>{{company.description}}</h5>
                            </CCardBody>
                        </CCard>
                    </CCol>

                </CRow>
                <CRow class="mb-3">
                    <CCol v-if="gpec">
                        <companies-badge :text="$t('company.content.badge.gpec')" :icon="'check-circle'" color="success"></companies-badge>
                    </CCol>
                    <CCol v-if="cddt">
                        <companies-badge :text="$t('company.content.badge.cddt')" :icon="'check-circle'" color="success"></companies-badge>
                    </CCol>
                    <CCol v-if="company.adhesion">
                        <companies-badge img="logo_unea_membership_full.png" :imgsize="{width:120, height: 60}" :text="'Logo adhérent UNEA'" ></companies-badge>
                    </CCol>
                    <CCol v-if="company.employees_count && company.employees_count > 0">
                        <companies-badge :text="company.employees_count +' employés'" :icon="'user-tie'"></companies-badge>
                    </CCol>
                    <CCol v-if="company.ca  && company.ca > 0 ">
                        <companies-badge :text="company.ca + '€ de CA ' + fomatCa(company.year_ca)" :icon="'coins'"></companies-badge>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>
                        <h3>Contact</h3>
                        <p v-if="company.phone">
                            <font-awesome-icon icon="phone"></font-awesome-icon>
                            {{company.phone}}
                        </p>
                        <p>
                            <a :href="`mailto:${company.mail}`">
                                <font-awesome-icon icon="envelope"></font-awesome-icon>
                                Envoyer un E-mail
                            </a>
                        </p>
                    </CCol>
                    <CCol v-if="company.website !== null">
                        <h3>Site web</h3>
                        <p>
                            <a :href="`${company.website}`" target="_blank">
                                {{company.website}}
                            </a>
                        </p>
                    </CCol>
                    <CCol v-if="company.address && company.address.zip && company.address.street.length > 0">
                        <h3>Adresse</h3>
                        <p v-for="(line, index) in company.address.street" v-bind:key="'adressline_' + index">
                            {{line}}</p>
                        <p>{{company.address.zip}} {{company.address.city}}</p>
                    </CCol>
                </CRow>
                <CRow class="mt-3">
                    <CCol>
                        <CompanyCollapse :companies="company.allCompany" :parentid="company._id"></CompanyCollapse>
                    </CCol>
                </CRow>
                <CRow class="mt-3" v-if="resumes && resumes.length > 0">
                    <CCol>
                        <ResumeCollapse :resumes="resumes"></ResumeCollapse>
                    </CCol>
                </CRow>
            </CCardBody>
            <CCardFooter>
                <CRow v-if="company.source === 'unea'">
                    <CCol>
                        <Activities :sectors="company.sectors" :sections="company.sections" :activities="company.acts"></Activities>
                    </CCol>
                </CRow>
                <CRow v-else-if="company.source === 'jobinclusif'">
                    <CCol>
                        <Activities :partner_sectors="company.partner_sectors"></Activities>
                    </CCol>
                </CRow>
            </CCardFooter>
        </CCard>
    </CContainer>
</template>

<script>
    import {companiesService, efaSocieteService, authenticationService, resumesService} from '@/services';
    import CompanyCollapse from "./partials/CompanyCollapse";
    import CompaniesBadge from "./partials/CompaniesBadge";
    import ResumeCollapse from "./partials/ResumeCollapse";
    import Activities from "./partials/Activities";
    import {memberService} from "../../services";
    import {authorised_in_view} from "@/helpers";
    import {Roles} from '@/config';


    export default {
        components: {
            CompanyCollapse,
            Activities,
            CompaniesBadge,
            ResumeCollapse
        },
        data() {
            return {
                Roles: Roles,
                userFromApi: null,
                company: {},
                gpec: false,
                cddt: false,
                inFavorite: false,
                resumes:null
            };
        },
        methods: {
            fomatCa(year_ca){
                if(year_ca) return "en " + year_ca
                else return ''
            },
            authorised(authorised_roles = []) {
                if (this.userFromApi) return authorised_in_view(authorised_roles, this.userFromApi);
                return false;
            },
            favoriteClick: function (id) {
                const favorite = {
                    _id: id,
                    name: this.company.name,
                    address: {
                        zip: this.company.address.zip,
                        city: this.company.address.city,
                    },
                    sectors: this.company.sectors,
                };
                if (this.userFromApi.favorites.companies.find(c => c._id === this.company._id)) {
                    this.userFromApi.favorites.companies.splice(this.userFromApi.favorites.companies.indexOf(favorite), 1);
                } else {
                    this.userFromApi.favorites.companies.push(favorite);
                }
                memberService.toggleFavorite(this.userFromApi).catch(err => {
                    console.log(err);
                });
            }
        },
        computed: {},
        mounted() {
            companiesService.getById(this.$route.params.id).then(companies => {
                this.company = companies;
                if (companies.adhesion === 1) this.company.adhesion = true;
                //On test de récupérer le liens avec les sociétes EFA
                if (companies.siret && companies.siret.replace(/ /g, '').replace(/\./g, '').length === 14) {
                    efaSocieteService.getBySiret(companies.siret).then(efadata => {
                        if (efadata && efadata.efa_id) {
                            this.gpec = true;
                            resumesService.getByEfaIdSociete(efadata.efa_id).then(resumes => {
                                this.resumes = resumes;
                            });
                        }
                        if (efadata && efadata.cddt && efadata.cddt === 1) this.cddt = true
                    });
                }
                //Récupération des établissements qui ont le même SIREN
                if (companies.siren) companiesService.getEtablissement(companies._id).then(companies => this.company.allCompany = companies);
                //Récupération des favoris
                memberService.getById(authenticationService.currentUserValue._id).then(user => this.userFromApi = user);
            });
        }
    };
</script>

<style scoped>
    .pink-heart {
        color: rgb(224, 36, 94);
    }
</style>