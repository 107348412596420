<template>
  <CFooter :fixed="false">
    <div>
      &copy;{{new Date().getFullYear()}} <a href="https://sayaris.fr" target="_blank">Sayaris</a> Tous droits réservés.
    </div>
    <div class="ml-auto">
      Un service opéré par <a href="https://www.co-savoirs.com/" target="_blank">Co-Savoirs</a>, développé par <a href="https://www.sayaris.fr" target="_blank">Sayaris</a>.
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
