import {i18n} from "../../lang/lang";
import {Roles} from "../../config";

import Profile from "../../views/search/Profile";

export default [
    {
        path: 'profiles',
        name: i18n.t('routes.search.profiles.index'),
        component: Profile,
        meta: {
            authorize: [Roles.Admin, Roles.Company_Administrator, Roles.Recruiter],
            title: "JobInclusif.fr - " + i18n.t('routes.search.profiles.index')
        }
    },
    {
        path: 'profiles/:timestamp',
        name: i18n.t('routes.search.profiles.index') + " ",
        component: Profile,
        meta: {
            authorize: [Roles.Admin, Roles.Company_Administrator, Roles.Recruiter],
            title: "JobInclusif.fr - " + i18n.t('routes.search.profiles.index')
        }
    }
];