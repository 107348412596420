export default {
    select: {
        sector: "Selectionner un Secteur",
        job: "Selectionnez un Métier",
        function: "Sélectionnez une Fonction"
    },
    zip: "Code Postal",
    city: "Ville",
    range: "Distance en Km",
    filter: "Affichage des résultats",
    resumes: "Profils",
    companies: "Entreprises",
    joboffer: "Offres d'emploi partenaires",
    location: "Secteur Géographique",
    results: {
        index: "Résultats de la recherche",
        resumes: "Profils Pertinents",
        offers: "Offres Partenaires Pertinentes",
        companies: "Entreprises Adaptées Pertinentes",
        companies_comment: "Si vous ne trouvez pas de cadidature pertinente, les entreprises suivantes peuvent vous aider. Des collaborateurs sont peut être déjà en formation dans le cadre d'un CDDT au sein de ces structures"
    }
}