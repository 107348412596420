<template>
    <CContainer>
        <CCard>
            <CCardHeader>
                <h4>{{$t('routes.search.profiles.index')}}</h4>
            </CCardHeader>
            <CCardBody>
                <CRow>
                    <CCol>
                        <label for="select_secteur">{{$t('searches.select.sector')}}</label>
                        <v-select id="select_secteur" label="name" :options="secteurs" v-model="selected_secteur" @input="changeSecteur()"></v-select>
                    </CCol>
                    <CCol>
                        <label for="select_metier">{{$t('searches.select.job')}}</label>
                        <v-select id="select_metier" label="name" :options="metiers" v-model="selected_metier" @input="changeMetier()"></v-select>
                    </CCol>
                    <CCol>
                        <label for="select_fonction">{{$t('searches.select.function')}}</label>
                        <v-select id="select_fonction" label="name" :options="fonctions" v-model="selected_fonction" @input="run_search()"></v-select>
                    </CCol>
                </CRow>
                <CRow class="mt-3">
                    <CCol col="2">
                        <label for="search_zip">{{$t('searches.zip')}}</label>
                        <input id="search_zip" type="text" class="form-control" v-model="search_zip" placeholder="Code Postal" @keyup="zipChanged()" :disabled="search_running">
                    </CCol>
                    <CCol>
                        <label for="search_city">
                            <i class="fas fa-spinner fa-spin" v-if="search_running"></i>
                            {{$t('searches.city')}}
                        </label>
                        <v-select id="search_city" label="city_name" :options="results_cities" v-model="search_coords" @input="getCity()" placeholder="Sélectionnez une ville"></v-select>
                    </CCol>
                    <CCol col="2">
                        <label for="search_km">{{$t('searches.range')}}</label>
                        <input id="search_km" type="text" class="form-control" v-model="search_km" placeholder="Distance en KM" @change="getCity()">
                    </CCol>
                </CRow>
                <CRow v-if="search_coords && search_coords.lat && search_coords.lng" style="display: none;">
                    <CCol>
                        {{search_coords.city_name}}, {{search_coords.context}}, Latitude: {{search_coords.lat}}, Longitude: {{search_coords.lng}}
                    </CCol>
                </CRow>
                <CRow class="mt-3">
                    <CCol>
                        <CButtonGroup class="full_width">
                            <CButton color="info" class="text-white" @click="run_search()" :disabled="search_running">
                                <font-awesome-icon icon="spinner" spin v-if="search_running"></font-awesome-icon>
                                <font-awesome-icon icon="search" v-else></font-awesome-icon>
                                {{$t('buttons.search')}}
                            </CButton>
                            <CButton color="success" v-if="searched && !saved" @click="save_search()">
                                <font-awesome-icon icon="save"></font-awesome-icon>
                                {{$t('buttons.save_search')}}
                            </CButton>
                        </CButtonGroup>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>
                        <hr>
                        <h4>{{$t('searches.results.index')}}</h4>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>
                        <div class="form-row">
                            <div class="col"><b>{{$t('searches.filter')}} :</b></div>
                            <div v-for="l in layers" v-bind:key="l.id" class="col">
                                <input :id="'display_layer'+ l.id" type="checkbox" v-model="l.active" @click="layerChanged(l.id, !l.active)"> &nbsp;
                                <label :for="'display_layer'+ l.id">{{l.name}}</label>
                            </div>
                        </div>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>
                        <div id="map" class="map" style="height: 500px;"></div>
                    </CCol>
                </CRow>
                <template v-if="searched">
                    <CRow class="mt-4">
                        <CCol>
                            <h4>{{$t('searches.results.resumes')}}: {{result_resumes.filter(r => r.displayed).length}}</h4>
                        </CCol>
                    </CRow>
                    <CRow class="mt-4">
                        <template v-for="resume in result_resumes">
                            <CCol col="4" v-bind:key="resume._id" v-if="resume.displayed" class="pb-3">
                                <result-resume :resume="resume" :company="getSocieteFull(resume.efa_id_societe)"></result-resume>
                            </CCol>
                        </template>
                    </CRow>
                    <CRow class="mt-4">
                        <CCol>
                            <h4>{{$t('searches.results.companies')}}: {{results_company.length}}</h4>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol>
                            <small>
                                {{$t('searches.results.companies_comment')}}
                            </small>
                        </CCol>
                    </CRow>
                    <CRow class="mt-4">
                        <template v-for="company in results_company">
                            <CCol col="4" v-bind:key="company._id" class="pb-3">
                                <result-company :company="company" :sectors="getSecteurFromActivities(company.activities)" :distance="distanceFromCompany(company)"></result-company>
                            </CCol>
                        </template>
                    </CRow>
                </template>
            </CCardBody>
        </CCard>
        <CToaster :autohide="3000">
            <template v-for="t in savedSearch">
                <CToast :key="'toast' + t" :show="true" close-button class="bg-success text-white">
                    <font-awesome-icon icon="check"></font-awesome-icon>
                    la recherche a été sauvegardée avec succès !
                </CToast>
            </template>
        </CToaster>
    </CContainer>
</template>

<!-- IMPORT LEAFLETJS - Open Street Map Library -->
<link rel="stylesheet" href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"/>
<script src="https://unpkg.com/leaflet@1.7.1/dist/leaflet.js"></script>

<script>
    import {searchService, structureService, geoService, efaSocieteService, companiesService, memberService, authenticationService} from "@/services";
    import resultResume from "./partials/resultResume";
    import resultCompany from "./partials/resultCompany";
    import {removeAccent, removeDuplicatesFromArrayOfObject} from '../../helpers';

    export default {
        name: "SearchProfile",
        components: {
            resultResume,
            resultCompany
        },
        data() {
            return {
                loadSavedSearch: true,
                updateListFromFonction: false,
                currentUser: authenticationService.currentUserValue,
                userFromApi: null,
                societes: [],
                search_string: "",
                search_zip: "",
                search_city: "",
                search_coords: {
                    city_name: "",
                    context: "",
                    lat: null,
                    lng: null
                },
                search_km: 50,
                data: [],
                structure: [],
                activities: [],
                secteurs: [],
                metiers: [],
                fonctions: [],
                selected_secteur: null,
                selected_metier: null,
                selected_fonction: null,
                searched: false,
                saved: false,
                savedSearch: 0,
                search_running: false,
                results_cities: [],
                result_company: [],
                results_company: [],
                result_companies_crm: [],
                result_resumes: [],
                //MAP
                map: null,
                tileLayer: null,
                layers: [
                    {
                        id: 0,
                        name: this.$t('searches.resumes'),
                        active: true,
                        features: [],
                        markers: []
                    },
                    {
                        id: 1,
                        name: this.$t('searches.companies'),
                        active: true,
                        features: [],
                        markers: []
                    },
                    {
                        id: 2,
                        name: this.$t('searches.location'),
                        active: true,
                        features: [],
                        markers: []
                    }
                ],
                marker_person: null,
                marker_building: null,
            };
        },
        methods: {
            zipChanged: async function () {
                this.search_coords = {
                    city_name: "",
                    context: "",
                    lat: null,
                    lng: null
                };
                this.layerChanged(2, false);
                this.layers[2].features = [];
                this.layers[2].markers = [];
                if (this.search_zip.length === 5) {
                    this.search_running = true;
                    geoService.searchZipCode(this.search_zip).then(cities => {
                        this.results_cities = cities;
                        this.search_running = false;
                    })
                        .catch(err => {
                            if (err) console.log(err);
                            this.search_running = false;
                        });
                }
            },
            run_search: async function (from_city = false) {
                this.layerChanged(0, false);
                this.layers[0].features = [];
                this.layers[0].markers = [];
                this.layerChanged(1, false);
                this.layers[1].features = [];
                this.layers[1].markers = [];
                if (!from_city) {
                    await this.getCity(true);
                }
                if (this.selected_fonction) {
                    if (!this.selected_metier || this.loadSavedSearch) {
                        this.updateListFromFonction = true;
                        for(let index_metier = 0; index_metier < this.metiers.length; index_metier++) {
                            if (this.metiers[index_metier].fonctions.includes(this.selected_fonction)) {
                                if (!this.selected_secteur) {
                                    this.secteurs.forEach(s => {
                                        if (s.metiers.includes(this.metiers[index_metier])) {
                                            this.selected_secteur = s;
                                        }
                                    });
                                }
                                this.selected_metier = this.metiers[index_metier];
                            }
                        }
                    }
                    if (this.selected_metier) {
                        if (this.selected_secteur && this.updateListFromFonction) await this.changeSecteur();
                        if(!this.selected_fonction || this.loadSavedSearch) await this.changeMetier();
                        await companiesService.getByMetier(this.selected_metier.name).then(c => {
                            this.result_companies_crm = c;
                        });
                        let fonctions = [];
                        this.selected_metier.fonctions.forEach(fonction => {
                            fonctions.push(fonction);
                        });
                        this.fonctions = this.removeDoubles(this.sortByName(fonctions));
                    }
                    this.updateListFromFonction = false;
                    this.loadSavedSearch = false;
                    this.result_resumes = [];
                    this.results_company = [];
                    if (this.selected_fonction.search && this.selected_fonction.search.trim().length > 0) {
                        this.search_string = this.selected_fonction.search;
                    } else {
                        this.search_string = this.selected_fonction.name;
                    }
                    const str_reformated = this.search_string.replace(/-\(\)\//g, ' ').trim();
                    const splitted = str_reformated.split(/[\s,']+/);
                    let searchable = "";
                    splitted.forEach(s => {

                        if (s.length > 3
                            || s.toLowerCase() === 'rrh'
                            || s.toLowerCase() === 'drh'
                            || s.toLowerCase() === 'iep'
                            || s.toLowerCase() === 'ged'
                            || s.toLowerCase() === 'sav'
                            || s.toLowerCase() === 'amp'
                            || s.toLowerCase() === 'adv'
                            || s.toLowerCase() === 'daf'
                            || s.toLowerCase() === 'rh'
                            || s.toLowerCase() === 'tri'
                        ) {
                            searchable += s + ' ';
                        }
                    });
                    searchable.trim();

                    try {
                        this.searched = false;
                        this.search_running = true;
                        //const q_fonction = await axios.get('/api/v1/efa/fonction_user/' + searchable);
                        const q_resumes = await searchService.searchProfiles(searchable);
                        this.map.remove();
                        this.initMap();
                        let filtered_ignore = [];
                        let ignore_done = false;
                        if (this.selected_fonction.ignore && this.selected_fonction.ignore.trim().length > 0) {
                            const ignore_list = this.selected_fonction.ignore.split(' ');
                            q_resumes.forEach(d => {
                                let insert = true;
                                for (let i = 0; i < ignore_list.length; i++) {
                                    for (let j = 0; j < d.jobdescriptions_target.length; j++) {
                                        if (ignore_list[i].length > 0 && removeAccent(d.jobdescriptions_target[0].LIBELLE).includes(removeAccent(ignore_list[i]))) {
                                            insert = false;
                                            break;
                                        }
                                    }
                                    if (!insert) break;
                                }
                                if (insert) filtered_ignore.push(d);
                            });
                            ignore_done = true;
                        }
                        let results = [];
                        let required_words = false;
                        if (this.selected_fonction.required && this.selected_fonction.required.trim().length > 0) {
                            const required_list = this.selected_fonction.required.split(' ');
                            let new_data = [];
                            if (ignore_done) {
                                new_data = filtered_ignore;
                            } else {
                                new_data = q_resumes;
                            }
                            new_data.forEach(d => {
                                let insert = 0;
                                required_list.forEach(word => {
                                    d.jobdescriptions_target.forEach(jdt => {
                                        if (removeAccent(jdt.LIBELLE).includes(removeAccent(word))) {
                                            insert++;
                                        }
                                    });
                                });
                                if (insert >= required_list.length) results.push(d);
                            });
                            required_words = true;
                        } else {
                            if (ignore_done) results = filtered_ignore;
                        }
                        if (required_words && results.length === 0) {
                            this.result_resumes = [];
                        } else if (results.length > 0) {
                            if (this.search_coords.lat && this.search_coords.lng) {
                                const calculate = this.distanceFromCoords;
                                const city = this.search_coords;
                                const distance = this.search_km;
                                results.forEach(r => {
                                    const calc_distance = calculate(r.position.lat, r.position.lng, city.lat, city.lng);
                                    if ((calc_distance <= distance) && (calc_distance >= (distance * -1))) {
                                        r.displayed = true;
                                    }
                                });
                                this.result_resumes = results;
                            } else {
                                results.forEach(r => {
                                    r.displayed = true;
                                });
                                this.result_resumes = results;
                            }
                        } else {
                            if (this.search_coords.lat && this.search_coords.lng) {
                                const calculate = this.distanceFromCoords;
                                const city = this.search_coords;
                                const distance = this.search_km;
                                q_resumes.forEach(r => {
                                    const calc_distance = calculate(r.position.lat, r.position.lng, city.lat, city.lng);
                                    if ((calc_distance <= distance) && (calc_distance >= (distance * -1))) {
                                        r.displayed = true;
                                    }
                                });
                                this.result_resumes = q_resumes;
                            } else {
                                q_resumes.forEach(r => {
                                    r.displayed = true;
                                });
                                this.result_resumes = q_resumes;
                            }
                        }
                        let marker_index = 0;
                        let results_company = [];
                        this.result_resumes.forEach(f => {
                            // results_company.push(f.efa_id_societe);
                            if (f.position.city && f.position.zip && f.position.lat && f.position.lng && f.displayed) {
                                this.layers[0].features.push({
                                    id: marker_index,
                                    type: 'marker_person',
                                    key: removeAccent(f.position.cp + '_' + f.position.city.trim().replace(/ /g, '_')).toUpperCase().replace(/-/g, '_').replace(/'/g, '_'),
                                    _id: f._id,
                                    efa_user_id: f.efa_user_id,
                                    ville: f.position.city,
                                    zip: f.position.zip,
                                    lat: f.position.lat,
                                    lng: f.position.lng,
                                    // fonction: f.libelle,
                                    jobdescriptions_target: f.jobdescriptions_target,
                                    coords: [f.position.lat || 0, f.position.lng || 0],
                                    societe: this.getSocieteFull(f.efa_id_societe),
                                    icon: this.marker_person
                                });
                                marker_index++;
                            }
                        });
                        const calculate = this.distanceFromCoords;
                        const city = this.search_coords;
                        const distance = this.search_km;
                        let test_displayed = 0;
                        this.result_companies_crm.forEach(c => {
                            const calc_distance = calculate(c.address.lat, c.address.lng, city.lat, city.lng);
                            if ((calc_distance <= distance) && (calc_distance >= (distance * -1))) {
                                c.displayed = true;
                                test_displayed++;
                            }
                        });
                        this.result_companies_crm.forEach(c => {
                            //results_company.push(c);
                            if (c.address.city && c.address.zip && c.address.lat && c.address.lng && ((city && city.lat && city.lng && c.displayed) || (city && (!city.lat || !city.lng) && !c.displayed))) {
                                this.results_company.push(c);
                                this.layers[1].features.push({
                                        type: 'marker_building',
                                        ville: c.address.city,
                                        zip: c.address.zip,
                                        lat: c.address.lat,
                                        lng: c.address.lng,
                                        coords: [c.address.lat || 0, c.address.lng || 0],
                                        ...c
                                    }
                                );
                            }
                        })
                        this.searched = true;
                        this.saved = false;
                        this.search_running = false;
                    } catch (e) {
                        throw e;
                    }
                }
                this.initLayers();
            },
            removeDoubles: function (array_to_sort) {
                return array_to_sort.filter((v, i) => {
                    return array_to_sort.map(e => {
                        return e.name.toLowerCase();
                    }).indexOf(v.name.toLowerCase()) === i;
                });
            },
            sortByName: function (array_to_sort) {
                return array_to_sort.sort((a, b) => {
                    const nameA = a.name.toLowerCase(),
                        nameB = b.name.toLowerCase()
                    ;
                    if (nameA < nameB) //sort string ascending
                        return -1;
                    if (nameA > nameB)
                        return 1;
                    return 0;
                });
            },
            save_search() {
                const search = {
                    timestamp: Date.now(),
                    secteur: this.selected_secteur,
                    metier: this.selected_metier,
                    fonction: this.selected_fonction,
                    zip: this.search_zip,
                    city: this.search_coords.city_name,
                    context: this.search_coords.context,
                    lat: this.search_coords.lat,
                    lng: this.search_coords.lng,
                    range: this.search_km,
                    type: 'profile'
                };
                memberService.saveSearch(this.currentUser._id, search)
                    .then(() => {
                        this.saved = true;
                        this.savedSearch++;
                    })
                    .catch(err => console.log(err))
                ;
            },
            getSecteurFromActivities(activities_code = []) {
                let sectors = [];
                activities_code.forEach(ac => {
                    const activity = this.activities.find(a => a.code === ac);
                    if (activity && activity.sectors) sectors.push(activity.sectors[0]);
                });
                return removeDuplicatesFromArrayOfObject(sectors, item => item.name);
            },
            changeSecteur: function () {
                if(!this.updateListFromFonction) this.selected_metier = null;
                this.search_string = "";
                if (this.selected_secteur) {
                    let metiers = [];
                    let fonctions = [];
                    this.selected_secteur.metiers.forEach(metier => {
                        metiers.push(metier);
                        metier.fonctions.forEach(fonction => {
                            fonctions.push(fonction);
                        });
                    });
                    this.metiers = this.removeDoubles(this.sortByName(metiers));
                    this.fonctions = this.removeDoubles(this.sortByName(fonctions));
                }
            },
            changeMetier: async function () {
                if(!this.updateListFromFonction) this.selected_fonction = null;
                this.search_string = "";
                if (this.selected_metier) {
                    await companiesService.getByMetier(this.selected_metier.name).then(c => {
                        this.result_companies_crm = c;
                    });
                    let fonctions = [];
                    this.selected_metier.fonctions.forEach(fonction => {
                        fonctions.push(fonction);
                    });
                    this.fonctions = this.removeDoubles(this.sortByName(fonctions));
                }
                if (!this.selected_secteur) {
                    this.secteurs.forEach(s => {
                        if (s.metiers.includes(this.selected_metier)) {
                            this.selected_secteur = s;
                        }
                        this.updateListFromFonction = true;
                        this.changeSecteur();
                        this.updateListFromFonction = false;
                    });
                }
            },
            getSociete: function (id) {
                for (let i = 0; i < this.societes.length; i++) {
                    if (this.societes[i].efa_id === id) {
                        return this.societes[i].libelle;
                    }
                }
            },
            getSocieteFull: function (id) {
                for (let i = 0; i < this.societes.length; i++) {
                    if (this.societes[i].efa_id === id) {
                        return this.societes[i];
                    }
                }
            },
            //MAP

            initMap: function () {
                this.map = L.map('map');
                this.map.setView([46.67, 2.60], 6);
                this.map.scrollWheelZoom.disable();
                this.tileLayer = L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
                    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                    tileSize: 512,
                    zoomOffset: -1,
                    maxZoom: 18,
                    id: 'mapbox/streets-v11',
                    accessToken: 'pk.eyJ1Ijoicm9tYWluc2F5YSIsImEiOiJjamx4c3B1b2sxaDVoM3FxeG9yZXNkc2t3In0.jSF8gXLkbFJLglZ3xD4jBg' // Clé MapBox
                });
                this.tileLayer.addTo(this.map);
                this.marker_person = L.icon({
                    // iconUrl: '/images/marker_person.png',
                    // iconUrl: 'https://staff.sayaris.fr/images/marker_person.png',
                    iconUrl: require('../../assets/images/icon_person.png'),
                    iconSize: [40, 55],
                    iconAnchor: [20, 55],
                    popupAnchor: [0, -12]
                });
                this.marker_building = L.icon({
                    // iconUrl: '/images/marker_building.png',
                    // iconUrl: 'https://staff.sayaris.fr/images/marker_building.png',
                    iconUrl: require('../../assets/images/icon_building.png'),
                    iconSize: [40, 55],
                    iconAnchor: [20, 55],
                    popupAnchor: [0, -12]
                });
            },
            initLayers: function () {
                this.layers.forEach((layer) => {
                    // Initialize the layer
                    const markerPersonFeatures = layer.features.filter(feature => feature.type === 'marker_person');
                    const markerBuldingFeatures = layer.features.filter(feature => feature.type === 'marker_building');
                    const markerCityFeatures = layer.features.filter(feature => feature.type === 'marker_city');
                    const groupped = this.groupArrayOfObjects(markerPersonFeatures, "key");
                    layer.markers = [];
                    markerCityFeatures.forEach(feature => {
                        layer.markers.push(L.marker(feature.coords).bindPopup(`
                                <b>${feature.ville}</b><br>${feature.context}
                            `));
                        layer.markers.push(L.circle(feature.coords, feature.circle_size));
                    });
                    markerBuldingFeatures.forEach((feature) => {
                        let content = "";
                        if (feature.logo && feature.logo.trim().length > 0) content += `<img src="https://standard.e-formadapt.com/storage/instances/logos/${feature.logo}" style="max-width: 100px;max-height: 60px;">`;
                        layer.markers.push(L.marker(feature.coords, {icon: this.marker_building}).bindPopup(`
                                ${content}<br><a href="/#/app/companies/${feature._id}" target="_blank"><b>${feature.name}</b></a>
                            `));
                    });
                    for (let prop in groupped) {
                        let content = `<b>${groupped[prop][0].zip} ${groupped[prop][0].ville}</b>`;
                        groupped[prop].forEach(u => {
                            content += '<br>- <a href="/#/app/resumes/' + u._id + '" target="_blank">CV ' + u.efa_user_id + '</a> <i>(';
                            let first = true;
                            u.jobdescriptions_target.forEach(jdt => {
                                if (!first) content += ", ";
                                content += jdt.LIBELLE;
                                first = false;
                            });
                            content += ' chez ';
                            if (u.societe.crm) {
                                content += '<a href="/#/app/companies/' + u.societe.crm._id + '" target="_blank">' + u.societe.crm.name + '</a>)</i>';
                            } else {
                                content += u.societe.libelle + ')</i>';
                            }

                        });
                        layer.markers.push(L.marker(groupped[prop][0].coords, {icon: groupped[prop][0].icon}).bindPopup(content));
                    }
                    this.layerChanged(layer.id, layer.active)
                });
            },
            layerChanged: function (layerId, active) {
                const layer = this.layers.find(layer => layer.id === layerId);
                layer.markers.forEach((marker) => {
                    if (active) {
                        marker.addTo(this.map);
                    } else {
                        marker.removeFrom(this.map);
                    }
                });
            },
            layerSwitched: function (layer) {
                const l = this.layers.find(ly => ly.id === layer.id);
                l.active = !l.active;
                this.layerChanged(l.id, !l.active)
            },
            groupArrayOfObjects: function (list, key) {
                return list.reduce(function (rv, x) {
                    (rv[x[key]] = rv[x[key]] || []).push(x);
                    return rv;
                }, {});
            },
            kmToDeg: function (km, lat) { //la distance en km représentant un degré en coordonnées varie en fonction de la latitude
                const rayon_terre_equateur = 6378.1;
                const circonference_terre_equateur = 2 * Math.PI * rayon_terre_equateur;
                return km / (circonference_terre_equateur * Math.cos(lat) / 360);
            },
            distanceFromCoords: function (lat1, lon1, lat2, lon2) {
                const R = 6371; // km (changer cette constante pour des miles)
                const dLat = (lat2 - lat1) * Math.PI / 180;
                const dLon = (lon2 - lon1) * Math.PI / 180;
                const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
                    Math.sin(dLon / 2) * Math.sin(dLon / 2);
                const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                return R * c;
            },
            distanceFromCompany: function (company) {
                if (this.search_coords.lat && this.search_coords.lng && company.address && company.address.lat && company.address.lng) {
                    return this.distanceFromCoords(company.address.lat, company.address.lng, this.search_coords.lat, this.search_coords.lng);
                }
                return 0;
            },
            getCity: async function (from_search = false) {
                if (this.search_coords && this.search_coords.lat && this.search_coords.lng) {
                    this.layerChanged(2, false);
                    this.layers[2].features = [];
                    this.layers[2].markers = [];
                    this.layers[2].features = [{
                        id: 1,
                        type: 'marker_city',
                        ville: this.search_coords.city_name,
                        context: this.search_coords.context,
                        circle_size: this.search_km * 1000,
                        coords: [this.search_coords.lat || 0, this.search_coords.lng || 0]
                    }];
                } else {
                    this.search_coords = {
                        city_name: "",
                        context: "",
                        lat: null,
                        lng: null
                    };
                    this.layerChanged(2, false);
                    this.layers[2].features = [];
                    this.layers[2].markers = [];
                }
                if (!from_search) await this.run_search(true);
            },
            loadStructure: async function() {
                await structureService.getAllCS().then(s => {
                    let secteurs = [], metiers = [], fonctions = [];
                    secteurs = s;
                    for (let i = 0; i < s.length; i++) {
                        const m = s[i].metiers;
                        for (let j = 0; j < m.length; j++) {
                            metiers.push(m[j]);
                            const f = m[j].fonctions;
                            for (let k = 0; k < f.length; k++) {
                                fonctions.push(f[k]);
                            }
                        }
                    }
                    this.secteurs = this.removeDoubles(this.sortByName(secteurs));
                    this.metiers = this.removeDoubles(this.sortByName(metiers));
                    this.fonctions = this.removeDoubles(this.sortByName(fonctions));
                });
            }
        },
        watch: {
            "selected_metier": async function() {
                if(!this.selected_metier) {
                    this.selected_fonction = null;
                    await this.changeSecteur();
                }
            },
            "selected_secteur": function() {
                if(!this.selected_secteur) {
                    this.selected_metier = null;
                    this.loadStructure();
                }
            }
        },
        mounted: async function () {
            await efaSocieteService.getAll().then(s => {
                this.societes = s;
                this.societes.forEach(cp => {
                    if (cp.siret && cp.siret.replace(/ /g, '').replace(/\./g, '').length === 14) {
                        companiesService.getBySiret(cp.siret.replace(/ /g, '').replace(/\./g, '')).then(c => {
                            if (c._id) {
                                cp.crm = c;
                            } else {
                                cp.crm = null;
                            }
                        });
                    } else {
                        cp.crm = null;
                    }
                });
            });
            await this.loadStructure();

            memberService.getById(this.currentUser.id).then(user => {
                this.userFromApi = user;
                const timestamp = this.$route.params.timestamp;
                if (timestamp) {
                    let search = null;
                    user.saved_searches.forEach(s => {
                        if (s.timestamp === parseFloat(timestamp)) search = s;
                    });
                    if (search) {
                        this.selected_secteur = search.secteur;
                        this.selected_metier = search.metier;
                        this.selected_fonction = search.fonction;
                        this.search_zip = search.zip;
                        this.search_coords = {
                            city_name: search.city,
                            context: search.context,
                            lat: search.lat,
                            lng: search.lng
                        };
                        this.search_km = search.range;
                    }
                    this.run_search();
                } else {
                    this.loadSavedSearch = false;
                }
            });

            structureService.getActivitiesUNEA().then(c => {
                this.activities = c;
            });

            //openstreetmap
            this.initMap();
        }
    }
</script>

<style scoped>
    .btn-group.full_width {
        display: flex;
    }

    .btn-group.full_width .btn {
        flex: 1
    }
</style>