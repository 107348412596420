<template>
    <div>
        <Resumes></Resumes>
        <CRow class="mt-4 mx-3 mb-4" v-if="$parent.resume.status !== 'published'">
            <CCol class="text-right">
                <CButton color="success" @click="publish()">
                    <font-awesome-icon icon="save"></font-awesome-icon>
                    {{$t('buttons.publish')}}
                </CButton>
            </CCol>
        </CRow>
        <CRow class="mt-4 mx-3 mb-4" v-else>
            <CCol class="text-right">
                <CButton color="danger" @click="unpublish()">
                    <font-awesome-icon icon="times"></font-awesome-icon>
                    {{$t('buttons.unpublish_resume')}}
                </CButton>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import Resumes from "../../Resumes";
    export default {
        name: "ResumeFormValidate",
        components: {Resumes},
        methods: {
            publish() {
                this.$parent.resume.status = 'published';
                this.$parent.resume.dt_publish = new Date();
                this.$parent.save();
            },
            unpublish() {
                this.$parent.resume.status = 'draft';
                this.$parent.save();
            }
        }
    }
</script>

<style scoped>

</style>