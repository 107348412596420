import {handleResponse, requestOptions} from '@/helpers';

//------------------Export des routes----------------//
export const statusService = {
    getAllApplications,
    getAllApplicationsManual,
    getById
}
//---------------------------------------------------//


//---------------------Routes------------------------//
// /**
//  *
//  * @returns {Promise<Response>}
//  */
// function getAll() {
//     return fetch(`${process.env.VUE_APP_API_URI}/status`, requestOptions.get())
//         .then(handleResponse);
// }

/**
 *
 * @returns {Promise<Response>}
 */
function getAllApplications() {
    return fetch(`${process.env.VUE_APP_API_URI}/status/applications`, requestOptions.get())
        .then(handleResponse);
}
/**
 *
 * @returns {Promise<Response>}
 */
function getAllApplicationsManual() {
    return fetch(`${process.env.VUE_APP_API_URI}/status/applications/manual`, requestOptions.get())
        .then(handleResponse);
}

/**
 *
 * @param id
 * @returns {Promise<Response>}
 */
function getById(id){
    return fetch(`${process.env.VUE_APP_API_URI}/status/:id`, requestOptions.delete({id: id}))
        .then(handleResponse);
}

//---------------------------------------------------//