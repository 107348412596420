<template>
    <CCard>
        <CCardHeader>
            <CRow>
                <CCol>
                    <CCardTitle>{{$t('joboffers.index')}}</CCardTitle>
                </CCol>
                <CCol class="text-right">
                    <CButton @click="withDeleted = !withDeleted" :class="'mr-2 ' + (withDeleted ? 'btn-info' : 'btn-outline-info')">
                        {{ $t("joboffers.deleted." + (withDeleted ? "hide" : "show" )) }}
                    </CButton>
                    <CButton @click="withClosed = !withClosed" :class="'mr-2 ' + (withClosed ? 'btn-info' : 'btn-outline-info')">
                        {{ $t("joboffers.closed." + (withClosed ? "hide" : "show" )) }}
                    </CButton>
                </CCol>
            </CRow>
        </CCardHeader>
        <CCardBody>
            <CDataTable :items="displayedItems"
                        :fields="fields"
                        :striped="true"
                        :border="true"
                        :small="true"
                        :sorter="{ resetable: true }"
                        :columnFilter="false"
                        :columnFilterValue="{}"
                        :tableFilter="{ placeholder: $t('datatable.search_placeholder'), label: $t('datatable.search') }"
                        :loading="false"
                        :pagination="true"
                        :itemsPerPage="25"
                        :itemsPerPageSelect="{ label: $t('datatable.elements_count'), }"
                        :noItemsView="{ noResults: $t('datatable.noResults'), noItems: $t('datatable.noItems') }"
            >
                <td slot="updatedAt" slot-scope="{item}">
                    <span v-if="item.updatedAt">{{format_date(item.updatedAt, false)}}</span>
                </td>
                <td slot="author" slot-scope="{item}">
                    <span v-if="item.members.length >0">{{item.members[0].firstName}} {{item.members[0].lastName}}</span>
                </td>
                <!--                    <td slot="company" slot-scope="{item}">-->
                <!--                        <span v-if="item.companies.length >0"> {{item.companies[0].name}}</span>-->
                <!--                    </td>-->
                <td slot="function" slot-scope="{item}">
                    {{item.function.name}}
                </td>
                <td slot="published_at" slot-scope="{item}">
                    <span v-if="item.published_at">{{format_date(item.published_at, true)}}</span>
                </td>
                <td slot="status" slot-scope="{item}">
                        <span v-if="item.status.substr(0,item.status.length -1) === 'step_'">
                            {{$t('joboffers.status.draft')}} (Etape : {{$t('joboffers.steps.' + item.status.substr(item.status.length -1,item.status.length -2))}})
                        </span>
                    <span v-else>{{$t('joboffers.status.' + item.status)}}</span>
                </td>
                <td slot="contract" slot-scope="{item}">
                    <span v-if="item.contract">{{item.contract.name}}</span>
                </td>
                <td slot="actions" slot-scope="{item}">
                    <CButtonGroup v-if="!itemIndex || itemIndex !== item._id">
                        <CButton :to="getPathEdit(item)" color="primary" size="sm">
                            <font-awesome-icon icon="eye"></font-awesome-icon>
                            {{$t('buttons.show')}}
                        </CButton>
                        <CButton :to="getPathApplications(item)" color="warning" size="sm">
                            <font-awesome-icon icon="eye"></font-awesome-icon>
                            {{$t('joboffers.applications.title')}}
                        </CButton>

                        <template v-if="isDeleted(item) && authorizedToRemove(item)">
                            <CButton @click="prepareItem(item, true)" color="danger" size="sm">
                                <font-awesome-icon icon="redo"></font-awesome-icon>
                                {{$t('buttons.restore')}}
                            </CButton>
                        </template>
                        <template v-else>
                            <CButton v-if="isClosed(item)" @click="prepareItem(item)" color="info" size="sm">
                                <font-awesome-icon icon="redo"></font-awesome-icon>
                                {{$t("joboffers.rePublish")}}
                            </CButton>
                            <CButton v-else @click="prepareItem(item)" color="info" size="sm">
                                <font-awesome-icon icon="times"></font-awesome-icon>
                                {{$t("joboffers.close")}}
                            </CButton>
                            <CButton v-if="authorizedToRemove(item)" @click="prepareItem(item, true)" color="danger" size="sm">
                                <font-awesome-icon icon="trash"></font-awesome-icon>
                                {{$t('buttons.trash')}}
                            </CButton>
                        </template>
                    </CButtonGroup>

                    <CButtonGroup v-else>
                        <CButtonGroup v-if="toTrash === true && itemIndex && itemIndex === item._id">
                            <CButton v-if="isDeleted(item)" @click="restore(item)" color="success" size="sm">
                                <font-awesome-icon icon="check"></font-awesome-icon>
                                {{$t('buttons.restore')}}
                            </CButton>
                            <CButton v-else @click="remove(item)" color="success" size="sm">
                                <font-awesome-icon icon="check"></font-awesome-icon>
                                {{$t('buttons.trash')}}
                            </CButton>
                        </CButtonGroup>
                        <CButton v-else-if="isClosed(item)" @click="publish(item)" color="success" size="sm">
                            <font-awesome-icon icon="check"></font-awesome-icon>
                            {{$t("joboffers.rePublish")}}
                        </CButton>
                        <CButton v-else @click="close(item)" color="success" size="sm">
                            <font-awesome-icon icon="check"></font-awesome-icon>
                            {{$t("joboffers.close")}}
                        </CButton>

                        <CButton @click="refreshIndex()" color="danger" size="sm">
                            <font-awesome-icon icon="times"></font-awesome-icon>
                            {{$t('buttons.cancel')}}
                        </CButton>
                    </CButtonGroup>


                </td>
            </CDataTable>
        </CCardBody>
    </CCard>
</template>


<script>
    import {joboffersService, authenticationService} from '@/services';
    import {formatDate, authorised_in_view, currentUserIsSuperAdmin} from '@/helpers';
    import {Roles} from '@/config';

    export default {
        name: "JobOffers",
        data() {
            return {
                withDeleted: false,
                withClosed: false,
                currentUser: authenticationService.currentUserValue,
                items: [],
                base_url: "/app/joboffers/",
                itemIndex: null,
                toTrash: false,
            };
        },
        methods: {
            getPathEdit(joboffer) {
                return this.base_url + joboffer._id + "/update";
            },
            getPathApplications(joboffer) {
                return this.base_url + joboffer._id + "/applications";
            },
            format_date(value, withTime) {
                return formatDate(value, withTime)
            },
            prepareItem(item, trash = false) {
                this.itemIndex = item._id;
                if (trash === true) this.toTrash = true;
            },
            refreshIndex() {
                this.itemIndex = null;
                this.toTrash = false;
            },
            restoreDatas(response) {
                if (response) {
                    this.loadDatas()
                    this.refreshIndex();
                }
            },
            remove: async function (item) {
                let response = null;
                response = await joboffersService.remove(item._id);
                this.restoreDatas(response);
            },
            restore: async function (item) {
                let response = null;
                response = await joboffersService.restore(item._id);
                this.restoreDatas(response);
            },
            publish: async function (item) {
                let response = null;
                response = await joboffersService.publish(item._id);
                this.restoreDatas(response);
            },
            close: async function (item) {
                let response = null;
                response = await joboffersService.close(item._id);
                this.restoreDatas(response);
            },
            loadDatas() {
                this.items = null;
                joboffersService.getByCompanyId(this.currentUser.id_company).then(response => {
                    this.items = response
                });
            },
            isDeleted(item) {
                return item.status === "deleted";
            },
            isClosed(item) {
                return item.status === "closed";
            },
            authorizedToRemove(item) {
                /** super admin **/
                if (currentUserIsSuperAdmin()) return true;

                const isFromSameCompany = item.members.length > 0 ? item.members[0].id_company === this.currentUser.id_company : false;
                return this.authorised([Roles.Recruiter, Roles.Company_Administrator]) && isFromSameCompany;
            },
            authorised(authorised_roles = []) {
                if (this.currentUser) return authorised_in_view(authorised_roles, this.currentUser);
                return false;
            },
        },
        computed: {
            fields() {
                return [
                    {
                        key: "updatedAt",
                        label: "Modifiée le",
                        filter: true,
                        sorter: false
                    },
                    {
                        key: "title",
                        label: "Intitulé de l'offre",
                        filter: true,
                        sorter: false
                    },
                    {
                        key: "function",
                        label: "Fonction recherchée",
                        filter: true,
                        sorter: false
                    },
                    {
                        key: "published_at",
                        label: "Publiée le",
                        filter: true,
                        sorter: false
                    },
                    {
                        key: "status",
                        label: "Statut",
                        filter: true,
                        sorter: false
                    },
                    {
                        key: "author",
                        label: "Auteur",
                        filter: true,
                        sorter: false
                    },
                    {
                        key: "contract",
                        label: "Type de contrat",
                        filter: true,
                        sorter: false
                    },
                    // {
                    //     key: "company",
                    //     label: "société",
                    //     filter: true,
                    //     sorter: false
                    // },
                    {
                        key: 'actions',
                        label: '',
                        filter: false,
                        sorter: false
                    }
                ];
            },
            displayedItems() {
                if (!this.items) return [];
                
                if (this.withDeleted && this.withClosed) return this.items;

                let items = this.items;
                if (!this.withDeleted) items = items.filter(item => this.isDeleted(item) === false);
                if (!this.withClosed) items = items.filter(item => this.isClosed(item) === false);
                return items;
            },
        },
        created() {
            this.loadDatas();
        },
    }
</script>


<style scoped>

</style>