<template>
    <div>
        <CCard>
            <CCardHeader>
                <CCardTitle>
                    {{$t("applications.consult")}}
                </CCardTitle>
            </CCardHeader>
            <CCardBody>
                <CDataTable :items="items"
                            :fields="fields"
                            :striped="true"
                            :border="true"
                            :small="true"
                            :sorter="{resetable: true}"
                            :columnFilter="false"
                            :columnFilterValue="{}"
                            :tableFilter="{placeholder: $t('datatable.search_placeholder'),
                                label: $t('datatable.search')}"
                            :loading="false"
                            :pagination="true"
                            :itemsPerPage="25"
                            :itemsPerPageSelect="{label: $t('datatable.elements_count')}"
                            :noItemsView="{ noResults: $t('datatable.noResults'), noItems: $t('datatable.noItems') }"
                >

                    <td slot="candidat" slot-scope="{item}">
                        <span v-if="item.resumes.length >0">{{item.resumes[0].fullName}}</span>
                    </td>
                    <td slot="title" slot-scope="{item}">
                        <span v-if="item.joboffer.length >0">{{item.joboffer[0].title}}</span>
                    </td>
                    <td slot="function" slot-scope="{item}">
                        <span v-if="item.joboffer.length >0">{{item.joboffer[0].function.name}} </span>
                    </td>
                    <td slot="createdAt" slot-scope="{item}">
                        <span v-if="item.createdAt">{{format_date(item.createdAt)}}</span>
                    </td>
                    <td slot="company" slot-scope="{item}">
                        <span v-if="item.companies.length >0"> {{item.companies[0].name}}</span>
                    </td>
                    <td slot="status" slot-scope="{item}">
                        <span>{{$t("applications.status."+item.joboffer[0].status)}}</span>
                    </td>
                    <td slot="actions" slot-scope="{item}">
                        <CButtonGroup>
                            <CButton :to="'/app/resumes/'+item.id_resume" color="primary" size="sm">
                                CV
                            </CButton>
                            <CButton :to="'/app/joboffers/'+item.id_joboffer" size="sm">
                                Offre
                            </CButton>
                        </CButtonGroup>
                    </td>
                </CDataTable>
            </CCardBody>
        </CCard>
    </div>

</template>

<script>
    import {applicationsService, authenticationService, memberService, resumesService} from "../../services";
    import {Roles} from '../../config';
    import {authorised_in_view} from "@/helpers";
    import {formatDate} from '@/helpers';


    export default {
        name: "applications",
        data() {
            return {
                currentUser: authenticationService.currentUserValue,
                Roles: Roles,
                items: []
            }
        },
        computed: {
            fields() {
                if (this.authorised([Roles.Referral_Support])) {
                    return [
                        {
                            key: "candidat",
                            label: "Candidat",
                            filter: true
                        },
                        {
                            key: "title",
                            label: "Intitulé de l'offre",
                            filter: false
                        },
                        {
                            key: "function",
                            label: "Fonction recherchés",
                            filter: false,
                        },
                        {
                            key: "createdAt",
                            label: "J'ai postulé le",
                            filter: true,
                            sorter: true
                        },
                        {
                            key: "company",
                            label: "Entreprise",
                            filter: false
                        },
                        {
                            key: 'status',
                            label: "Statut de l'offre",
                        },
                        {
                            key: 'actions',
                            label: ''
                        }
                    ];
                } else {
                    return [
                        {
                            key: "title",
                            label: "Intitulé de l'offre",
                            filter: false
                        },
                        {
                            key: "function",
                            label: "Fonction recherchés",
                            filter: true,
                            sorter: false
                        },
                        {
                            key: "createdAt",
                            label: "J'ai postulé le",
                            filter: true
                        },
                        {
                            key: "company",
                            label: "Entreprise",
                            filter: true
                        },
                        {
                            key: 'status',
                            label: "Statut de l'offre",
                            filter: false
                        },
                        {
                            key: 'actions',
                            label: '',
                            filter: false,
                            sorter: false
                        }
                    ];
                }

            }
        },
        methods: {
            authorised(authorised_roles = []) {
                if (this.currentUser) return authorised_in_view(authorised_roles, this.currentUser);
                return false;
            },
            format_date(value) {
                return formatDate(value)
            },
        },
        mounted() {
            if (this.authorised([Roles.Referral_Support])) {
                memberService.getById(authenticationService.currentUserValue._id).then(user => {
                    if (user.id_efa) {
                        applicationsService.getByIdCip(user.id_efa).then(applications => {
                            if (applications && applications.length) this.items = applications;
                        })
                    }
                })
            } else {
                memberService.getById(authenticationService.currentUserValue._id).then(user => {
                    if (user.id_efa) {
                        resumesService.getByEfaId(user.id_efa).then(resume => {
                            if (resume && resume._id) {
                                applicationsService.getByIdResume(resume._id).then(applications => {
                                    if (applications && applications.length) this.items = applications;
                                })
                            }
                        });
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>