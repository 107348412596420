<template>
    <CForm v-if="joboffer" @submit.prevent="onSubmit()">
        <CRow>
            <CCol>
                <div class="bg-light text-dark text-center rounded border p-1 mb-2"><strong> Etape {{this.current_step}} : {{$t('joboffers.steps.' + this.current_step)}}</strong></div>
            </CCol>
        </CRow>
        <CRow>
            <CCol>
                <c-input id="title" v-model.trim="$v.joboffer.title.$model" :label="$t('joboffers.title')" :placeholder="$t('joboffers.title')" type="text"
                         :invalidFeedback="$t('joboffers.errors_messages.title')" :isValid="isFieldValid($v.joboffer.title)"></c-input>
            </CCol>
        </CRow>
        <CRow>
            <CCol col="3"></CCol>
            <CCol col="6">
                <div class="bg-light text-dark text-center rounded p-0 mb-2">
                    <strong>Veuillez sélectionner la fonction recherchée</strong>
                </div>
            </CCol>
        </CRow>
        <CRow>
            <CCol>
                <div class="text-dark text-center rounded border p-3">
                    <CRow>
                        <CCol col="4">
                            <label for="select_secteur">{{$t('joboffers.secteur')}}</label>
                            <v-select id="select_secteur" label="name" :options="secteurs" v-model="selected_secteur" @input="changeSecteur()"></v-select>
                            <CInput type="hidden" v-model.trim="$v.selected_secteur.name.$model"
                                    :invalidFeedback="$t('joboffers.errors_messages.secteur')" :isValid="isFieldValid($v.selected_secteur.name)"></CInput>
                        </CCol>
                        <CCol col="4">
                            <label for="select_metier">{{$t('joboffers.metier')}}</label>
                            <v-select id="select_metier" label="name" :options="metiers" v-model="selected_metier" @input="changeMetier()"></v-select>
                            <CInput type="hidden" v-model.trim="$v.selected_metier.name.$model"
                                    :invalidFeedback="$t('joboffers.errors_messages.metier')" :isValid="isFieldValid($v.selected_metier.name)"></CInput>
                        </CCol>
                        <CCol col="4">
                            <label for="select_fonction">{{$t('joboffers.fonction')}}</label>
                            <v-select id="select_fonction" label="name" :options="fonctions" v-model="selected_fonction" @input="changeFonction()"></v-select>
                            <CInput type="hidden" v-model.trim="$v.selected_fonction.name.$model"
                                    :invalidFeedback="$t('joboffers.errors_messages.fonction')" :isValid="isFieldValid($v.selected_fonction.name)"></CInput>
                        </CCol>
                    </CRow>

                </div>
            </CCol>
        </CRow>

        <br>
        <CRow>
            <CCol>
                <CTextarea id="description" v-model="joboffer.description" :label="$t('joboffers.description.label')" :placeholder="$t('joboffers.description.placeholder')" rows="4"></CTextarea>
            </CCol>
        </CRow>
        <br>
        <JobOfferForm_buttons :base_url="base_url" :previous_step='null' :next_step='this.base_url + "/step_" + this.next_step'></JobOfferForm_buttons>
    </CForm>
</template>

<script>
    import {router} from '@/routes/router';
    import {structureService, joboffersService} from "@/services";
    import JobOfferForm_buttons from "../JobOfferForm_buttons";
    import {required} from "vuelidate/lib/validators";

    export default {
        name: "JobOffer_step_1",
        components: {JobOfferForm_buttons},
        data() {
            return {
                loading: false,
                submitted: false,
                base_url: "/app/joboffers/",
                previous_step: null,
                current_step: 1,
                next_step: 2,
                joboffer: {
                    status: "",
                    title: "",
                    sector: {},
                    occupation: {},
                    function: {},
                    description: "",
                },
                secteurs: [],
                metiers: [],
                fonctions: [],
                selected_secteur: null,
                selected_metier: null,
                selected_fonction: null,
                updateListFromFonction: false,
            }
        },
        validations: {
            joboffer: {
                title: {required},
            },
            selected_secteur: {
                name: {required}
            },
            selected_metier: {
                name: {required}
            },
            selected_fonction: {
                name: {required},
            },
        },
        methods: {
            isFieldValid(field) {
                if (!this.submitted) return null;
                if (field.$error) return false;
                return true;
            },
            removeDoubles: function (array_to_sort) {
                return array_to_sort.filter((v, i) => {
                    return array_to_sort.map(e => {
                        return e.name.toLowerCase();
                    }).indexOf(v.name.toLowerCase()) === i;
                });
            },
            sortByName: function (array_to_sort) {
                return array_to_sort.sort((a, b) => {
                    const nameA = a.name.toLowerCase(),
                        nameB = b.name.toLowerCase()
                    ;
                    if (nameA < nameB) //sort string ascending
                        return -1;
                    if (nameA > nameB)
                        return 1;
                    return 0;
                });
            },
            changeSecteur: function () {
                if (!this.updateListFromFonction) this.selected_metier = null;
                if (this.selected_secteur) {
                    let metiers = [];
                    let fonctions = [];
                    this.selected_secteur.metiers.forEach(metier => {
                        metiers.push(metier);
                        metier.fonctions.forEach(fonction => {
                            fonctions.push(fonction);
                        });
                    });
                    this.metiers = this.removeDoubles(this.sortByName(metiers));
                    this.fonctions = this.removeDoubles(this.sortByName(fonctions));
                }
            },
            changeMetier: async function () {
                if (!this.updateListFromFonction) this.selected_fonction = null;
                if (this.selected_metier) {
                    let fonctions = [];
                    this.selected_metier.fonctions.forEach(fonction => {
                        fonctions.push(fonction);
                    });
                    this.fonctions = this.removeDoubles(this.sortByName(fonctions));
                }
                if (!this.selected_secteur) {
                    this.secteurs.forEach(s => {
                        if (s.metiers.includes(this.selected_metier)) {
                            this.selected_secteur = s;
                        }
                        this.updateListFromFonction = true;
                        this.changeSecteur();
                        this.updateListFromFonction = false;
                    });
                }
            },
            changeFonction: async function () {
                if (this.selected_fonction) {
                    if (!this.selected_metier) {
                        this.updateListFromFonction = true;
                        for (let index_metier = 0; index_metier < this.metiers.length; index_metier++) {
                            if (this.metiers[index_metier].fonctions.includes(this.selected_fonction)) {
                                if (!this.selected_secteur) {
                                    this.secteurs.forEach(s => {
                                        if (s.metiers.includes(this.metiers[index_metier])) {
                                            this.selected_secteur = s;
                                        }
                                    });
                                }
                                this.selected_metier = this.metiers[index_metier];
                            }
                        }
                    }
                    if (this.selected_metier) {
                        if (this.selected_secteur && this.updateListFromFonction) await this.changeSecteur();
                        if (!this.selected_fonction) await this.changeMetier();
                        let fonctions = [];
                        this.selected_metier.fonctions.forEach(fonction => {
                            fonctions.push(fonction);
                        });
                        this.fonctions = this.removeDoubles(this.sortByName(fonctions));
                    }
                    this.updateListFromFonction = false;
                }
            },
            onSubmit() {
                this.submitted = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return false;
                }

                this.submitted = false;
                this.loading = true;

                this.joboffer.status = "step_" + this.current_step;
                this.joboffer.sector = this.selected_secteur;
                this.joboffer.occupation = this.selected_metier;
                this.joboffer.function = this.selected_fonction;

                const id = this.$route.params.id || null;

                if (id) {
                    this.$parent.update(this.joboffer);
                } else {
                    this.create();
                }
                return true;
            },
            create() {
                return joboffersService.create(this.joboffer).then(response => {
                    return router.push(this.base_url + response._id + "/update/step_" + this.next_step);
                }, error => {
                    console.log(error);
                    this.loading = false;
                });
            },
            resetOffer() {
                this.loading = false;
                this.submitted = false;
                this.joboffer = {
                    status: "step_1",
                    title: "",
                    sector: {},
                    occupation: {},
                    function: {},
                    description: "",
                };
                this.secteurs = [];
                this.metiers = [];
                this.fonctions = [];
                this.selected_secteur = null;
                this.selected_metier = null;
                this.selected_fonction = null;
            },
            loadStructure: async function () {
                await structureService.getAllCS().then(s => {
                    let secteurs = [], metiers = [], fonctions = [];
                    secteurs = s;
                    for (let i = 0; i < s.length; i++) {
                        const m = s[i].metiers;
                        for (let j = 0; j < m.length; j++) {
                            metiers.push(m[j]);
                            const f = m[j].fonctions;
                            for (let k = 0; k < f.length; k++) {
                                fonctions.push(f[k]);
                            }
                        }
                    }
                    this.secteurs = this.removeDoubles(this.sortByName(secteurs));
                    this.metiers = this.removeDoubles(this.sortByName(metiers));
                    this.fonctions = this.removeDoubles(this.sortByName(fonctions));
                });
            },
            loadJoboffer() {
                const id = this.$route.params.id || null;
                if (id) {
                    joboffersService.getById(id).then(response => {
                        this.joboffer = response;
                        this.selected_secteur = this.joboffer.sector;
                        this.selected_metier = this.joboffer.occupation;
                        this.selected_fonction = this.joboffer.function;
                        this.base_url = "/app/joboffers/" + response._id + "/update";
                    });
                }
            },
        },
        mounted: async function () {
            await this.loadStructure();
            this.loadJoboffer();
        },
        watch: {
            "selected_metier": async function () {
                if (!this.selected_metier) {
                    this.selected_fonction = null;
                    await this.changeSecteur();
                }
            },
            "selected_secteur": function () {
                if (!this.selected_secteur) {
                    this.selected_metier = null;
                    this.loadStructure();
                }
            },
            '$route'() {
                this.resetOffer();
            }
        }
    }
</script>

<style scoped>

</style>